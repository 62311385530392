import { useState, useEffect, useCallback } from "react";
import { FieldValues, useForm, Controller } from "react-hook-form";

import { Autocomplete, Box, Typography } from "@mui/material";

import { useStyles } from "./styles";
import { usePopup } from "../../../../../hooks/usePopup";

import TextField from "../../../../../components/TextField";
import Button from "../../../../../components/Button";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError, AxiosResponse } from "axios";
import { ISeller } from "../../../../../interfaces/SellersApi/ISeller";
import sellersApiService from "../../../../../services/SellersApi/sellersApiService";
import {
    SdrBasedErrors,
    SdrBasedResponses,
} from "../../../../../utils/Api/responses/responses";
import useCreateSpecialAttentionClient from "../../../../../hooks/AbacateApi/useCreateSpecialAttentionClient";
import { ISpecialAttetionClient } from "../../../../../interfaces/AbacateApi/ISpecialAttetionClient";
import useClientJourney from "../../../../../hooks/SdrApi/useClientJourney";
import useTeams from "../../../../../hooks/SellersApi/useTeams";
import ComponentHeader from "../../../../../components/ComponentHeader";

let searchClientFormSchema = yup.object().shape({
    cnpj: yup
        .string()
        .required("Cnpj do cliente é obrigatório.")
        .transform((value, originalValue) => {
            return originalValue
                ? originalValue.replace(/[./-]/g, "")
                : originalValue;
        }),
});

let postSpecialAttetionClientFormSchema = yup.object().shape({
    order: yup.string().required(),
    cpf_cnpj: yup.string().required("Cnpj do cliente é obrigatório."),
    name: yup.string().required("Nome do cliente é obrigatório."),
    responsible_hunter: yup.string().required("Reponsável é obrigatório."),
    description: yup
        .string()
        .max(
            140,
            "Seja objetivo, o separador precisa entender o problema de forma rápida e clara. Max 140 carac."
        )
        .required("Descrição é obrigatório."),
    reason: yup.array().of(yup.string()).required("Motivo é obrigatório."),
});

const SpecialAttentionClients = () => {
    const classes = useStyles();
    const { addPopup } = usePopup();

    const [query, setQuery] = useState<Object>();

    const [gettingHunters, setGettingHunters] = useState(false);
    const [hunters, setHunters] = useState<ISeller[]>();

    const {
        register: clientSearchRegister,
        handleSubmit: handleClientSearchFormSubmit,
        formState: { errors: clientSearchErrors },
    } = useForm({ resolver: yupResolver(searchClientFormSchema) });

    const handleSearchClient = (data: FieldValues) => {
        setQuery({ cpf_cnpj__in: `["${data.cnpj}"]` });
    };

    const { data: clientJourneyResponse, isFetching: gettingClientJourney } =
        useClientJourney(query, { enabled: !!query });

    const {
        mutate: createSpecialAttentionClient,
        isLoading: creatingSpecialAttentionClient,
    } = useCreateSpecialAttentionClient();

    const {
        register: postSpecialAttentionClientRegister,
        handleSubmit: handlePostSpecialAttentionClient,
        formState: { errors: postSpecialAttetionClientErrors },
        setValue: setPostAttentionClientValue,
        control: postAttentionClientControl,
        reset: resetPostAttentionClientForm,
    } = useForm<ISpecialAttetionClient>({
        resolver: yupResolver(postSpecialAttetionClientFormSchema),
    });

    const handleCreateSpecialAttentionClient = (
        data: ISpecialAttetionClient
    ) => {
        createSpecialAttentionClient(data);
        resetPostAttentionClientForm();
    };

    const { data: teamsResponse } = useTeams({ active: true });

    const getSellers = useCallback(async () => {
        setGettingHunters(true);
        await sellersApiService
            .getFrexcoSellers({
                squads__overlap: teamsResponse?.results
                    ? JSON.stringify(
                          teamsResponse?.results.map((team) => team.name)
                      )
                    : undefined,
                active: true,
            })
            .then(async (resp: AxiosResponse<SdrBasedResponses>) => {
                let count = resp.data.info.count;
                await sellersApiService
                    .getFrexcoSellers({
                        squads__overlap: teamsResponse?.results
                            ? JSON.stringify(
                                  teamsResponse?.results.map(
                                      (team) => team.name
                                  )
                              )
                            : undefined,
                        active: true,
                        per_page: count,
                        order_by: "name",
                    })
                    .then((resp: AxiosResponse<SdrBasedResponses>) => {
                        var sellers = resp.data.results;
                        sellers.push({
                            name: "inbound",
                            rdstation: {
                                external_id: "",
                            },
                            squads: ["without_team"],
                        });
                        sellers.push({
                            name: "Encantamento",
                            rdstation: {
                                external_id: "",
                            },
                            squads: ["without_team"],
                        });
                        setHunters(sellers);
                    });
            })
            .catch((err: AxiosError<SdrBasedErrors>) => {
                addPopup({
                    title: "Falha ao buscar Hunters.",
                    description: err.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            })
            .finally(() => setGettingHunters(false));
    }, [addPopup, teamsResponse]);

    useEffect(() => {
        if (clientJourneyResponse?.results.length === 0) {
            addPopup({
                title: "Cliente não encontrado.",
                description: "Não foi possível encontrar dados para este cnpj",
                type: "error",
                duration: "temporary",
            });
        } else if (clientJourneyResponse?.results.length === 1) {
            setPostAttentionClientValue(
                "cpf_cnpj",
                clientJourneyResponse?.results[0]?.cpf_cnpj
            );
            setPostAttentionClientValue(
                "name",
                clientJourneyResponse?.results[0]?.trade_name
            );
        }
    }, [clientJourneyResponse, setPostAttentionClientValue, addPopup]);

    useEffect(() => {
        getSellers();
    }, [getSellers]);

    return (
        <Box>
            <ComponentHeader.Root>
                <ComponentHeader.Title text="Solicitação de pedido crítico" />
                <ComponentHeader.Desc
                    text="Registre um pedido de atenção para o próximo pedido de um
                cliente."
                />
            </ComponentHeader.Root>
            <Box className={classes.formsContainer}>
                <form
                    className={classes.searchClientForm}
                    onSubmit={handleClientSearchFormSubmit(handleSearchClient)}
                >
                    <Typography>Pesquisar cliente:</Typography>
                    <Box className={classes.cnpjInputBox}>
                        <TextField
                            variant="outlined"
                            placeholder="CNPJ*"
                            label="CNPJ"
                            error={!!clientSearchErrors?.cnpj}
                            helperText={
                                clientSearchErrors?.cnpj?.message ? (
                                    <>{clientSearchErrors?.cnpj?.message}</>
                                ) : (
                                    <></>
                                )
                            }
                            {...clientSearchRegister("cnpj", {
                                required: true,
                            })}
                        />
                        <Button type="submit" loading={gettingClientJourney}>
                            <Typography variant="body2">pesquisar</Typography>
                        </Button>
                    </Box>
                </form>
                {clientJourneyResponse?.results.length === 1 && (
                    <form
                        onSubmit={handlePostSpecialAttentionClient(
                            handleCreateSpecialAttentionClient
                        )}
                        className={classes.postSpecialAttetionClientForm}
                    >
                        <Typography variant="h3">
                            {clientJourneyResponse?.results[0].trade_name}
                        </Typography>
                        <Typography sx={{ marginBottom: "20px" }} variant="h4">
                            Agora forneça detalhes do ocorrido:
                        </Typography>

                        <Box className={classes.postFormInputBox}>
                            <TextField
                                {...postSpecialAttentionClientRegister(
                                    "order"
                                )}
                                label="Número do pedido"
                                variant="outlined"
                                error={
                                    !!postSpecialAttetionClientErrors?.order
                                }
                                helperText={
                                    postSpecialAttetionClientErrors?.order ? (
                                        <>O número do pedido é obrigatório.</>
                                    ) : (
                                        <></>
                                    )
                                }
                            />
                            <Controller
                                name="reason"
                                control={postAttentionClientControl}
                                defaultValue={[]}
                                render={({ field: { ref, ...field } }) => (
                                    <Autocomplete
                                        {...field}
                                        multiple
                                        id="reason"
                                        options={[
                                            "Pontualidade",
                                            "Qualidade",
                                            "Iten(s) faltando",
                                        ]}
                                        className={classes.inputTextField}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="reason"
                                                inputRef={ref}
                                                label="Motivo(s) *"
                                                variant="outlined"
                                                error={
                                                    !!postSpecialAttetionClientErrors?.reason
                                                }
                                            />
                                        )}
                                        onChange={(e, value) => {
                                            setPostAttentionClientValue(
                                                "reason",
                                                value
                                            );
                                        }}
                                    />
                                )}
                            />

                            <Autocomplete
                                loading={gettingHunters}
                                options={hunters ? hunters : []}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                className={classes.inputTextField}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Vendedor Responsável *"
                                        variant="outlined"
                                        error={
                                            !!postSpecialAttetionClientErrors?.responsible_hunter
                                        }
                                        {...postSpecialAttentionClientRegister(
                                            "responsible_hunter"
                                        )}
                                    />
                                )}
                            />
                            <TextField
                                {...postSpecialAttentionClientRegister(
                                    "description"
                                )}
                                label="Descrição *"
                                variant="outlined"
                                multiline
                                rows={3}
                                error={
                                    !!postSpecialAttetionClientErrors?.description
                                }
                                helperText={
                                    postSpecialAttetionClientErrors?.description
                                        ?.message ? (
                                        <>
                                            {
                                                postSpecialAttetionClientErrors
                                                    ?.description?.message
                                            }
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }
                            />
                        </Box>
                        <Button
                            type="submit"
                            loading={creatingSpecialAttentionClient}
                        >
                            <Typography variant="body2">Registrar</Typography>
                        </Button>
                    </form>
                )}
            </Box>
        </Box>
    );
};

export default SpecialAttentionClients;
