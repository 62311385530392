import { useState } from "react";

import {
    Accordion,
    AccordionDetails,
    Box,
    Divider,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";

import AccordionSummary from "../../../../../components/AccordionSummary";
import TeamDefinitionsDialog from "./TeamDifinitionsDialog";
import HuntersListComponent from "./HuntersListComponent";

import { useStyles } from "./styles";
import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";
import { ITeams } from "../../../../../interfaces/SellersApi/ITeams";

interface IAccordionItem {
    team: ITeams;
    hunters: IFrexcoSeller[];
    huntersParams: { hunter_id: number; farmer_id: number }[];
    farmers: IFrexcoSeller[];
}

const AccordionItem = ({
    team,
    hunters,
    huntersParams,
    farmers,
}: IAccordionItem) => {
    const classes = useStyles();

    const [openSquadDefinitionsDialog, setOpenSquadDefinitionsDialog] =
        useState(false);

    return (
        <>
            <Accordion>
                <AccordionSummary
                    key={team.id}
                    expandIcon={
                        <Tooltip title="Vendedores">
                            <ExpandMoreIcon />
                        </Tooltip>
                    }
                    aria-controls="panel1a-content"
                >
                    <Box className={classes.accordionSummary}>
                        <Typography variant="h3">{team.name}</Typography>
                        <Tooltip title="Gerenciar time">
                            <IconButton
                                onClick={() =>
                                    setOpenSquadDefinitionsDialog(true)
                                }
                            >
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {hunters.map((hunter, index) => (
                        <>
                            <HuntersListComponent
                                key={parseInt(hunter.id!)}
                                hunter={hunter}
                                responsibleFarmer={farmers.filter((farmer) => {
                                    let hunterParams = huntersParams.filter(
                                        (hunterParams) =>
                                            hunterParams.hunter_id ===
                                            parseInt(hunter.id!)
                                    );
                                    if (!!hunterParams.length) {
                                        return (
                                            hunterParams[0].farmer_id ===
                                            parseInt(farmer.id!)
                                        );
                                    }
                                    return false;
                                })}
                                farmers={farmers}
                            />
                            <Divider key={index} />
                        </>
                    ))}
                </AccordionDetails>
            </Accordion>
            {openSquadDefinitionsDialog && (
                <TeamDefinitionsDialog
                    openDialog={openSquadDefinitionsDialog}
                    setOpenDialog={setOpenSquadDefinitionsDialog}
                    team={team}
                    hunters={hunters}
                    farmers={farmers}
                />
            )}
        </>
    );
};

export default AccordionItem;
