import { useState } from "react";

import CircleIcon from "@mui/icons-material/Circle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";

import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";
import { IconButton, Tooltip } from "@mui/material";

import IrregularityDialog from "../IrregularityDialog";
import useUtalkChannels from "../../../../../hooks/SellersApi/useUtalkChannels";
import { IUtalkChannel } from "../../../../../interfaces/SellersApi/IUtalkChannel";

export const Circle = ({ value }: { value: boolean }) => {
    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
            }}
        >
            <CircleIcon sx={{ color: value ? "primary.dark" : "error.main" }} />
        </div>
    );
};

export const Visibility = ({ value }: { value: boolean }) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {value ? (
                <VisibilityOffIcon sx={{ color: "error.main" }} />
            ) : (
                <VisibilityIcon sx={{ color: "primary.dark" }} />
            )}
        </div>
    );
};

export const IrregularityAlert = ({
    member,
    channel,
}: {
    member: IFrexcoSeller;
    channel: IUtalkChannel | undefined;
}) => {
    const [memberState, setMemberState] = useState(member);

    const [openIrregularityDialog, setOpenIrregularityDialog] = useState(false);

    let hasExitDate = !!memberState.exit_date;
    let utalkUserShouldNotExist = hasExitDate && !!memberState.utalk?.active;
    let shouldHaveChannel =
        !hasExitDate && (channel?.state === "Deleted" || !channel);

    if (member.is_team_leader || member.is_regional_leader) {
        shouldHaveChannel = false;
    }
    let irregular = utalkUserShouldNotExist || shouldHaveChannel;

    return irregular ? (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Tooltip title="Irregularidades">
                    <IconButton onClick={() => setOpenIrregularityDialog(true)}>
                        <NewReleasesOutlinedIcon color="error" />
                    </IconButton>
                </Tooltip>
            </div>
            {openIrregularityDialog && (
                <IrregularityDialog
                    openDialog={openIrregularityDialog}
                    setOpenDialog={setOpenIrregularityDialog}
                    memberState={memberState}
                    setMemberState={setMemberState}
                    utalkUserShouldNotExist={utalkUserShouldNotExist}
                    shouldHaveChannel={shouldHaveChannel}
                />
            )}
        </>
    ) : (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Tooltip title="Irregularidades">
                    <DoneAllOutlinedIcon color="primary" />
                </Tooltip>
            </div>
        </>
    );
};
