import { IFrexcoSeller } from "../../interfaces/SellersApi/IFrexcoSeller";
import { IFrexcoSellerQueryOptions } from "../../interfaces/SellersApi/QuerisOptions/IFrexcoSellerQueryOptions";
import GenericApi from "../genericApi";

class FrexcoSellersApi extends GenericApi<
    IFrexcoSeller,
    IFrexcoSellerQueryOptions,
    IFrexcoSeller
> {
    constructor() {
        super("api/sellers/frexco/");
    }
}

export default FrexcoSellersApi;
