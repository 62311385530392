import { useState } from "react";

import { Box, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

import DataGrid from "../../../../../components/DataGrid";
import ComponentHeader from "../../../../../components/ComponentHeader";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";

import { ICriticalOrder } from "../../../../../interfaces/SdrApi/ICriticalOrders";

import useMutation from "../../../../../hooks/useMutate";
import useCriticalOrders from "../../../../../hooks/SdrApi/useCriticalOders";

import { ISpecialAttetionClient } from "../../../../../interfaces/AbacateApi/ISpecialAttetionClient";
import SpecialAttentionClientsService from "../../../../../services/AbacateApi/specialAttentionClients";

import {
    getXDaysAgo,
    getXDaysForward,
    getYearMonthDay,
} from "../../../../../utils/dateFunctions";

const specialAttentionClientsService = new SpecialAttentionClientsService();

interface ICriticalOrdersManagementTable {
    area: "CD" | "Logística";
}

const CriticalOrdersManagementTable = ({ area }: ICriticalOrdersManagementTable) => {
    const isLogistics = area === "Logística";

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [SpecialAttentionClientToEdit, setSpecialAttentionClientToEdit] =
        useState<Partial<ISpecialAttetionClient>>();

    const colDefs: GridColDef<ICriticalOrder>[] = [
        {
            field: "order_id",
            headerName: "ID do Pedido",
            width: 130,
        },
        {
            field: "client_cpf_cnpj",
            headerName: "CNPJ",
            width: 160,
        },
        {
            field: "client_name",
            headerName: "Nome",
            minWidth: 150,
            flex: 1,
        },
        {
            field: "reason",
            headerName: "Motivos",
            minWidth: 150,
            flex: 1,
        },
        {
            field: "description",
            headerName: "Descrição",
            minWidth: 150,
            flex: 1,
        },
        {
            field: "total",
            headerName: "Valor",
            width: 100,
        },
        {
            field: "actions",
            type: "actions",
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Deletar">
                                <DeleteForeverOutlinedIcon
                                    fontSize="large"
                                    color="error"
                                />
                            </Tooltip>
                        }
                        label="Deletar"
                        onClick={() => {
                            setSpecialAttentionClientToEdit({
                                id: params.row.special_attention_client_id,
                                name: params.row.client_name,
                                cpf_cnpj: params.row.client_cpf_cnpj,
                                responsible_hunter:
                                    params.row.responsible_hunter,
                                reason: params.row.reason,
                                description: params.row.description,
                                valid: false,
                            });
                            setOpenConfirmationDialog(true);
                        }}
                    />,
                ];
            },
        },
    ];

    const { data: criticalOrdersResponse, isFetching: gettingCriticalOrders } =
        useCriticalOrders({
            created__gte: getYearMonthDay(getXDaysAgo(15)),
            scheduledDate: getYearMonthDay(getXDaysForward(1)),
            reasons: isLogistics ? JSON.stringify(["Pontualidade"]) : JSON.stringify(["Qualidade", "Iten(s) faltando"]),
        });

    const {
        mutate: editSpecialAttentionClient,
        isLoading: editingSpecialAttentionClient,
    } = useMutation({
        successText: "Solicitação deletada com sucesso.",
        errorText: "Falha ao deletar solicitação",
        queryKeyArray: ["critical-orders"],
        callback: specialAttentionClientsService.put,
    });

    const isLoading = editingSpecialAttentionClient || gettingCriticalOrders;

    return (
        <Box>
            <ComponentHeader.Root>
                <ComponentHeader.Title text={`Gerenciamento dos pedidos críticos ${isLogistics ? "logísticos" : "de distribuição"}`} />
                <ComponentHeader.Desc text="Visualize e gerencie pedidos de atenção especial criados nos últimos 15 dias com entrega marcada para amanhã" />
            </ComponentHeader.Root>
            <Box width={"100%"} height={"500px"}>
                <DataGrid
                    columns={colDefs}
                    rows={
                        criticalOrdersResponse
                            ? criticalOrdersResponse.results
                            : []
                    }
                    loading={isLoading}
                    getRowHeight={() => "auto"}
                />
            </Box>
            {openConfirmationDialog && (
                <ConfirmationDialog
                    openDialog={openConfirmationDialog}
                    setOpenDialog={setOpenConfirmationDialog}
                    title="Deseja mesmo deletar o pedido de atenção especial?"
                    callback={() =>
                        SpecialAttentionClientToEdit
                            ? editSpecialAttentionClient({
                                  id: SpecialAttentionClientToEdit.id!,
                                  object: SpecialAttentionClientToEdit,
                              })
                            : {}
                    }
                />
            )}
        </Box>
    );
};

export default CriticalOrdersManagementTable;
