interface IPermissionGate {
    neededpermissions: string[];
    userPermissions: string[];
    children: any;
}

export const PermissionGate = ({
    neededpermissions,
    userPermissions,
    children,
}: IPermissionGate) => {
    const matchPermission = neededpermissions.some((permission) =>
        userPermissions.includes(permission)
    );
    if (
        matchPermission ||
        neededpermissions.length === 0 ||
        userPermissions.includes("groselha | admin")
    ) {
        return children;
    }

    return null;
};
