import { Box } from "@mui/material";
import Header from "../LevelGoalsHeader";
import SalesLevelGoalsCards from "../SalesLevelGoalsCards";
import { ISalesLevel } from "../../../../../interfaces/SalesBonus/ISalesLevel";

interface ILevelGoals {
    level: ISalesLevel;
}

const LevelGoals = ({ level }: ILevelGoals) => {
    return (
        <Box>
            <Header level={level} />
            <SalesLevelGoalsCards level={level} />
        </Box>
    );
};

export default LevelGoals;
