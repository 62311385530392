import { useState } from "react";

import { IconButton, Tooltip } from "@mui/material";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { ConfirmationDialog } from "../../../ConfirmationDialog";

interface IDeleteItemCellRenderer {
    callback: Function;
}

const DeleteItemCellRenderer = ({ callback }: IDeleteItemCellRenderer) => {
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    return (
        <>
            <Tooltip title="Deletar Item">
                <IconButton onClick={() => setOpenConfirmationDialog(true)}>
                    <DeleteForeverOutlinedIcon color="error" />
                </IconButton>
            </Tooltip>
            {openConfirmationDialog && (
                <ConfirmationDialog
                    setOpenDialog={setOpenConfirmationDialog}
                    openDialog={openConfirmationDialog}
                    title="Deseja mesmo deletar o item?"
                    callback={callback}
                />
            )}
        </>
    );
};

export default DeleteItemCellRenderer;
