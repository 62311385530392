import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import { List, ListItem, Typography } from "@mui/material";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { ISalesLevel } from "../../../../../interfaces/SalesBonus/ISalesLevel";

import Card from "../../../../../components/Card";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";

import SalesLevelsApi from "../../../../../services/SalesBonusApi/salesLevelsApi";

import useMutation from "../../../../../hooks/useMutate";

const salesLevelApi = new SalesLevelsApi();

interface ISalesAreaLevelCard {
    level: ISalesLevel;
    area_id: number;
}

const SalesAreaLevelCard = ({ level, area_id }: ISalesAreaLevelCard) => {
    const navigate = useNavigate();

    const [openConfirmDeleteLevelDialog, setOpenConfirmDeleteLevelDialog] =
        useState(false);

    const { mutate: deleteLevel } = useMutation({
        successText: "Level deletado.",
        errorText: "Falha ao deletar level.",
        queryKeyArray: ["sales-areas-with-info"],
        callback: salesLevelApi.delete,
    });

    return (
        <>
            <Card.Root>
                <Card.Title text={level.name} />
                <Card.Content>
                    <List>
                        {level.fixed_values.map((fixed_value, index) => (
                            <>
                                <ListItem key={uuidv4()}>
                                    <Typography variant="body1">
                                        Quantidade mínima para{" "}
                                        {fixed_value.goal_type.name}:{" "}
                                        {fixed_value.quantity}
                                    </Typography>
                                </ListItem>
                                <ListItem key={uuidv4()}>
                                    <Typography variant="body1">
                                        Valor fixo por{" "}
                                        {fixed_value.goal_type.name}:{" "}
                                        {fixed_value.value}
                                    </Typography>
                                </ListItem>
                            </>
                        ))}
                        {level.goals.map((goal) => (
                            <ListItem key={uuidv4()}>
                                <Typography variant="body1">
                                    Bonificação {goal.name}
                                </Typography>
                            </ListItem>
                        ))}
                    </List>
                </Card.Content>
                <Card.Actions>
                    <Card.Action
                        tooltip={`Deletar ${level.name}`}
                        icon={
                            <DeleteForeverOutlinedIcon
                                color="error"
                                fontSize="large"
                            />
                        }
                        action={() => setOpenConfirmDeleteLevelDialog(true)}
                    />
                    <Card.Action
                        tooltip={`Editar ${level.name}`}
                        icon={
                            <EditOutlinedIcon
                                color="primary"
                                fontSize="large"
                            />
                        }
                        action={() => navigate(`level/${area_id}/${level.id}`)}
                    />
                </Card.Actions>
            </Card.Root>
            <ConfirmationDialog
                title={`Deseja mesmo deleter o nível ${level.name}?`}
                setOpenDialog={setOpenConfirmDeleteLevelDialog}
                openDialog={openConfirmDeleteLevelDialog}
                callback={() => deleteLevel(level.id!)}
            />
        </>
    );
};

export default SalesAreaLevelCard;
