import { ChangeEvent, useState } from "react";

import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { useAuth } from "../../../../../hooks/useAuth";

import { PermissionGate } from "../../../../../components/PermissionGate";
import ComponentHeader from "../../../../../components/ComponentHeader";
import AdminsGrid from "../AdminsGrid";
import RegionalLeadersGrid from "../RegionalLeadersGrid";

const RegionalLeaderRegister = () => {
    const [showAdminsGrid, setShowAdminsGrid] = useState(true);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setShowAdminsGrid(JSON.parse((event.target as HTMLInputElement).value));
    };
    
    const { permissions: userPermissions } = useAuth();

    return (
        <Box>
            <PermissionGate
                neededpermissions={["regional-leader-register"]}
                userPermissions={userPermissions}
            >
                <ComponentHeader.Root>
                    <ComponentHeader.Title text="Definição de Líderes Regionais" />
                    <ComponentHeader.Desc text="Utilize a tabela abaixo para gerenciar a designação dos líderes regionais." />
                </ComponentHeader.Root>
                <Box>
                <FormControl>
                    <RadioGroup
                        defaultValue="true"
                        onChange={handleChange}
                        row
                    >
                        <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Líderes de polo"
                        />
                        <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Líderes Regionais"
                        />
                    </RadioGroup>
                </FormControl>
                { showAdminsGrid ? (
                    <AdminsGrid
                    adminsQuery={{
                        active: true,
                        is_regional_leader: false,
                        is_team_leader: true,
                    }}
                    updatingFields={{ is_regional_leader: true }}
                />
                ) : (
                    <RegionalLeadersGrid />
                ) }   
                </Box>
            </PermissionGate>
        </Box>
    );
};

export default RegionalLeaderRegister;
