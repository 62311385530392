import { ISpecialAttetionClient } from "../../interfaces/AbacateApi/ISpecialAttetionClient";
import { ISpecialAttetionClientQueryOptions } from "../../interfaces/AbacateApi/QueryOptions/ISpecialAttentionClientsQueryOptions";
import GenericApi from "../genericApi";

class SpecialAttentionClientsService extends GenericApi<
    ISpecialAttetionClient,
    ISpecialAttetionClientQueryOptions,
    ISpecialAttetionClient
> {
    constructor() {
        super(`api/abacate/v1/public/special-attention-clients/`);
    }
}

export default SpecialAttentionClientsService;
