import { useState } from "react";
import { ChangePwdDialog } from "./components/ChangePwdDialog";
import ComponentHeader from "../../../components/ComponentHeader";

import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";

const Profile = () => {
    const [openChangePwdDialog, setOpenChangePwdDialog] = useState(false);

    const handleOpenPwdDialog = () => {
        setOpenChangePwdDialog(true);
    };

    return (
        <>
            <ComponentHeader.Root>
                <ComponentHeader.Title text="Perfil" />
                <ComponentHeader.Actions>
                    <ComponentHeader.Action
                        tooltip="Alterar Senha"
                        icon={
                            <KeyOutlinedIcon fontSize="large" color="primary" />
                        }
                        action={handleOpenPwdDialog}
                    />
                </ComponentHeader.Actions>
            </ComponentHeader.Root>

            {openChangePwdDialog && (
                <ChangePwdDialog
                    openDialog={openChangePwdDialog}
                    setOpenDialog={setOpenChangePwdDialog}
                />
            )}
        </>
    );
};

export default Profile;
