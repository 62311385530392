import { useQuery } from "@tanstack/react-query";
import HunterSalesService from "../../services/AbacateApi/hunterSalesService";
import { IHunterSalesSummary } from "../../interfaces/AbacateApi/IHunterSalesSummary";
import { AbacateApiErros } from "../../utils/Api/responses/responses";

const hunterSalesService = new HunterSalesService();

const useHunterSalesSummary = (params: Object) =>
    useQuery<IHunterSalesSummary[], AbacateApiErros>({
        queryKey: ["hunters-sales-summary", params],
        queryFn: () => hunterSalesService.getHunterSales(params),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar sumário de vendas.",
        },
    });
export default useHunterSalesSummary;
