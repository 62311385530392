import { useState, useEffect } from "react";
import { Autocomplete, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { usePopup } from "../../../hooks/usePopup";
import useCepInfos from "../../../hooks/BrasilApi/useCepInfos";
import useRdstationCities from "../../../hooks/SdrApi/useRdstationCities";
import useCepInfosV2 from "../../../hooks/BrasilApi/useCepInfosV2";

import TextField from "../../../components/TextField";
import Button from "../../../components/Button";
import { AlertDialog } from "../../../components/AlertDialog";

import sdrApiService from "../../../services/sdrApiService";

import { useStyles } from "./styles";
import { AxiosError, AxiosResponse } from "axios";

import { ICepInfo } from "../../../interfaces/BrasilApi/ICepInfos";
import { validateCep } from "../../../utils/validateCep";

const formSchema = yup.object().shape({
    leadName: yup
        .string()
        .required("O nome da organização é obrigatório")
        .trim(),
    leadCnpj: yup
        .string()
        .required("O CNPJ do lead é obrigatório")
        .transform((value, originalValue) => {
            if (originalValue) {
                return originalValue.replace(/[./-]/g, "");
            }
            return value;
        })
        .matches(
            /^([0-9]{14}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/,
            "Formato de CNPJ inválido"
        )
        .trim()
        .length(14),
    formattedPhoneNumber1: yup
        .string()
        .required("O número principal do lead é obrigatório")
        .trim()
        .max(14)
        .min(10),
    formattedPhoneNumber2: yup.string().trim(),
    leadMailAddress: yup.string().email().trim(),
    street: yup
        .string()
        .required("A rua do endereço do lead é obrigatório")
        .trim(),
    number: yup
        .string()
        .required("O número do endereço do lead é obrigatório")
        .trim(),
    neighborhood: yup
        .string()
        .required("O bairro do endereço do lead é obrigatório")
        .trim(),
    zipcode: yup
        .string()
        .required("O CEP do endereço do lead é obrigatório")
        .transform((value) =>
            value.length === 9 ? value.replace("-", "") : value
        )
        .length(8)
        .trim(),
    city: yup
        .string()
        .required("A cidade do endereço do lead é obrigatório")
        .transform((value) =>
            value === "São Caetano do Sul"
                ? "São Caetano"
                : value === "São Bernardo do Campo"
                ? "São Bernardo"
                : value
        )
        .trim(),
    state: yup
        .string()
        .required("O estado do endereço do lead é obrigatório")
        .trim(),
    country: yup.string().required().trim(),
    type: yup.string().required().trim(),
    otherIndications: yup.boolean().default(true),
});

const OthersRecommendationsForm = () => {
    const classes = useStyles();
    const { addPopup } = usePopup();

    const [sendingRecommendation, setSendingRecommendation] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [recommendationTitle, setRecommendationTitle] = useState("");
    const [recommendationResult, setRecommendationResult] = useState("");
    const [cep, setCep] = useState("");
    const [cepInfos, setCepInfos] = useState<ICepInfo>();

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        setError,
    } = useForm({
        resolver: yupResolver(formSchema),
    });

    const { data: cepInfosFromApi, isError: getCepInfosFromApiIsError } =
        useCepInfos(cep, {
            enabled: !!cep,
        });

    const { data: cepInfosFromApiV2, isError: getCepInfosFromApiIsErrorV2 } =
        useCepInfosV2(cep, {
            enabled: getCepInfosFromApiIsError,
        });

    const {
        data: rdstationCitiesResponse,
        isFetching: gettingRdstationCities,
    } = useRdstationCities();

    useEffect(() => {
        setValue("country", "Brazil");
        setValue("type", "Recommendation");
    }, [setValue]);

    const onSubmit = async (data: FieldValues) => {
        if (!rdstationCitiesResponse?.cities) {
            addPopup({
                title: "Erro ao realizar recomendação.",
                description:
                    "Não foi possível verificar se a cidade está em nossa área de entrega, comunique um adminstrador.",
                type: "info",
                duration: "temporary",
            });
            return;
        }
        if (!rdstationCitiesResponse?.cities.includes(data.city)) {
            setError("city", {
                type: "string",
                message: "Fora da área de entrega.",
            });
            return;
        }
        setSendingRecommendation(true);
        addPopup({
            title: "Recomendação enviada ao servidor.",
            description:
                "Por favor, espere até que uma nova mensagem seja exibida com o resultado da recomendação.",
            type: "info",
            duration: "permanent",
        });
        await sdrApiService
            .postRecommendation(data)
            .then((resp: AxiosResponse) => {
                setRecommendationTitle("Recomendação realizada.");
                if (typeof resp.data.success === "string") {
                    setRecommendationResult(resp.data.success);
                } else if (typeof resp.data.success === "object") {
                    const message = resp.data.success;
                    let resultMessage = "Já existem deals para esse lead: \n";

                    for (const key in message) {
                        if (message.hasOwnProperty(key)) {
                            const deal = message[key];
                            const hunterName = deal.hunter_name;
                            const dealId = deal.deal_id;

                            resultMessage += `deal_id: ${dealId},\n hunter_name: ${hunterName}\n`;
                        }
                    }
                    setRecommendationResult(resultMessage);
                }
                setOpenAlertDialog(true);
            })
            .catch((err: AxiosError<{ error: string }>) => {
                setRecommendationTitle("Falha ao realizar recomendação.");
                if (typeof err.response?.data.error === "string") {
                    setRecommendationResult(err.response?.data.error);
                } else {
                    setRecommendationResult("Descrição do erro indisponível.");
                }
                setOpenAlertDialog(true);
            })
            .finally(() => {
                setSendingRecommendation(false);
            });
    };

    const handleCepTextFildChange = (cep: string) => {
        if (validateCep(cep)) {
            if (cep.length === 9) {
                cep = cep.slice(0, 5) + cep.slice(6);
            }
            setCep(cep);
        }
    };

    useEffect(() => {
        if (cepInfosFromApi || cepInfosFromApiV2) {
            const cepInfos = cepInfosFromApi
                ? cepInfosFromApi
                : cepInfosFromApiV2;
            setCepInfos(cepInfos);
            setValue("state", cepInfos?.state);
            setValue("city", cepInfos?.city);
            setValue("neighborhood", cepInfos?.neighborhood);
            setValue("street", cepInfos?.street);
        }
    }, [cepInfosFromApi, cepInfosFromApiV2, setValue]);

    useEffect(() => {
        if (getCepInfosFromApiIsErrorV2) {
            addPopup({
                title: "Preencha o endereço manualmente.",
                description:
                    "Não foi possível buscar as informações de endereço, por favor preencha-as manualmente.",
                type: "info",
                duration: "permanent",
            });
        }
    }, [getCepInfosFromApiIsErrorV2]);

    return (
        <div>
            <Box className={classes.contentContainer}>
                <Box className={classes.contentContainer}>
                    <Typography variant="h6">Dados Gerais do Lead</Typography>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            variant="outlined"
                            label="Nome da Organização *"
                            type="text"
                            {...register("leadName")}
                            error={!!errors?.leadName}
                        />

                        <TextField
                            variant="outlined"
                            label="CNPJ do Lead *"
                            type="text"
                            {...register("leadCnpj")}
                            error={!!errors?.leadCnpj}
                            helperText={<>{errors?.leadCnpj?.message}</>}
                        />

                        <Typography variant="h6">
                            Dados de Contato do Lead
                        </Typography>

                        <TextField
                            variant="outlined"
                            label="Telefone 01 *"
                            type="text"
                            {...register("formattedPhoneNumber1")}
                            error={!!errors?.formattedPhoneNumber1}
                        />

                        <TextField
                            variant="outlined"
                            label="Telefone 02"
                            type="text"
                            {...register("formattedPhoneNumber2")}
                            error={!!errors?.formattedPhoneNumber2}
                        />

                        <TextField
                            variant="outlined"
                            label="Email"
                            type="text"
                            {...register("leadMailAddress")}
                            error={!!errors?.leadMailAddress}
                        />

                        <Typography variant="h6">
                            Endereço do Lead: insira o CEP para buscar o
                            endereço completo
                        </Typography>
                        <TextField
                            variant="outlined"
                            label="CEP *"
                            placeholder="00000-000"
                            type="text"
                            {...register("zipcode")}
                            error={!!errors?.zipcode}
                            onChange={(event) =>
                                handleCepTextFildChange(event.target.value)
                            }
                        />
                        <TextField
                            variant="outlined"
                            label="Logradouro *"
                            type="text"
                            {...register("street")}
                            error={!!errors?.street}
                            focused={!!cepInfos?.street}
                            InputLabelProps={{ shrink: true }}
                            disabled={!getCepInfosFromApiIsErrorV2}
                        />

                        <TextField
                            variant="outlined"
                            label="Número *"
                            type="text"
                            {...register("number")}
                            error={!!errors?.number}
                        />

                        <TextField
                            variant="outlined"
                            label="Bairro *"
                            type="text"
                            {...register("neighborhood")}
                            error={!!errors?.neighborhood}
                            focused={!!cepInfos?.neighborhood}
                            InputLabelProps={{ shrink: true }}
                            disabled={!getCepInfosFromApiIsErrorV2}
                        />

                        <Box sx={{ display: "flex" }}>
                            <TextField
                                variant="outlined"
                                label="Estado *"
                                type="text"
                                {...register("state")}
                                error={!!errors?.state}
                                focused={!!cepInfos?.state}
                                InputLabelProps={{ shrink: true }}
                                disabled={!getCepInfosFromApiIsErrorV2}
                            />
                            {!getCepInfosFromApiIsErrorV2 ? (
                                <TextField
                                    {...register("city")}
                                    label="Cidade *"
                                    variant="outlined"
                                    error={!!errors?.city}
                                    focused={!!cepInfos?.city}
                                    helperText={<>{errors?.city?.message}</>}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={!getCepInfosFromApiIsErrorV2}
                                />
                            ) : (
                                <Autocomplete
                                    options={
                                        rdstationCitiesResponse
                                            ? rdstationCitiesResponse?.cities
                                            : []
                                    }
                                    loading={gettingRdstationCities}
                                    getOptionLabel={(option) => option}
                                    className={classes.inputTextField}
                                    renderInput={(params) => (
                                        <TextField
                                            {...register("city")}
                                            {...params}
                                            label="Cidade *"
                                            variant="outlined"
                                            error={!!errors?.city}
                                        />
                                    )}
                                />
                            )}
                        </Box>
                        <Box>
                            <Button
                                disabled={sendingRecommendation}
                                className={classes.buttonBox}
                                type="submit"
                            >
                                <Typography variant="h4">Enviar</Typography>
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
            {openAlertDialog && (
                <AlertDialog
                    setOpenDialog={setOpenAlertDialog}
                    openDialog={openAlertDialog}
                    title={recommendationTitle}
                    message={recommendationResult}
                />
            )}
        </div>
    );
};

export default OthersRecommendationsForm;
