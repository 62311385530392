import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        marginTop: "20px",
    },
    filtersBox: {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        marginBottom: "5px",
    },
    datesFiltersBox: {
        display: "flex",
        gap: "15px",
        "& .MuiTextField-root": {
            margin: "0px",
        },
        "@media (max-width: 620px)": {
            flexDirection: "column",
        },
    },
    buttonsBox: {
        display: "flex",
        gap: "15px",
        justifyContent: "end",
        alignItems: "center",
        "@media (max-width: 620px)": {
            flexDirection: "column",
        },
    },
    multiSelect: {
        width: "180px",
    },
    paginationBox: {
        marginTop: "10px",
        display: "flex",
        justifyContent: "end",
    },
}));
