import { api } from "../../utils/Api/apiClient";

class RdstationCitiesService {
    url: string;
    constructor() {
        this.url = `api/sdr/v1/management/rdstation-cities/`;
    }

    getCities = () => api.get(this.url).then((res) => res.data);
}

export default RdstationCitiesService;
