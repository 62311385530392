import { Box, Grid } from "@mui/material";

import { ISalesLevel } from "../../../../../interfaces/SalesBonus/ISalesLevel";
import SalesLevelGoalCard from "../SalesLevelGoalCard";

interface ISalesLevelGoalsCards {
    level: ISalesLevel;
}

const SalesLevelGoalsCards = ({ level }: ISalesLevelGoalsCards) => {
    return (
        <>
            <Box margin={"0 auto"} display={"flex"} justifyContent={"center"}>
                <Grid container spacing={2} maxWidth={"1200px"}>
                    {level.goals.map((goal, index) => (
                        <Grid key={index} item xs={12} sm={4}>
                            <SalesLevelGoalCard goal={goal} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default SalesLevelGoalsCards;
