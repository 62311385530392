import { useQuery } from "@tanstack/react-query";
import FarmerWalletApi from "../../services/SalesBonusApi/farmerWallet";

const farmerWalletApi = new FarmerWalletApi();

const useFarmerWallet = (query?: Object, definitions?: Object) =>
    useQuery({
        ...definitions,
        queryKey: ["farmer-wallet", query],
        queryFn: () => farmerWalletApi.get(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar carteira farmer.",
        },
    });
export default useFarmerWallet;
