import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
    usersHeader: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
    cardsContainer: {
        display: "flex",
        aligItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        width: "100%",
        maxHeight: "500px",
        minHeight: "180px",
        overflowY: "scroll",
        gap: "20px",
        marginTop: "15px",
        paddingTop: "15px",
        paddingBottom: "15px",
    },
    infoContainer: {
        marginTop: "20px",
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        "@media (max-width: 620px)": {
            flexDirection: "column",
        },
    },
}));
