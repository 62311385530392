import { useQuery } from "@tanstack/react-query";
import RdstationCitiesService from "../../services/SdrApi/rdstationCitiesService";
import { SdrBasedErrors } from "../../utils/Api/responses/responses";

const rdstationCitiesService = new RdstationCitiesService();

const useRdstationCities = () =>
    useQuery<{ cities: string[] }, SdrBasedErrors>({
        queryKey: ["rdstation-cities"],
        queryFn: () => rdstationCitiesService.getCities(),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar cidades.",
        },
    });
export default useRdstationCities;
