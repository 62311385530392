import { useCallback, useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";

import {
    Box,
    Autocomplete,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";

import { IClient } from "../../../../../interfaces/AbacateApi/IClients";
import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";
import { IOnboardingClient } from "../../../../../interfaces/AbacateApi/IOnboardingClient";

import { IOnboardingWallet } from "../../../../../interfaces/SalesBonus/IWallets";
import clientsService from "../../../../../services/clientsApiService";
import OnboardingWalletApi from "../../../../../services/SalesBonusApi/onboardingWallet";
import {
    AbacateApiErros,
    AbacateGetResponse,
} from "../../../../../utils/Api/responses/responses";

import TextField from "../../../../../components/TextField";
import Button from "../../../../../components/Button";
import DataGrid from "../../../../../components/DataGrid";
import ComponentHeader from "../../../../../components/ComponentHeader";

import { usePopup } from "../../../../../hooks/usePopup";
import useMutation from "../../../../../hooks/useMutate";
import useTeams from "../../../../../hooks/SellersApi/useTeams";
import useFrexcoSellers from "../../../../../hooks/SellersApi/useFrexcoSellers";
import { useStyles } from "./styles";

import { GridColDef, useGridApiRef } from "@mui/x-data-grid";

const onboardingWalletApi = new OnboardingWalletApi();

const GainToFarmer = () => {
    const classes = useStyles();
    const { addPopup } = usePopup();

    const [clientsWithoutFarmers, setClientsWithoutFarmers] = useState<
        IClient[]
    >([]);
    const [gettingClientsWithoutFarmers, setGettingClientsWithoutFarmers] =
        useState(false);
    const [selectedFarmer, setSelectedFarmer] =
        useState<IFrexcoSeller | null>();

    const gridRef = useGridApiRef();

    const [assigningClients, setAssigningClients] = useState(false);

    const [payingOff, setPayingOff] = useState(true);
    const [columnDefs] = useState<GridColDef[]>([
        {
            field: "id",
            maxWidth: 150,
            headerName: "ID",
        },
        { field: "name", headerName: "Nome", flex: 1 },
        { field: "cpf_cnpj", headerName: "CNPJ", flex: 1 },
        { field: "hunter_name", headerName: "Hunter", flex: 1 },
        { field: "hunter_squad", headerName: "Squad", flex: 1 },
    ]);

    const { data: teamsResponse } = useTeams({
        active: true,
        role__in: JSON.stringify(["Farm"]),
    });

    const { data: farmersResponse, isFetching: gettingFarmersResponse } =
        useFrexcoSellers(
            {
                active: true,
                squads__overlap: teamsResponse?.results
                    ? JSON.stringify(
                          teamsResponse?.results.map((team) => team.name)
                      )
                    : undefined,
            },
            {
                enabled: !!teamsResponse?.results,
            }
        );

    const getClientsWithoutFarmers = useCallback(async () => {
        setGettingClientsWithoutFarmers(true);
        await clientsService
            .getClientsWithoutFarmers()
            .then(async (resp: AxiosResponse<AbacateGetResponse<IClient>>) => {
                const total_count = resp.data.total_count;
                await clientsService
                    .getClientsWithoutFarmers({
                        page_size: total_count,
                    })
                    .then(
                        (resp: AxiosResponse<AbacateGetResponse<IClient>>) => {
                            setClientsWithoutFarmers(resp.data.results);
                        }
                    )
                    .catch((err: AxiosError<AbacateApiErros>) => {
                        addPopup({
                            title: "Error ao buscar clientes sem farmers.",
                            description: err.response?.data.error,
                            type: "error",
                            duration: "temporary",
                        });
                    });
            })
            .catch((err: AxiosError<AbacateApiErros>) => {
                addPopup({
                    title: "Error ao buscar clientes sem farmers.",
                    description: err.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            })
            .finally(() => {
                setGettingClientsWithoutFarmers(false);
            });
    }, [addPopup]);

    const { mutate: addClientToWallet } = useMutation({
        successText: "Cliente adicionado a carteira do farmer.",
        errorText: "Erro ao adicionar cliente a carteira do farmer.",
        queryKeyArray: ["onboarding-wallet"],
        callback: onboardingWalletApi.post,
    });

    const handleAtribuirButtonClick = async () => {
        setAssigningClients(true);
        try {
            const selectedClients = gridRef.current.getSelectedRows();
            const selectedClientsArray = Array.from(
                selectedClients.values()
            ) as IClient[];
            if (typeof selectedClientsArray != "undefined" && selectedFarmer) {
                const onboardingClients: IOnboardingClient[] = [];
                const walletRecords: IOnboardingWallet[] = [];
                selectedClientsArray.forEach((client) => {
                    const onboardingClient: IOnboardingClient = {
                        client: client.id,
                        farmer: selectedFarmer.name,
                    };
                    const walletRecord: IOnboardingWallet = {
                        onboarding: selectedFarmer.name,
                        cnpj: client.cpf_cnpj,
                        restaurant_name: client.trade_name
                            ? client.trade_name
                            : null,
                        client_name: client.responsible_name
                            ? client.responsible_name
                            : null,
                        paying_off: payingOff,
                        active: true,
                    };
                    walletRecords.push(walletRecord);
                    onboardingClients.push(onboardingClient);
                });
                addClientToWallet(walletRecords);
                await clientsService
                    .postOnboardingClients(onboardingClients)
                    .then(() => {
                        addPopup({
                            title: "Atribuição realizada com sucesso.",
                            type: "success",
                            duration: "temporary",
                        });
                        const newClientsWithoutFarmers: IClient[] =
                            clientsWithoutFarmers.filter(
                                (client) =>
                                    !selectedClientsArray.some(
                                        (selectedClient) =>
                                            selectedClient === client
                                    )
                            );
                        setClientsWithoutFarmers(newClientsWithoutFarmers);
                    })
                    .catch(() => {
                        addPopup({
                            title: "Falha ao atribuir clientes",
                            type: "error",
                            duration: "temporary",
                        });
                    });
            } else {
                addPopup({
                    title: "Erro ao atribuir",
                    description:
                        "É necessário selecionar pelo menos 1 cliente e 1 farmer.",
                    type: "info",
                    duration: "temporary",
                });
            }
        } catch {
        } finally {
            setAssigningClients(false);
        }
    };

    useEffect(() => {
        getClientsWithoutFarmers();
    }, []);

    return (
        <Box className={classes.assignClientsGridBox}>
            <ComponentHeader.Root>
                <ComponentHeader.Title text="Atribuição de ganhos a farmers" />
            </ComponentHeader.Root>
            <Box width={"100%"} height={"500px"}>
                <DataGrid
                    apiRef={gridRef}
                    columns={columnDefs}
                    rows={clientsWithoutFarmers}
                    loading={gettingClientsWithoutFarmers}
                    isRowSelectable={() => true}
                    checkboxSelection
                    hideFooterPagination={false}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <Autocomplete
                    id="onboarding-sellers"
                    options={farmersResponse?.results || []}
                    getOptionLabel={(seller) => seller.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Farmer" />
                    )}
                    loading={gettingFarmersResponse}
                    onChange={(e, value) => {
                        setSelectedFarmer(value);
                    }}
                    sx={{ width: "300px" }}
                />
                <FormGroup sx={{ width: "fit-content" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(event) => {
                                    setPayingOff(!event.target.checked);
                                }}
                            />
                        }
                        label="Não contar para o mês corrente."
                    />
                </FormGroup>
                <Button
                    onClick={handleAtribuirButtonClick}
                    loading={assigningClients}
                >
                    <Typography variant="body2">Atribuir</Typography>
                </Button>
            </Box>
        </Box>
    );
};

export default GainToFarmer;
