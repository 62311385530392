import { useEffect, useState } from "react";

import {
    Autocomplete,
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";

import clientsService from "../../../../../services/clientsApiService";

import { IClient } from "../../../../../interfaces/AbacateApi/IClients";

import { usePopup } from "../../../../../hooks/usePopup";
import useMutation from "../../../../../hooks/useMutate";
import useFrexcoSellers from "../../../../../hooks/SellersApi/useFrexcoSellers";

import TextField from "../../../../../components/TextField";
import Button from "../../../../../components/Button";
import { MutationFunction } from "@tanstack/react-query";
import useTeams from "../../../../../hooks/SellersApi/useTeams";

interface IAddClientDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    queryKey: string[];
    callback: MutationFunction<any, any>;
}

const AddClientDialog = ({
    openDialog,
    setOpenDialog,
    queryKey,
    callback,
}: IAddClientDialog) => {
    const { addPopup } = usePopup();

    const [client, setClient] = useState<IClient>();

    const [payingOff, setPayingOff] = useState(true);
    const [CNPJ, setCNPJ] = useState("");
    const [responsible, setResponsible] = useState("");
    const [responsibleError, setResponsibleError] = useState(false);

    const addInOnboardingWallet = queryKey[0] === "onboarding-wallet";

    const handleClose = () => {
        setOpenDialog(false);
    };

    const getClient = () => {
        clientsService
            .getClientsWithoutFarmers({ cpf_cnpj: CNPJ })
            .then(({ data }) => {
                setClient(data.items[0]);
            })
            .catch(() => {
                addPopup({
                    title: "Erro ao buscar dados do cliente.",
                    type: "error",
                    duration: "temporary",
                });
            });
    };

    const handleCnpjChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCNPJ(event.target.value);
    };

    const { data: teamsResponse } = useTeams({
        active: true,
        role__in: JSON.stringify(["Farm"]),
    });

    const {
        data: frexcoSellersResponse,
        isFetching: gettingFrexcoSellersResponse,
    } = useFrexcoSellers({
        squads__overlap: teamsResponse?.results
            ? JSON.stringify(teamsResponse?.results.map((team) => team.name))
            : undefined,
        active: true,
        order_by: "name",
        page: 1,
    });

    const { mutate: addClientToWallet, isLoading: addingClientToWallet } =
        useMutation({
            successText: "Cliente adicionado a carteira.",
            errorText: "Erro ao adicionar cliente a carteira.",
            queryKeyArray: queryKey,
            callback: callback,
        });

    const handleAddClient = () => {
        if (!responsible) {
            setResponsibleError(true);
            return;
        }
        setResponsibleError(false);
        if (client) {
            addInOnboardingWallet
                ? addClientToWallet([
                      {
                          onboarding: responsible,
                          cnpj: CNPJ,
                          restaurant_name: client?.name,
                          client_name: client?.responsible_name!,
                          paying_off: payingOff,
                          active: true,
                      },
                  ])
                : addClientToWallet([
                      {
                          farmer: responsible,
                          cnpj: CNPJ,
                          restaurant_name: client?.name,
                          client_name: client?.responsible_name!,
                          paying_off: payingOff,
                          active: true,
                      },
                  ]);
        }
    };

    useEffect(() => {
        const isAvalidInput = CNPJ.length === 14;
        if (isAvalidInput) {
            getClient();
        }
    }, [CNPJ]);

    return (
        <Dialog
            open={openDialog}
            onClose={handleClose}
            sx={{
                "& .MuiTextField-root": {
                    margin: "0",
                },
            }}
        >
            <DialogTitle sx={{ backgroundColor: "primary.main" }}>
                <Typography textAlign={"center"} variant="h3" color={"#FAFAFA"}>
                    Adicionar cliente
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box
                    marginTop={"10px"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"10px"}
                >
                    <TextField
                        value={CNPJ}
                        onChange={handleCnpjChange}
                        label="CNPJ"
                        helperText="Somente 14 números."
                    />
                    <Typography variant="body2">
                        Nome do restaurante: {client?.name}
                    </Typography>
                    <Typography variant="body2">
                        Nome do cliente: {client?.responsible_name}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    width={"100%"}
                >
                    <FormGroup sx={{ width: "fit-content" }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(event) => {
                                        setPayingOff(!event.target.checked);
                                    }}
                                />
                            }
                            label="Não contar para o mês corrente."
                        />
                    </FormGroup>
                    <Autocomplete
                        fullWidth
                        id="responsible"
                        loading={gettingFrexcoSellersResponse}
                        options={
                            frexcoSellersResponse?.results.map(
                                (seller) => seller.name
                            ) || []
                        }
                        value={responsible}
                        onChange={(e, newValue) => {
                            setResponsible(newValue || "");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Responsável"
                                error={responsibleError}
                            />
                        )}
                    />
                    <Button
                        fullWidth
                        disabled={!client}
                        loading={addingClientToWallet}
                        onClick={handleAddClient}
                    >
                        <Typography variant="body2">ADICIONAR</Typography>
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default AddClientDialog;
