import { useState } from "react";

import { Box, Typography } from "@mui/material";

import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";

import Button from "../../../../../components/Button";
import { CreatePermissionDialog } from "../ManagePermissionsDialog";
import { PermissionGate } from "../../../../../components/PermissionGate";
import { AddUserToGroupDialog } from "../../../../../components/AddUserToGroup";

import { useAuth } from "../../../../../hooks/useAuth";
import { useStyles } from "./styles";

import AccordionItem from "./AccordionItem";
import { ManageUsersDialog } from "../ManageUsersDialog.tsx";
import { ManageGroupsDialog } from "../ManageGroupsDialog";

const UsersManagementPanel = () => {
    const classes = useStyles();
    const { permissions } = useAuth();

    const adminPermission = ["groselha | admin"];

    const [groupUsers] = useState([
        "Hunter",
        "Farmer",
        "Líder de Polo",
        "Líder Farmer",
        "Encantamento",
        "ADMIN",
    ]);

    const [openCreatePermissionDialog, setOpenCreatePermissionDialog] =
        useState(false);
    const [openManageUsersDialog, setOpenManageUsersDialog] = useState(false);
    const [openManageGroupsDialog, setOpenManageGroupsDialog] = useState(false);
    const [openAddUserToGroupDialog, setOpenAddUserToGroupDialog] =
        useState(false);

    const handleOpenCreatePermissionDialog = () => {
        setOpenCreatePermissionDialog(true);
    };

    const handleOpenManageUsersDialog = () => {
        setOpenManageUsersDialog(true);
    };

    const handleOpenCreateGroupDialog = () => {
        setOpenManageGroupsDialog(true);
    };

    return (
        <>
            <Box>
                <Box className={classes.usersHeader}>
                    <Typography variant="h3">Usuários e Permissões:</Typography>
                    <Box>
                        <PermissionGate
                            neededpermissions={adminPermission}
                            userPermissions={permissions}
                        >
                            <Button
                                onClick={handleOpenManageUsersDialog}
                                endIcon={<PersonOutlineOutlinedIcon />}
                            >
                                <Typography variant="body2">
                                    Usuários
                                </Typography>
                            </Button>
                            <Button
                                onClick={handleOpenCreateGroupDialog}
                                endIcon={<GroupsOutlinedIcon />}
                            >
                                <Typography variant="body2">Grupos</Typography>
                            </Button>
                            <Button
                                onClick={handleOpenCreatePermissionDialog}
                                endIcon={<BadgeOutlinedIcon fontSize="large" />}
                            >
                                <Typography variant="body2">
                                    Permissões
                                </Typography>
                            </Button>
                        </PermissionGate>
                    </Box>
                </Box>
                {groupUsers.map((group, index) => (
                    <AccordionItem key={index} group={group} />
                ))}
            </Box>
            {openCreatePermissionDialog && (
                <CreatePermissionDialog
                    openDialog={openCreatePermissionDialog}
                    setOpenDialog={setOpenCreatePermissionDialog}
                />
            )}
            {openManageGroupsDialog && (
                <ManageGroupsDialog
                    openDialog={openManageGroupsDialog}
                    setOpenDialog={setOpenManageGroupsDialog}
                />
            )}
            {openAddUserToGroupDialog && (
                <AddUserToGroupDialog
                    openDialog={openAddUserToGroupDialog}
                    setOpenDialog={setOpenAddUserToGroupDialog}
                />
            )}
            {openManageUsersDialog && (
                <ManageUsersDialog
                    openDialog={openManageUsersDialog}
                    setOpenDialog={setOpenManageUsersDialog}
                />
            )}
        </>
    );
};

export default UsersManagementPanel;
