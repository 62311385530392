import { useState } from "react";
import UpgradeOutlinedIcon from "@mui/icons-material/UpgradeOutlined";

import { IconButton, Tooltip, CircularProgress } from "@mui/material";

import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";

import useAlterComercialTeamMember from "../../../../../hooks/SellersApi/useAlterComercialMember";

import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";

interface IPromoteMemberButton {
    member: IFrexcoSeller;
    updatingFields: Partial<IFrexcoSeller>;
}

export const PromoteMemberButton = ({
    member,
    updatingFields,
}: IPromoteMemberButton) => {
    const [openConfimartionDialog, setOpenConfimartionDialog] = useState(false);

    const { mutate: alterMember, isLoading: alteringMember } =
        useAlterComercialTeamMember();

    const handleConfirmPromoteMember = () => {
        setOpenConfimartionDialog(true);
    };

    const promoteMember = () => {
        member = {
            ...member,
            ...updatingFields,
        };
        alterMember(member);
    };

    return (
        <>
            <Tooltip title="Promover Membro">
                <IconButton onClick={handleConfirmPromoteMember}>
                    {alteringMember ? (
                        <CircularProgress size={20} />
                    ) : (
                        <UpgradeOutlinedIcon color="primary" />
                    )}
                </IconButton>
            </Tooltip>
            <ConfirmationDialog
                openDialog={openConfimartionDialog}
                setOpenDialog={setOpenConfimartionDialog}
                callback={promoteMember}
                title="Tem certeza que deseja promover este membro?"
            />
        </>
    );
};
