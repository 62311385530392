import { Breadcrumbs as MuiBreadCrumbs } from "@mui/material";
import { ReactNode } from "react";

interface IBreadCrumbsRoot {
    children: ReactNode;
}

const BreadCrumbsRoot = ({ children }: IBreadCrumbsRoot) => {
    return (
        <MuiBreadCrumbs sx={{ marginBottom: "20px" }} aria-label="breadcrumb">
            {children}
        </MuiBreadCrumbs>
    );
};

export default BreadCrumbsRoot;
