import { useState } from "react";

import { IconButton, Tooltip } from "@mui/material";

import { ConfirmationDialog } from "../../../../../../../components/ConfirmationDialog";

import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import TeamsApi from "../../../../../../../services/SellersApi/teamsApi";
import useMutation from "../../../../../../../hooks/useMutate";
import { ITeams } from "../../../../../../../interfaces/SellersApi/ITeams";

const teamsApi = new TeamsApi();

interface IReactivateTeamCellRender {
    team: ITeams;
}

const ReactivateTeamCellRenderer = ({ team }: IReactivateTeamCellRender) => {
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const { mutate: reactivateTeam } = useMutation({
        successText: "Equipe reativada.",
        errorText: "Falha ao reativar equipe.",
        queryKeyArray: ["teams"],
        callback: teamsApi.put,
    });

    return (
        <>
            <Tooltip title="Reativar equipe">
                <IconButton onClick={() => setOpenConfirmationDialog(true)}>
                    <RestoreFromTrashOutlinedIcon color="primary" />
                </IconButton>
            </Tooltip>
            {openConfirmationDialog && (
                <ConfirmationDialog
                    setOpenDialog={setOpenConfirmationDialog}
                    openDialog={openConfirmationDialog}
                    title="Deseja mesmo reativar o time?"
                    callback={() =>
                        reactivateTeam({
                            id: team.id!,
                            object: { ...team, active: true },
                        })
                    }
                />
            )}
        </>
    );
};

export default ReactivateTeamCellRenderer;
