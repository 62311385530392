import { useQuery } from "@tanstack/react-query";
import DealLostReasonService from "../../services/SdrApi/dealLostReasonService";
import {
    SdrBasedErrors,
    SdrBasedResponses,
} from "../../utils/Api/responses/responses";
import { AxiosError } from "axios";

const dealLostReasonService = new DealLostReasonService();

const useDealLostReason = (query?: Object, definitions?: Object) =>
    useQuery<SdrBasedResponses, AxiosError<SdrBasedErrors>>({
        ...definitions,
        queryKey: ["deal-lost-reason", query],
        queryFn: () => dealLostReasonService.getDealLostReason(query),
        staleTime: 60 * 60 * 1000 ,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar motivos de perda.",
        },
    });
export default useDealLostReason;
