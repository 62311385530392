import { useMutation, useQueryClient } from "@tanstack/react-query";
import AreaDistributionParamsService from "../../services/SdrApi/areaDistributionParamsService";
import { usePopup } from "../usePopup";
import { AxiosError } from "axios";
import { SdrBasedErrors } from "../../utils/Api/responses/responses";

const areaDistributionParamsService = new AreaDistributionParamsService();

const useAlterAreaDistributionParams = () => {
    const queryClient = useQueryClient();
    const { addPopup } = usePopup();
    return useMutation(
        areaDistributionParamsService.putAreaDistributionParams,
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ["areas"],
                });
                addPopup({
                    title: "Ranking de areas registrado.",
                    type: "success",
                    duration: "temporary",
                });
            },
            onError: (error: AxiosError<SdrBasedErrors>) => {
                addPopup({
                    title: "Erro ao registrar ranking de areas.",
                    description: error.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            },
        }
    );
};
export default useAlterAreaDistributionParams;
