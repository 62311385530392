import { useQuery } from "@tanstack/react-query";
import ClientJourneyService from "../../services/SdrApi/clientJourneyService";
import {
    SdrBasedErrors,
    SdrBasedResponses,
} from "../../utils/Api/responses/responses";

const clientJourneyService = new ClientJourneyService();

const useClientJourney = (query?: Object, definitions?: Object) =>
    useQuery<SdrBasedResponses, SdrBasedErrors>({
        ...definitions,
        queryKey: ["client-journey", query],
        queryFn: () => clientJourneyService.getClientJourney(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar informação do cliente.",
        },
    });
export default useClientJourney;
