import { useQuery } from "@tanstack/react-query";
import { CriticalOrdersService } from "../../services/SdrApi/criticalOrdersService";
import { ICriticalOrdersQueryOptions } from "../../interfaces/SdrApi/QueriesOptions/ICriticalOrdersQueryOptions";

const criticalOrdersService = new CriticalOrdersService();

const useCriticalOrders = (
    query?: ICriticalOrdersQueryOptions,
    definitions?: Object
) =>
    useQuery({
        ...definitions,
        queryKey: ["critical-orders", query],
        queryFn: () => criticalOrdersService.get(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar pedidos especiais.",
        },
    });

export default useCriticalOrders;
