import { makeStyles } from "@mui/styles";
import theme from "../../../styles/theme";

export const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
  },
  background: {
    width: "100%",
    left: 0,
    bottom: 0,
    zIndex: 0,
  },
  overlay: {
    height: "100%",
    width: "100%",
    position: "absolute",
    zIndex: 1,
    background: "black",
    opacity: 0.7,
  },
  loginBox: {
    height: "100vh",
    minHeight: "500px",
    width: "50%",
    maxWidth: "580px",
    padding: "0 2%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#fafafa",
    zIndex: 2,
    [theme.breakpoints.between(0, 768)]: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      maxWidth: "1000px",
    },
  },
  logoImg: {
    width: "80%",
    maxWidth: "300px",
    position: "absolute",
    top: "10%",
  },
  title: {
    marginTop: "80px",
    paddingBottom: "2%",
    textAlign: "center",
    width: "100%",
    fontSize: "clamp(1.2rem, 2vw, 2vw)",
  },
  form: {
    width: "95%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formObjects: {
    height: "66px",
    width: "100%",
    maxWidth: "500px",
    margin: "0 auto",
    marginBottom: "3%",
    background: "#fff",
    "&.MuiButton-root":{
      backgroundColor: "#8BC34A",
    },
    borderRadius: "5px",
  },
}));
