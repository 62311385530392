import { useState } from "react";

import AreasButtons from "../AreasButtons";
import SalesAreaLevelsCards from "../SalesAreaLevelsCards";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import ComponentHeader from "../../../../../components/ComponentHeader";

import CreateAreaDialog from "../CreateAreaDialog";

const BonusConfig = () => {
    const [selectedSalesArea, setSelectedSalesArea] = useState<number>();
    const [openCreateAreaDialog, setOpenCreateAreaDialog] = useState(false);

    return (
        <>
            <ComponentHeader.Root>
                <ComponentHeader.Title text="Bonificações" />
                <ComponentHeader.Actions>
                    <ComponentHeader.Action
                        tooltip="Criar Área"
                        icon={
                            <AddCircleOutlineOutlinedIcon
                                fontSize="large"
                                color="info"
                            />
                        }
                        action={() => {
                            setOpenCreateAreaDialog(true);
                        }}
                    />
                </ComponentHeader.Actions>
                <ComponentHeader.Desc text="Com a funcionalidade de calculo de bonificações, você terá acesso a uma lista completa dos status de cada área com todos os valores fixos e as metas." />
            </ComponentHeader.Root>
            <AreasButtons setSelectedSalesAreas={setSelectedSalesArea} />
            <SalesAreaLevelsCards selectedSalesArea={selectedSalesArea} />
            <CreateAreaDialog
                openDialog={openCreateAreaDialog}
                setOpenDialog={setOpenCreateAreaDialog}
            />
        </>
    );
};

export default BonusConfig;
