import { useQuery } from "@tanstack/react-query";
import AreaManagementService from "../../services/SdrApi/areaManagementService";
import {
    SdrBasedErrors,
    SdrBasedResponses,
} from "../../utils/Api/responses/responses";
import { AxiosError } from "axios";

const areaManagementService = new AreaManagementService();

const useAreas = (query?: Object, definitions?: Object) =>
    useQuery<SdrBasedResponses, AxiosError<SdrBasedErrors>>({
        ...definitions,
        queryKey: ["areas", query],
        queryFn: () => areaManagementService.getAreas(query),
        staleTime: 60 * 60 * 1000 ,
        keepPreviousData: true,
    });
export default useAreas;
