import { IUtalkChannel } from "../../interfaces/SellersApi/IUtalkChannel";
import { IUtalkChannelQueryOptions } from "../../interfaces/SellersApi/QuerisOptions/IUtalkChannelQueryOptions";
import GenericApi from "../genericApi";

class UtalkChannelApi extends GenericApi<
    IUtalkChannel,
    IUtalkChannelQueryOptions,
    IUtalkChannel
> {
    constructor() {
        super("api/sellers/utalk/channels/");
    }
}

export default UtalkChannelApi;
