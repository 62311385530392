import { Theme, useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useAuth } from "../hooks/useAuth";

import { Menu } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import logo from "../assets/images/logo.png";
import { MenuListItems } from "./NavListItems";
import configLayout from "./ConfigLayout";

const { APPBAR, DRAWER_WIDTH } = configLayout;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  height: APPBAR,
}));

interface IMenuButton {
  openDrawer: boolean;
  theme: Theme;
}

const MenuButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "openDrawer",
})(({ theme, openDrawer }: IMenuButton) => ({
  marginRight: "64px",
  marginLeft: "-24px",
  padding: "24px",
  borderRadius: "0px",
  backgroundColor: "#EFEFEF",
  "& svg": {
    transform: openDrawer ? "rotate(180deg)" : "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const KeyboardDoubleArrowRightIconStyle = styled(KeyboardDoubleArrowRightIcon)(
  ({ theme }) => ({
    "& path:first-of-type": {
      color: theme.palette.secondary.main,
    },
    "& path:last-of-type": {
      color: theme.palette.primary.main,
    },
  })
);

interface INavBar {
  toggleDrawer: React.MouseEventHandler<HTMLButtonElement>;
  openDrawer: boolean;
}

export const NavBar = ({ toggleDrawer, openDrawer }: INavBar) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openProfileMenu = Boolean(anchorEl);
  const { user } = useAuth();
  const theme = useTheme();

  const handleProfileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        sx={{ backgroundColor: "#FAFAFA", boxShadow: "none" }}
        position="absolute"
      >
        <Toolbar
          sx={{
            pr: "24px",
          }}
        >
          <MenuButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            openDrawer={openDrawer}
            theme={theme}
          >
            <KeyboardDoubleArrowRightIconStyle />
          </MenuButton>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Box
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                    [theme.breakpoints.down("md")]: {
                        display: "none",
                    },
                }}
            >
                <img
                    src={logo}
                    alt="logomarca da empresa"
                    style={{ width: "2.50rem", height: "2.50rem" }}
                />

                <Typography
                    variant="subtitle2"
                    color="secondary.light"
                    noWrap
                >
                    Groselha
                </Typography>
                <Typography
                    variant="subtitle2"
                    color="primary.main"
                    noWrap
                >
                    .Frexco
                </Typography>
            </Box>
        </Link>
          <IconButton
            color="inherit"
            onClick={handleProfileMenuClick}
            sx={{ marginLeft: "auto", borderRadius: "20%" }}
          >
            <AccountCircleIcon color="primary" sx={{ fontSize: 50 }} />
            <Typography
              variant="h4"
              color="secondary.light"
              sx={{ textAlign: "left", lineHeight: "1.2rem" }}
            >
              Olá,
              <br />
              <strong>{user}.</strong>
            </Typography>
          </IconButton>
        </Toolbar>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openProfileMenu}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 0.5,
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuListItems />
        </Menu>
      </AppBar>
    </>
  );
};
