import { AxiosResponse } from "axios";
import { api } from "../../utils/Api/apiClient";
import { IBonusResultsQueryOptions } from "../../interfaces/SalesBonus/QueriesOptions/BonusResultsQueryOptions";
import { TBonusPayment } from "../../interfaces/SalesBonus/IBonusPayment";

class BonusResultsApi {
    endpoint = "/api/sales-bonus-api/bonus/";

    get = (params?: IBonusResultsQueryOptions) =>
        api
            .get(this.endpoint, { params: params })
            .then((res: AxiosResponse<TBonusPayment[]>) => res.data);
}

export default BonusResultsApi;
