import PageContainer from "../../../components/PageContainer";
import FarmerWallet from "./components/FarmerWallet";
import OnboardingWallet from "./components/OnboardingWallet";

const Wallets = () => {
    return (
        <PageContainer>
            <OnboardingWallet />
            <FarmerWallet />
        </PageContainer>
    );
};

export default Wallets;
