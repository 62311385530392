import {
    IFarmerWallet,
    IOnboardingWallet,
} from "../interfaces/SalesBonus/IWallets";

export const isInstanceOfOnboardingWallet = (
    object: any
): object is IOnboardingWallet => {
    return "onboarding" in object;
};

export const isInstanceOfFarmerWallet = (
    object: any
): object is IFarmerWallet => {
    return "farmer" in object;
};
