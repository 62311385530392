import { useState } from "react";

import {
    Box,
    FormGroup,
    FormControlLabel,
    Typography,
    Checkbox,
    Stack,
    Pagination,
} from "@mui/material";

import { ITeams } from "../../../../../interfaces/SellersApi/ITeams";

import useTeams from "../../../../../hooks/SellersApi/useTeams";
import useMutation from "../../../../../hooks/useMutate";

import Button from "../../../../../components/Button";
import DeleteItemCellRenderer from "../../../../../components/AgGrid/CellRenderers/DeleteItem";
import ReactivateTeamCellRenderer from "./components/ReactivateTeamCellRenderer";
import EditTeamCellRenderer from "./components/EditTeamCellRenderer";
import DataGrid from "../../../../../components/DataGrid";
import CreateTeamDialog from "../CreateTeamDialog";

import TeamsApi from "../../../../../services/SellersApi/teamsApi";
import { GridColDef } from "@mui/x-data-grid";
import { ITeamsQueryOptions } from "../../../../../interfaces/SellersApi/QuerisOptions/ITeamsQueryOptions";

const teamsApi = new TeamsApi();

const TeamsManagementGrid = () => {
    const [teamsStatus, setTeamsStatus] = useState(true);
    const [openCreateTeamDialog, setOpenCreateTeamDialog] = useState(false);

    const [teamsParams, setTeamsParams] = useState<ITeamsQueryOptions>({
        page: 1,
    });

    const { mutate: deleteTeam } = useMutation({
        successText: "Equipe deletada.",
        errorText: "Falha ao deletar equipe.",
        queryKeyArray: ["teams"],
        callback: teamsApi.delete,
    });

    const [columnDefs] = useState<GridColDef<ITeams>[]>([
        {
            field: "name",
            minWidth: 300,
            headerName: "Nome",
            flex: 1,
        },
        {
            field: "area",
            minWidth: 150,
            headerName: "Area",
            flex: 1,
        },
        {
            field: "role",
            minWidth: 150,
            headerName: "Função",
            flex: 1,
        },
        {
            field: "active",
            minWidth: 150,
            headerName: "Status",
            flex: 1,
            renderCell: (params) => {
                return params.value ? "Ativo" : "Inativo";
            },
        },
        {
            field: "editTeam",
            valueGetter: (params) => params.row.active,
            width: 80,
            headerName: "",
            renderCell: (params) => {
                return params.value
                    ? EditTeamCellRenderer({ team: params.row })
                    : "";
            },
        },
        {
            field: "id",
            width: 80,
            headerName: "",
            renderCell: (params) => {
                return params.row.active
                    ? DeleteItemCellRenderer({
                          callback: () => deleteTeam(params.value),
                      })
                    : ReactivateTeamCellRenderer({ team: params.row });
            },
        },
    ]);

    const { data: getTeamsResponse, isFetching: gettingTeamsResponse } =
        useTeams({
            active: teamsStatus,
            ...teamsParams,
        });

    return (
        <>
            <Box>
                <Box>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <FormGroup sx={{ width: "fit-content" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) =>
                                            setTeamsStatus(
                                                !event.target.checked
                                            )
                                        }
                                    />
                                }
                                label="Exibir equipes inativas."
                            />
                        </FormGroup>
                        <Button onClick={() => setOpenCreateTeamDialog(true)}>
                            <Typography variant="body2">
                                Criar Equipe
                            </Typography>
                        </Button>
                    </Box>
                </Box>
                <Box width={"100%"} height={"500px"}>
                    <DataGrid
                        columns={columnDefs}
                        rows={
                            getTeamsResponse?.results
                                ? getTeamsResponse?.results
                                : []
                        }
                        loading={gettingTeamsResponse}
                    />
                    {getTeamsResponse && (
                        <Box
                            display={"flex"}
                            marginTop={"10px"}
                            justifyContent={"end"}
                        >
                            <Stack spacing={2}>
                                <Pagination
                                    count={Math.ceil(
                                        getTeamsResponse?.info.count /
                                            getTeamsResponse?.info.per_page
                                    )}
                                    page={teamsParams.page}
                                    onChange={(e, value) =>
                                        setTeamsParams((prevState) => ({
                                            ...prevState,
                                            page: value,
                                        }))
                                    }
                                    shape="rounded"
                                />
                            </Stack>
                        </Box>
                    )}
                </Box>
            </Box>
            {openCreateTeamDialog && (
                <CreateTeamDialog
                    setOpenDialog={setOpenCreateTeamDialog}
                    openDialog={openCreateTeamDialog}
                />
            )}
        </>
    );
};

export default TeamsManagementGrid;
