import {
    Autocomplete,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";

import Button from "../../../../../components/Button";
import TextField from "../../../../../components/TextField";

import { useState } from "react";

import { makeStyles } from "@mui/styles";

import { ISeller } from "../../../../../interfaces/SellersApi/ISeller";

import { FieldValues, useForm } from "react-hook-form";
import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";
import useAlterClientsWithoutFarmerParamsService from "../../../../../hooks/SdrApi/useAlterClientsWithoutFarmerParams";
import { ITeams } from "../../../../../interfaces/SellersApi/ITeams";

export const useStyles = makeStyles(() => ({
    inputsContainer: {
        width: "100%",
        display: "flex",
        gap: "2px",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 0px",
        "& .MuiTextField-root": {
            margin: "10px 0 0 0",
        },
    },
    actionsBox: {
        display: "flex",
        justifyContent: "center",
    },
}));

interface ITeamDefinitionsDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    team: ITeams;
    hunters: ISeller[];
    farmers: IFrexcoSeller[];
}

const TeamDefinitionsDialog = ({
    openDialog,
    setOpenDialog,
    team,
    hunters,
    farmers,
}: ITeamDefinitionsDialog) => {
    const classes = useStyles();
    const [gettingFarmers] = useState(false);

    const handleClose = () => {
        setOpenDialog(false);
    };

    const {
        handleSubmit,
        setError,
        formState: { errors },
        setValue,
    } = useForm();

    const { mutate, isLoading: alteringClientsWithoutFarmerParams } =
        useAlterClientsWithoutFarmerParamsService();

    const submitForm = (data: FieldValues) => {
        if (!data.farmer) {
            setError("farmer", { type: "custom", message: "Obrigatório" });
            return;
        }
        const huntersWithResponsibleFarmer = hunters.map((hunter) => ({
            hunter_id: parseInt(hunter.id!),
            farmer_id: data.farmer.id as number,
        }));
        mutate(huntersWithResponsibleFarmer);
    };

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>
                <Typography variant="h3" sx={{ textAlign: "center" }}>
                    Parâmetros do time {team.name}
                </Typography>
            </DialogTitle>
            <form onSubmit={handleSubmit(submitForm)}>
                <DialogContent>
                    <Autocomplete
                        sx={{
                            "& .MuiTextField-root": {
                                margin: "10px 0 0 0",
                            },
                        }}
                        loading={gettingFarmers}
                        id="tags-standard"
                        options={farmers}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => setValue("farmer", value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Farmer"
                                error={!!errors?.farmer}
                                helperText={
                                    errors?.farmer?.message ? (
                                        <>{errors?.farmer?.message}</>
                                    ) : (
                                        <></>
                                    )
                                }
                            />
                        )}
                    />
                </DialogContent>
                <Box className={classes.actionsBox}>
                    <Button
                        type="submit"
                        loading={alteringClientsWithoutFarmerParams}
                    >
                        <Typography variant="body2">Enviar</Typography>
                    </Button>
                </Box>
            </form>
        </Dialog>
    );
};

export default TeamDefinitionsDialog;
