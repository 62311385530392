import { useQuery } from "@tanstack/react-query";
import FrexcoSellersApi from "../../services/SellersApi/frexcoSellersApi";
import { IFrexcoSellerQueryOptions } from "../../interfaces/SellersApi/QuerisOptions/IFrexcoSellerQueryOptions";

const frexcoSellersApi = new FrexcoSellersApi();

const useFrexcoSellers = (query?: IFrexcoSellerQueryOptions, definitions?: Object) =>
    useQuery({
        ...definitions,
        queryKey: ["frexco-sellers", query],
        queryFn: () => frexcoSellersApi.get(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar sellers.",
        },
    });
export default useFrexcoSellers;
