import { useCallback, useEffect, useState } from "react";

import sellersApiService from "../../../../../services/SellersApi/sellersApiService";
import { AxiosError, AxiosResponse } from "axios";
import {
    SdrBasedErrors,
    SdrBasedResponses,
} from "../../../../../utils/Api/responses/responses";

import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/material";

import { usePopup } from "../../../../../hooks/usePopup";
import { useStyles } from "./styles";

import {
    ISeller,
    ISellerWithDistributionParams,
} from "../../../../../interfaces/SellersApi/ISeller";
import AccordionItem from "./AccordionItem";

import useSellerDistributionParams from "../../../../../hooks/SdrApi/useSellerDistributionParams";
import useTeams from "../../../../../hooks/SellersApi/useTeams";

const AutomaticAssignmenteParams = () => {
    const { addPopup } = usePopup();
    const classes = useStyles();

    const [hunters, setHunters] = useState<ISeller[]>([]);
    const [huntersWithDistributionParams, setHuntersWithDistributionParams] =
        useState<ISellerWithDistributionParams[]>([]);
    const [gettingHunters, setGettingHunters] = useState(false);

    const { data: teamsResponse, isFetching: gettingTeamsResponse } = useTeams({
        active: true,
        role__in: JSON.stringify(["Hunter"]),
    });

    const getHunters = useCallback(async () => {
        setGettingHunters(true);
        await sellersApiService
            .getFrexcoSellers({
                is_team_leader: false,
                is_regional_leader: false,
                squads__overlap: teamsResponse?.results
                    ? JSON.stringify(
                          teamsResponse?.results.map((team) => team.name)
                      )
                    : undefined,
                active: true,
            })
            .then(async (resp: AxiosResponse<SdrBasedResponses>) => {
                let count = resp.data.info.count;
                await sellersApiService
                    .getFrexcoSellers({
                        is_team_leader: false,
                        is_regional_leader: false,
                        squads__overlap: teamsResponse?.results
                            ? JSON.stringify(
                                  teamsResponse?.results.map(
                                      (team) => team.name
                                  )
                              )
                            : undefined,
                        active: true,
                        per_page: count,
                        order_by: "name",
                    })
                    .then((resp: AxiosResponse<SdrBasedResponses>) => {
                        var sellers = resp.data.results;
                        sellers.push({
                            name: "inbound",
                            rdstation: {
                                external_id: "",
                            },
                            squads: ["without_team"],
                        });
                        setHunters(sellers);
                    });
            })
            .catch((err: AxiosError<SdrBasedErrors>) => {
                addPopup({
                    title: "Falha ao buscar Hunters.",
                    description: err.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            })
            .finally(() => setGettingHunters(false));
    }, [addPopup, teamsResponse]);

    const {
        data: sellerDistributionParams,
        isFetching: gettinSellerDistributionParams,
        error: gettinSellerDistributionParamsError,
    } = useSellerDistributionParams();

    useEffect(() => {
        if (!(gettinSellerDistributionParams || gettingHunters)) {
            if (!gettinSellerDistributionParamsError && hunters) {
                const huntersWithDistributionParams: ISellerWithDistributionParams[] =
                    [];
                hunters.forEach((hunter) => {
                    let hunterParams = [];
                    hunterParams = sellerDistributionParams?.results
                        ? sellerDistributionParams?.results.filter(
                              (hunterParams) =>
                                  hunterParams.seller_id === hunter.id
                          )
                        : [];
                    huntersWithDistributionParams.push({
                        ...hunter,
                        new: !!hunterParams.length ? hunterParams[0].new : 0,
                        lost: !!hunterParams.length ? hunterParams[0].lost : 0,
                        inactive: !!hunterParams.length
                            ? hunterParams[0].inactive
                            : 0,
                        lost_reason: !!hunterParams.length
                            ? hunterParams[0].lost_reason
                            : "",
                    });
                });
                setHuntersWithDistributionParams(huntersWithDistributionParams);
            }
        }
    }, [
        gettinSellerDistributionParams,
        gettingHunters,
        gettinSellerDistributionParamsError,
        sellerDistributionParams?.results,
        hunters,
        sellerDistributionParams,
    ]);

    useEffect(() => {
        if (teamsResponse) {
            getHunters();
        }
    }, [getHunters, teamsResponse]);

    return (
        <>
            <Box className={classes.container}>
                {!gettingTeamsResponse ? (
                    teamsResponse?.results.map((team) => (
                        <AccordionItem
                            team={team}
                            hunters={huntersWithDistributionParams.filter(
                                (hunter) => hunter.squads.includes(team.name)
                            )}
                            key={team.id}
                        />
                    ))
                ) : (
                    <>
                        <Skeleton sx={{ height: "80px" }} />
                        <Skeleton sx={{ height: "80px" }} />
                        <Skeleton sx={{ height: "80px" }} />
                        <Skeleton sx={{ height: "80px" }} />
                        <Skeleton sx={{ height: "80px" }} />
                    </>
                )}
            </Box>
        </>
    );
};

export default AutomaticAssignmenteParams;
