import { Box } from "@mui/material";

import UsersManagementPanel from "./components/UsersManagementPanel";

import { useStyles } from "./styles";
import ComponentHeader from "../../../components/ComponentHeader";

const AdminPanel = () => {
    const classes = useStyles();

    return (
        <>
            <ComponentHeader.Root>
                <ComponentHeader.Title text="Painel de Administração" />
            </ComponentHeader.Root>
            <Box className={classes.contentContainer}>
                <UsersManagementPanel />
            </Box>
        </>
    );
};

export default AdminPanel;
