import { useQuery } from "@tanstack/react-query";
import BonusResultsApi from "../../services/SalesBonusApi/bonusResults";
import { IBonusResultsQueryOptions } from "../../interfaces/SalesBonus/QueriesOptions/BonusResultsQueryOptions";

const bonusResultsApi = new BonusResultsApi();

const useBonusResults = (
    query?: IBonusResultsQueryOptions,
    definitions?: Object
) =>
    useQuery({
        ...definitions,
        queryKey: ["bonus-results", query],
        queryFn: () => bonusResultsApi.get(query),
        staleTime: 5 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar resultados.",
        },
    });
export default useBonusResults;
