export const TemplateData = [
    {
        "Chance de contato": "Alta",
        CNPJ: "27851775000127",
        "Razão Social": "STEAKHOUSE",
        "Nome Fantasia": "MAXISTEAKHOUSE RESTAURANTE LTDA",
        "Cód Atividade": "5611201",
        Atividade: "Restaurantes e similares",
        Endereço:
            "AVENIDA ANTONIO FREDERICO OZANAN 6000, VILA RIO BRANCO, Jundiaí",
        Bairro: "VILA RIO BRANCO",
        Cidade: "Jundiaí",
        CEP: "13215276",
        "Precisão Geometria": "CEP",
        Email: "MAXISHOPPING@DETROITSTEAKHOUSE.COM.BR",
        "Telefone 1": "1128169652",
        "Telefone 2": "1145233347",
    },
    {
        "Chance de contato": "Alta",
        CNPJ: "22038527000148",
        "Razão Social": "ARMAZEM BALARDIN",
        "Nome Fantasia": "MARCILIO JUSTEN",
        "Cód Atividade": "4712100",
        Atividade:
            "Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - minimercados, mercearias e armazéns",
        Endereço: "RUA DO RETIRO 1787, VILA DAS HORTENCIAS, Jundiaí",
        Bairro: "VILA DAS HORTENCIAS",
        Cidade: "Jundiaí",
        CEP: "13209355",
        "Precisão Geometria": "CEP",
        Email: "BALARDINJUNDIAI@GMAIL.COM",
        "Telefone 1": "1145217503",
        "Telefone 2": "",
    },
];

export const TemplateDataPhoneValidation = [
    {
        "Chance de contato": "Alta",
        CNPJ: "27851775000127",
        "Razão Social": "STEAKHOUSE",
        "Nome Fantasia": "MAXISTEAKHOUSE RESTAURANTE LTDA",
        "Cód Atividade": "5611201",
        Atividade: "Restaurantes e similares",
        Endereço:
            "AVENIDA ANTONIO FREDERICO OZANAN 6000, VILA RIO BRANCO, Jundiaí",
        Bairro: "VILA RIO BRANCO",
        Cidade: "Jundiaí",
        CEP: "13215276",
        "Precisão Geometria": "CEP",
        Email: "MAXISHOPPING@DETROITSTEAKHOUSE.COM.BR",
        formatted_phone_number: "22998356704",
    },
    {
        "Chance de contato": "Alta",
        CNPJ: "22038527000148",
        "Razão Social": "ARMAZEM BALARDIN",
        "Nome Fantasia": "MARCILIO JUSTEN",
        "Cód Atividade": "4712100",
        Atividade:
            "Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - minimercados, mercearias e armazéns",
        Endereço: "RUA DO RETIRO 1787, VILA DAS HORTENCIAS, Jundiaí",
        Bairro: "VILA DAS HORTENCIAS",
        Cidade: "Jundiaí",
        CEP: "13209355",
        "Precisão Geometria": "CEP",
        Email: "BALARDINJUNDIAI@GMAIL.COM",
        formatted_phone_number: "22998358763",
    },
];

export const HardCodedGroups = [
    {
        id: 1,
        name: "Hunter",
    },
    {
        id: 2,
        name: "Farmer",
    },
    {
        id: 3,
        name: "Líder de Polo",
    },
    {
        id: 4,
        name: "Líder Farmer",
    },
    {
        id: 5,
        name: "Encantamento",
    },
    {
        id: 6,
        name: "ADMIN",
    },
];
