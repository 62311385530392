import { api } from "../../../utils/Api/apiClient";

interface IBonusResultsQueryOptions {
    month: number;
    year: number;
    action: "simulate" | "download";
}

export const handleDowload = async ({
    month,
    year,
    action,
}: IBonusResultsQueryOptions) => {
    const endpoint = `/api/sales-bonus-api/bonus/?month=${month}&year=${year}&action=${action}`;
    const { data } = await api.get(endpoint, {
        responseType: "arraybuffer",
    });

    const URL = window.URL.createObjectURL(
        new Blob([data], {
            type: "application/xlsx",
        })
    );

    const downloadURL = document.createElement("a");
    downloadURL.href = URL;
    downloadURL.setAttribute(
        "download",
        `bonus_${month.toString().padStart(2, "0")}_${year}.xlsx`
    );
    
    document.body.appendChild(downloadURL);
    downloadURL.click();
    downloadURL.parentNode!.removeChild(downloadURL);
};
