import { ITeams } from "../../interfaces/SellersApi/ITeams";
import { ITeamsQueryOptions } from "../../interfaces/SellersApi/QuerisOptions/ITeamsQueryOptions";
import GenericApi from "../genericApi";

class TeamsApi extends GenericApi<ITeams, ITeamsQueryOptions, ITeams> {
    constructor() {
        super("api/sellers/teams/");
    }
}

export default TeamsApi;
