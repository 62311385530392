import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
    contentContainer: {
        marginY: "15px",
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
    },
}));
