import { IOnboardingWallet } from "../../interfaces/SalesBonus/IWallets";
import { IOnboardingWalletsQueryOptions } from "../../interfaces/SalesBonus/QueriesOptions/IWalletsQueryOptions";
import { api } from "../../utils/Api/apiClient";
import GenericApi from "../genericApi";

class OnboardingWalletApi extends GenericApi<
    IOnboardingWallet,
    IOnboardingWalletsQueryOptions,
    IOnboardingWallet
> {
    constructor() {
        super("/api/sales-bonus-api/onboarding-wallet/");
    }

    put = ({
        id,
        object,
    }: {
        id?: number;
        object: Partial<IOnboardingWallet> | IOnboardingWallet[];
    }) =>
        api
            .put(
                `/api/sales-bonus-api/onboarding-wallet/${id ? id + "/" : ""}`,
                {
                    data: object,
                }
            )
            .then((res) => res.data);
}

export default OnboardingWalletApi;
