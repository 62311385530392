import { useState } from "react";

import {
    Autocomplete,
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";

import {
    IFarmerWallet,
    IOnboardingWallet,
} from "../../../../../interfaces/SalesBonus/IWallets";

import TextField from "../../../../../components/TextField";
import Button from "../../../../../components/Button";

import useTeams from "../../../../../hooks/SellersApi/useTeams";
import useFrexcoSellers from "../../../../../hooks/SellersApi/useFrexcoSellers";
import useMutation from "../../../../../hooks/useMutate";

import {
    isInstanceOfFarmerWallet,
    isInstanceOfOnboardingWallet,
} from "../../../../../utils/instanceCheckers";
import WalletMovementApi from "../../../../../services/SalesBonusApi/walletMovement";
import { IWalletMovement } from "../../../../../interfaces/SalesBonus/IWalletMovement";

const walletMovementApi = new WalletMovementApi();

interface IMoveClientsDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    clients: IFarmerWallet[] | IOnboardingWallet[];
}

const MoveClientsDialog = ({
    openDialog,
    setOpenDialog,
    clients,
}: IMoveClientsDialog) => {
    const [moveToOnboardingWallet, setMoveToOnboardingWallet] = useState(true);
    const [moveToFarmWallet, setMoveToFarmWallet] = useState(false);
    const [responsible, setResponsible] = useState("");
    const [responsibleError, setResponsibleError] = useState(false);

    const { data: teamsResponse } = useTeams({
        active: true,
        role__in: JSON.stringify(["Farm"]),
    });

    const {
        data: frexcoSellersResponse,
        isFetching: gettingFrexcoSellersResponse,
    } = useFrexcoSellers({
        squads__overlap: teamsResponse?.results
            ? JSON.stringify(teamsResponse?.results.map((team) => team.name))
            : undefined,
        active: true,
        order_by: "name",
        page: 1,
    });

    const { mutate: moveWalletsRecords, isLoading: movingWalletRecords } =
        useMutation({
            successText: "Movimentação realizada.",
            errorText: "Erro ao realizar movimentação.",
            queryKeyArray: ["onboarding-wallet", "farmer-wallet"],
            callback: walletMovementApi.post,
        });

    const moveClients = () => {
        if (!responsible) {
            setResponsibleError(true);
            return;
        }

        if (moveToOnboardingWallet) {
            if (isInstanceOfOnboardingWallet(clients[0])) {
                const clientsToMove = clients.map((client) => ({
                    ...client,
                    onboarding: responsible,
                }));
                let reqBody: IWalletMovement = {
                    from_wallet: "onboarding_wallet",
                    to_wallet: "onboarding_wallet",
                    data: clientsToMove,
                };
                moveWalletsRecords(reqBody);
            } else {
                const clientsToMove = clients.map((client) => ({
                    ...client,
                    farmer: responsible,
                }));
                let reqBody: IWalletMovement = {
                    from_wallet: "farmer_wallet",
                    to_wallet: "onboarding_wallet",
                    data: clientsToMove,
                };
                moveWalletsRecords(reqBody);
            }
        } else {
            if (isInstanceOfFarmerWallet(clients[0])) {
                const clientsToMove = clients.map((client) => ({
                    ...client,
                    farmer: responsible,
                }));
                let reqBody: IWalletMovement = {
                    from_wallet: "farmer_wallet",
                    to_wallet: "farmer_wallet",
                    data: clientsToMove,
                };
                moveWalletsRecords(reqBody);
            } else {
                const clientsToMove = clients.map((client) => ({
                    ...client,
                    farmer: responsible,
                }));
                let reqBody: IWalletMovement = {
                    from_wallet: "onboarding_wallet",
                    to_wallet: "farmer_wallet",
                    data: clientsToMove,
                };
                moveWalletsRecords(reqBody);
            }
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <Dialog
            open={openDialog}
            onClose={handleClose}
            sx={{
                "& .MuiTextField-root": {
                    margin: "0",
                },
            }}
        >
            <DialogTitle sx={{ backgroundColor: "primary.main" }}>
                <Typography textAlign={"center"} variant="h3" color={"#FAFAFA"}>
                    Mover clientes
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box
                    marginTop={"10px"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"10px"}
                >
                    <FormGroup sx={{ width: "fit-content" }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={moveToOnboardingWallet}
                                    onChange={(event) => {
                                        setMoveToOnboardingWallet(
                                            event.target.checked
                                        );
                                        setMoveToFarmWallet(
                                            !event.target.checked
                                        );
                                    }}
                                />
                            }
                            label="Para Carteira Onboarding"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={moveToFarmWallet}
                                    onChange={(event) => {
                                        setMoveToFarmWallet(
                                            event.target.checked
                                        );
                                        setMoveToOnboardingWallet(
                                            !event.target.checked
                                        );
                                    }}
                                />
                            }
                            label="Para Carteira Farmer"
                        />
                    </FormGroup>
                    <Autocomplete
                        fullWidth
                        id="responsible"
                        loading={gettingFrexcoSellersResponse}
                        options={
                            frexcoSellersResponse?.results.map(
                                (seller) => seller.name
                            ) || []
                        }
                        value={responsible}
                        onChange={(e, newValue) => {
                            setResponsible(newValue || "");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Responsável"
                                error={responsibleError}
                            />
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    width={"100%"}
                >
                    <Button onClick={moveClients} loading={movingWalletRecords}>
                        <Typography variant={"body2"}>MOVER</Typography>
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default MoveClientsDialog;
