import { ICriticalOrder } from "../../interfaces/SdrApi/ICriticalOrders";
import { ICriticalOrdersQueryOptions } from "../../interfaces/SdrApi/QueriesOptions/ICriticalOrdersQueryOptions";
import { SdrBasedGenericResponses } from "../../utils/Api/responses/responses";
import GenericApi from "../genericApi";

interface SelectedMethods {
    get(
        params?: ICriticalOrdersQueryOptions
    ): Promise<SdrBasedGenericResponses<ICriticalOrder>>;
}

export class CriticalOrdersService {
    private genericApi: SelectedMethods;

    constructor() {
        this.genericApi = new GenericApi<
            ICriticalOrder,
            ICriticalOrdersQueryOptions,
            ICriticalOrder
        >("api/sdr/critical-orders/");
    }

    get(params?: ICriticalOrdersQueryOptions) {
        return this.genericApi.get(params);
    }
}
