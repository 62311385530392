import { ISalesGoalType } from "../../interfaces/SalesBonus/ISalesGoalType";
import { ISalesGoalsTypesQueryOptions } from "../../interfaces/SalesBonus/QueriesOptions/ISalesGoalsTypesQueryOptions";
import GenericApi from "../genericApi";

class SalesGoalsTypesApi extends GenericApi<
    ISalesGoalType,
    ISalesGoalsTypesQueryOptions,
    ISalesGoalType
> {
    constructor() {
        super("/api/sales-bonus-api/goal-type/");
    }
}

export default SalesGoalsTypesApi;
