import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTransition } from "react-spring";

import { Popup } from "./popUp";

import { TPopupMessage } from "../../@types/popup";

export const useStyles = makeStyles((theme: Theme) => ({
  teste: {
    position: "absolute",
    right: "0",
    top: "0",
    padding: "30px",
    overflow: "hidden",
    [theme.breakpoints.between(0, 600)]: {
      width: "100%",
    },
  },
}));

interface IPopupContainer {
  messages: TPopupMessage[];
  duration: string;
}

export const PopupContainer = ({ messages, duration }: IPopupContainer) => {
  const classes = useStyles();

  const transition = useTransition(messages, {
    keys: (message) => message.id,
    from: { right: "-120%", opacity: 0 },
    enter: { right: "0%", opacity: 1 },
    leave: { right: "-120%", opacity: 0 },
  });

  return (
    <div className={classes.teste}>
      {transition((style, popup) => (
        <Popup style={style} message={popup} duration={duration} />
      ))}
    </div>
  );
};
