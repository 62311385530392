import { useQuery } from "@tanstack/react-query";
import SalesGoalsClassApi from "../../services/SalesBonusApi/salesGoalsClassApi";

const salesGoalsClassApi = new SalesGoalsClassApi();

const useSalesGoalsClass = (query?: Object, definitions?: Object) =>
    useQuery({
        ...definitions,
        queryKey: ["sales-goals-class", query],
        queryFn: () => salesGoalsClassApi.get(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar classes de metas.",
        },
    });
export default useSalesGoalsClass;
