import { ISalesLevel } from "../../interfaces/SalesBonus/ISalesLevel";
import { ISalesLevelPost } from "../../interfaces/SalesBonus/PostObjectsInterfaces/ISalesLevelPost";
import { ISalesLevelsQueryOptions } from "../../interfaces/SalesBonus/QueriesOptions/ISalesLevelsQueryOptions";
import GenericApi from "../genericApi";

class SalesLevelsApi extends GenericApi<
    ISalesLevel,
    ISalesLevelsQueryOptions,
    ISalesLevelPost
> {
    constructor() {
        super("/api/sales-bonus-api/sales-level/");
    }
}

export default SalesLevelsApi;
