import { Card } from "@mui/material";
import { ReactNode } from "react";

interface ICardRoot {
    children: ReactNode;
}

const CardRoot = ({ children }: ICardRoot) => {
    return <Card>{children}</Card>;
};

export default CardRoot;
