import { Box, Divider } from "@mui/material";
import { ReactNode } from "react";

interface ICardActions {
    children: ReactNode;
}

const CardActions = ({ children }: ICardActions) => {
    return (
        <>
            <Divider />
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"15px"}
                padding={"5px 0"}
            >
                {children}
            </Box>
        </>
    );
};

export default CardActions;
