import TopSellersTable from "./components/TopSellersTable";
import HunterSalesTable from "./components/HunterSalesTable";

import { useStyles } from "./styles";
import PageContainer from "../../../components/PageContainer";
import DistributionSummaryGrid from "./components/DistributionSummaryGrid";

const HunterSales = () => {
    const classes = useStyles();

    return (
        <>
            <PageContainer>
                <HunterSalesTable />
                <TopSellersTable />
                <DistributionSummaryGrid />
            </PageContainer>
        </>
    );
};

export default HunterSales;
