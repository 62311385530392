import { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { Dayjs } from "dayjs";

import ComponentHeader from "../../../../../components/ComponentHeader";
import Button from "../../../../../components/Button";
import { PermissionGate } from "../../../../../components/PermissionGate";

import { usePopup } from "../../../../../hooks/usePopup";
import useBonusResults from "../../../../../hooks/SalesBonusApi/useBonusResults";
import { useAuth } from "../../../../../hooks/useAuth";

import { handleDowload } from "../../../../../services/SalesBonusApi/utils/downloadResults";

const Actions = () => {
    const { permissions } = useAuth();
    const { addPopup } = usePopup();

    const [monthYear, setMonthYear] = useState<Dayjs | null>(null);
    const [month, setMonth] = useState<number | undefined>(undefined);
    const [year, setYear] = useState<number | undefined>(undefined);

    const [calculate, setCalculate] = useState(false);

    const [loading, setLoading] = useState(false);

    const exportResults = (action: "download" | "simulate") => {
        addPopup({
            title: "O pedido está sendo processado...",
            description:
                "Assim que o processo terminar será feito o download do arquivo com os resultados.",
            type: "info",
            duration: "temporary",
        });
        setLoading(true);
        handleDowload({ month: month!, year: year!, action: action })
            .then()
            .catch(() => {
                addPopup({
                    title: "Falha ao gerar resultados...",
                    description:
                        "Entre em contato com um administrador do grupo.",
                    type: "error",
                    duration: "temporary",
                });
            })
            .finally(() => setLoading(false));
    };

    const { data } = useBonusResults(
        { month: month!, year: year!, action: "calculate" },
        { enabled: calculate }
    );

    useEffect(() => {
        if (calculate) {
            setCalculate(false);
        }
    }, [calculate]);

    return (
        <Box>
            <ComponentHeader.Root>
                <ComponentHeader.Title text="Ações" />
                <ComponentHeader.Desc
                    text={
                        <>
                            <br />
                            Nesta seção você pode:
                            <br />
                            * <b>Simular:</b> baixa uma planilha com os
                            valores do bônus referente ao mês e ano
                            selecionados com dados consultados em tempo real.
                            <br />
                            * <b>Calcular:</b> armazena os valores do
                            bônus referente ao mês e ano selecionados impedindo
                            que esses valores sejam alterados por qualquer
                            fonte.
                            <br />
                            * <b>Download:</b> baixa uma planilha com
                            os valores de bônus já armazenados
                            referentes ao mês e ano selecionados.
                        </>
                    }
                />
            </ComponentHeader.Root>
            <Box>
                <DatePicker
                    label="Mês referente"
                    views={["month", "year"]}
                    disableFuture
                    format="MM/YYYY"
                    value={monthYear}
                    onChange={(date) => {
                        if (date) {
                            setMonth(date.month() + 1);
                            setYear(date.year());
                        } else {
                            setMonth(undefined);
                            setYear(undefined);
                        }
                        setMonthYear(date);
                    }}
                />
                <Button
                    loading={loading}
                    disabled={!monthYear}
                    onClick={() => exportResults("simulate")}
                >
                    <Typography variant="body2">Simular</Typography>
                </Button>
                <PermissionGate
                    neededpermissions={["sales-bonus | calculate"]}
                    userPermissions={permissions}
                >
                    <Button
                        loading={loading}
                        disabled={!monthYear}
                        onClick={() => {
                            setCalculate(true);
                        }}
                    >
                        <Typography variant="body2">Calcular</Typography>
                    </Button>
                </PermissionGate>
                <Button
                    loading={loading}
                    disabled={!monthYear}
                    onClick={() => exportResults("download")}
                >
                    <Typography variant="body2">Download</Typography>
                </Button>
            </Box>
        </Box>
    );
};

export default Actions;
