import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import useSalesAreas from "../../../hooks/SalesBonusApi/useSalesAreas";
import useSalesLevels from "../../../hooks/SalesBonusApi/useSalesLevels";

import ServiceTitle from "../../../components/ServiceTitle";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";

import { Autocomplete, Grid, Typography } from "@mui/material";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import CloseIcon from "@mui/icons-material/Close";
import SpeakerNotesOffOutlinedIcon from "@mui/icons-material/SpeakerNotesOffOutlined";

import sellersApiService from "../../../services/SellersApi/sellersApiService";

import { IFrexcoSeller } from "../../../interfaces/SellersApi/IFrexcoSeller";

import { usePopup } from "../../../hooks/usePopup";
import { useStyles } from "./styles";

import TextField from "../../../components/TextField";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, FieldValues } from "react-hook-form";
import {
    SdrBasedErrors,
    SdrBasedResponses,
} from "../../../utils/Api/responses/responses";

import { ISalesAreas } from "../../../interfaces/SalesBonus/ISalesAreas";
import BreadCrumbs from "../../../components/BreadCrumbs";
import PageContainer from "../../../components/PageContainer";
import ComponentHeader from "../../../components/ComponentHeader";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import useTeams from "../../../hooks/SellersApi/useTeams";
import { AlertDialog } from "../../../components/AlertDialog";

const frexcoSellerSchema = yup.object().shape({
    name: yup.string().required(),
    registration_id: yup.string().nullable(),
    email: yup.string().required(),
    area: yup.string(),
    status: yup.string(),
    squads: yup.string().required(),
    entry_date: yup.date().nullable(),
    exit_date: yup.date().nullable(),
    utalkName: yup.string(),
    utalkPermissions: yup.array().of(yup.string().required()),
    utalkSquads: yup.string(),
    rdstationName: yup.string(),
    rdstationSquads: yup.string(),
});

const SingleSeller = () => {
    const { addPopup } = usePopup();
    const classes = useStyles();
    const sellerId = useParams().id;

    const [seller, setSeller] = useState<IFrexcoSeller>();

    const [editSeller, setEditSeller] = useState(false);
    const [searchingSellerInfo, setSearchingSellerInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [area, setArea] = useState<ISalesAreas>();
    const [squad, setSquad] = useState("");
    const [openDeleteSellerConfirmDialog, setOpenDeleteSellerConfirmDialog] =
        useState(false);
    const [openCreateChannelConfirmDialog, setOpenCreateChannelConfirmDialog] =
        useState(false);
    const [openDeleteChannelConfirmDialog, setOpenDeleteChannelConfirmDialog] =
        useState(false);
    const [openSquadChangedAlertDialog, setOpenSquadChangedAlertDialog] =
        useState(false);

    const utalkPermissions = ["Member", "Operator", "Admin"];

    const { data: teamsResponse } = useTeams({ active: true });
    const sectors = teamsResponse
        ? teamsResponse.results.map((team) => team.name)
        : [];

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        control,
    } = useForm({
        resolver: yupResolver(frexcoSellerSchema),
    });

    const { data: salesAreasResponse } = useSalesAreas();

    useEffect(() => {
        if (!!teamsResponse?.results.length) {
            let sellerSquad = teamsResponse.results.find(
                (team) => team.name === squad
            );
            if (!!salesAreasResponse?.results.length && sellerSquad) {
                let sellerArea = salesAreasResponse.results.find(
                    (area) => area.name === sellerSquad?.area
                );
                if (sellerArea) {
                    setArea(sellerArea);
                }
            }
        }
    }, [
        salesAreasResponse,
        seller?.area,
        teamsResponse,
        seller?.squads,
        squad,
    ]);

    const {
        data: salesLevelsResponse,
        isFetching: gettingSalesLevelsResponse,
    } = useSalesLevels({ area__in: `["${area?.id!}"]` }, { enabled: !!area });

    const setSellerSquad = (updatedSeller: IFrexcoSeller) => {
        if (!updatedSeller.utalk.squads?.length) {
            updatedSeller.utalk.squads[0] = updatedSeller.squads[0];
        }
        if (
            updatedSeller.utalk.squads.length &&
            typeof updatedSeller.utalk.squads[0] != "string"
        ) {
            updatedSeller.utalk.squads[0] = updatedSeller.utalk.squads[0].name;
        }

        return updatedSeller;
    };

    const sendSeller = async (seller: IFrexcoSeller) => {
        setLoading(true);
        await sellersApiService
            .updateSeller(seller)
            .then(() => {
                addPopup({
                    title: "Hunter alterado com sucesso",
                    description:
                        "As mudanças podem demorar alguns minutos para serem refletidas no RDStation e Utalk.",
                    type: "success",
                    duration: "temporary",
                });
            })
            .catch((err: AxiosError<SdrBasedErrors>) => {
                addPopup({
                    title: "Falha ao alterar Hunter.",
                    description: err.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteSeller = async () => {
        setLoading(true);
        if (seller !== undefined) {
            let updatedSeller = { ...seller };
            updatedSeller.active = false;
            if (updatedSeller.utalk) {
                updatedSeller.utalk.active = false;
                updatedSeller = setSellerSquad(updatedSeller);
            }
            await sellersApiService
                .deleteSeller(updatedSeller)
                .then(() => {
                    addPopup({
                        title: "Hunter desativado com sucesso",
                        description:
                            "O pedido foi concluído, o processo de desativação no RDStation pode demorar alguns minutos. ",
                        type: "success",
                        duration: "temporary",
                    });
                    setSeller(updatedSeller);
                })
                .catch((err: AxiosError<SdrBasedErrors>) => {
                    addPopup({
                        title: "Falha ao deletar Hunter.",
                        description: err.response?.data.error,
                        type: "error",
                        duration: "temporary",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const createUtalkChannel = async () => {
        setLoading(true);
        addPopup({
            title: "Criando canal...",
            description:
                "O canal está sendo criado, por favor aguarde 10 segundos.",
            duration: "permanent",
            type: "info",
        });
        if (seller !== undefined) {
            await sellersApiService
                .createChannel({
                    name: seller?.name!,
                    seller_email: seller?.email!,
                })
                .then(async (resp: AxiosResponse<SdrBasedResponses>) => {
                    addPopup({
                        title: "Canal criado com sucesso!",
                        type: "success",
                        duration: "temporary",
                    });
                    let updatedSeller = { ...seller };
                    updatedSeller = setSellerSquad(updatedSeller);
                    setSeller(updatedSeller);
                    await sendSeller(updatedSeller);
                })
                .catch((err: AxiosError<SdrBasedErrors>) => {
                    addPopup({
                        title: "Falha ao criar canal",
                        description: err.response?.data.error,
                        type: "error",
                        duration: "temporary",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const deleteUtalkChannel = async () => {
        setLoading(true);
        await sellersApiService
            .deleteChannel(seller?.utalk.channel!)
            .then(async () => {
                addPopup({
                    title: "Canal deletado com sucesso!",
                    type: "success",
                    duration: "temporary",
                });
                if (seller !== undefined) {
                    let updatedSeller = { ...seller };
                    updatedSeller = setSellerSquad(updatedSeller);
                    setSeller(updatedSeller);
                    await sendSeller(updatedSeller);
                }
            })
            .catch((err: AxiosError<SdrBasedErrors>) => {
                addPopup({
                    title: "Erro ao deletar canal.",
                    description: err.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleEditButton = () => {
        if (seller?.utalk?.external_id && seller?.rdstation.external_id) {
            setEditSeller(true);
        } else {
            addPopup({
                title: "Não é possível editar este hunter",
                description:
                    "Por favor, aguarde o registro do hunter na plataforma do rdstation e utalk.",
                duration: "temporary",
                type: "info",
            });
        }
    };

    const handleChangeSquads = (
        e: React.SyntheticEvent<Element, Event>,
        value: string
    ) => {
        setValue("rdstationSquads", value);
        setValue("utalkSquads", value);
        setValue("squads", value);
        setSquad(value);
    };

    useEffect(() => {
        const getSellerInfo = async () => {
            setSearchingSellerInfo(true);
            await sellersApiService
                .getFrexcoSellers({ id__in: `[${sellerId}]` })
                .then((resp: AxiosResponse<SdrBasedResponses>) => {
                    const results = resp.data.results;
                    if (!!results.length) {
                        const seller: IFrexcoSeller = results[0];
                        setValue("name", seller.name);
                        setValue("email", seller.email);
                        setValue("registration_id", seller.registration_id);
                        setValue(
                            "entry_date",
                            seller.entry_date
                                ? dayjs(seller.entry_date)
                                : undefined
                        );
                        setValue(
                            "exit_date",
                            seller.exit_date
                                ? dayjs(seller.exit_date)
                                : undefined
                        );
                        if (!!seller?.squads) {
                            setValue("squads", seller?.squads[0]);
                            setSquad(seller?.squads[0]);
                        }
                        if (seller?.utalk?.permissions) {
                            setValue(
                                "utalkPermissions",
                                seller?.utalk?.permissions
                            );
                        }
                        if (
                            seller?.utalk?.squads &&
                            seller?.utalk?.squads?.length !== 0
                        ) {
                            const sector = seller?.utalk?.squads[0];
                            if (typeof sector === "object") {
                                setValue("utalkSquads", sector.name);
                            }
                        }

                        setValue("rdstationName", seller?.rdstation?.name);
                        setValue("rdstationEmail", seller?.rdstation?.email);
                        if (
                            seller?.rdstation?.squads &&
                            seller?.rdstation?.squads?.length !== 0
                        ) {
                            const squad = seller?.rdstation?.squads[0];
                            if (typeof squad === "object") {
                                setValue("rdstationSquads", squad.name);
                            }
                        }

                        setSeller(results[0]);
                    }
                })
                .catch((err: AxiosError<SdrBasedErrors>) => {
                    addPopup({
                        title: "Falha ao carregar hunter.",
                        description: err.response?.data.error,
                        type: "error",
                        duration: "temporary",
                    });
                })
                .finally(() => {
                    setSearchingSellerInfo(false);
                });
        };
        getSellerInfo();
    }, [addPopup, sellerId, setValue]);

    const onSubmit = async (data: FieldValues) => {
        if (data.entry_date) {
            const entryDate = data.entry_date as Date;
            const entryYear = entryDate.getFullYear();
            const entryMonth = String(entryDate.getMonth() + 1).padStart(
                2,
                "0"
            );
            const entryDay = String(entryDate.getDate()).padStart(2, "0");
            data.entry_date = `${entryYear}-${entryMonth}-${entryDay}`;
        } else {
            data.entry_date = null;
        }

        if (data.exit_date) {
            const exitDate = data.exit_date as Date;
            let exitYear = exitDate.getFullYear();
            let exitMonth = String(exitDate.getMonth() + 1).padStart(2, "0");
            let exitDay = String(exitDate.getDate()).padStart(2, "0");
            data.exit_date = `${exitYear}-${exitMonth}-${exitDay}`;
        } else {
            data.exit_date = null;
        }

        if (data.squads !== seller?.squads[0]) {
            setOpenSquadChangedAlertDialog(true);
        }

        var modifiedSeller: IFrexcoSeller = {
            id: seller!.id,
            name: data.name,
            email: seller!.email,
            squads: [data.squads],
            active: seller!.active!,
            is_regional_leader: seller!.is_regional_leader,
            is_team_leader: seller!.is_team_leader,
            area: area ? area.name : null,
            registration_id: data.registration_id ? data.registration_id : null,
            status: data.status ? data.status : null,
            entry_date: data.entry_date,
            exit_date: data.exit_date,
            inbound_punishment: seller!.inbound_punishment,
            utalk: {
                external_id: seller!.utalk?.external_id,
                permissions: data.utalkPermissions,
                squads: [data.utalkSquads],
                channel: seller!.utalk?.channel,
                active: seller!.utalk?.active,
            },
            rdstation: {
                name: seller!.rdstation?.name,
                external_id: seller!.rdstation?.external_id,
                squads: [data.rdstationSquads],
                hidden: seller!.rdstation?.hidden,
                active: seller!.rdstation?.active,
            },
        };
        sendSeller(modifiedSeller);
    };

    return (
        <>
            <BreadCrumbs.Root>
                <BreadCrumbs.Link
                    text="Time Comercial"
                    to="/management/comercial-team"
                />
                <BreadCrumbs.Current
                    text={seller?.name ? seller?.name : "Carregando..."}
                />
            </BreadCrumbs.Root>
            {searchingSellerInfo ? (
                <ServiceTitle title="Carregando Informações..." />
            ) : seller ? (
                <>
                    <ComponentHeader.Root>
                        <ComponentHeader.Title text={seller.name} />
                        <ComponentHeader.Actions>
                            {!editSeller ? (
                                <ComponentHeader.Action
                                    tooltip="Editar Vendedor"
                                    icon={
                                        <EditOutlinedIcon
                                            color="primary"
                                            fontSize="large"
                                        />
                                    }
                                    action={() => handleEditButton()}
                                />
                            ) : (
                                <>
                                    {seller.utalk?.channel ? (
                                        <ComponentHeader.Action
                                            tooltip="Deletar Canal do Utalk"
                                            icon={
                                                <SpeakerNotesOffOutlinedIcon
                                                    fontSize="large"
                                                    color="error"
                                                />
                                            }
                                            action={() =>
                                                setOpenDeleteChannelConfirmDialog(
                                                    true
                                                )
                                            }
                                        />
                                    ) : (
                                        <ComponentHeader.Action
                                            tooltip="Criar Canal do Utalk"
                                            icon={
                                                <ChatOutlinedIcon
                                                    fontSize="large"
                                                    color="primary"
                                                />
                                            }
                                            action={() =>
                                                setOpenCreateChannelConfirmDialog(
                                                    true
                                                )
                                            }
                                        />
                                    )}
                                    <ComponentHeader.Action
                                        tooltip="Deletar Vendedor"
                                        icon={
                                            <NoAccountsIcon
                                                color="error"
                                                fontSize="large"
                                            />
                                        }
                                        action={() => {
                                            setOpenDeleteSellerConfirmDialog(
                                                true
                                            );
                                        }}
                                    />
                                    <ComponentHeader.Action
                                        tooltip="Confirmar Edição"
                                        icon={
                                            <SendOutlinedIcon
                                                color="primary"
                                                fontSize="large"
                                            />
                                        }
                                        action={handleSubmit(onSubmit)}
                                    />
                                    <ComponentHeader.Action
                                        tooltip="Cancelar"
                                        icon={
                                            <CloseIcon
                                                color="action"
                                                fontSize="large"
                                            />
                                        }
                                        action={() => setEditSeller(false)}
                                    />
                                </>
                            )}
                        </ComponentHeader.Actions>
                    </ComponentHeader.Root>
                    <PageContainer>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Typography
                                    sx={{ marginBottom: "20px" }}
                                    color={
                                        seller.active
                                            ? "primary.main"
                                            : "error.main"
                                    }
                                    variant="h4"
                                >
                                    {seller.active ? "Ativo" : "Inativo"}
                                </Typography>
                            </Grid>
                            <Grid container item spacing={1}>
                                <Grid item sm={12}>
                                    <Typography variant="h3">
                                        Dados Internos:
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Nome"
                                        defaultValue={seller.name}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("name")}
                                        error={!!errors?.name}
                                        disabled={!editSeller}
                                        helperText={
                                            errors?.name?.message ? (
                                                <>{errors?.name?.message}</>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Matrícula"
                                        defaultValue={seller.registration_id}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("registration_id")}
                                        error={!!errors?.registration_id}
                                        helperText={
                                            errors?.registration_id?.message ? (
                                                <>
                                                    {
                                                        errors?.registration_id
                                                            ?.message
                                                    }
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        disabled={!editSeller}
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Email"
                                        defaultValue={seller.email}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("email")}
                                        error={!!errors?.email}
                                        helperText={
                                            errors?.email?.message ? (
                                                <>{errors?.email?.message}</>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        disabled
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <Controller
                                        name="squads"
                                        control={control}
                                        defaultValue=""
                                        render={({
                                            field: { ref, ...field },
                                        }) => (
                                            <Autocomplete
                                                {...field}
                                                className={classes.textInput}
                                                options={sectors}
                                                getOptionLabel={(option) =>
                                                    option
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="squads"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        label="Selecione o polo*"
                                                        placeholder="Polo"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                onChange={handleChangeSquads}
                                                disabled={!editSeller}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Area"
                                        value={area?.name}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("area")}
                                        error={!!errors?.area}
                                        helperText={
                                            errors?.area?.message ? (
                                                <>{errors?.area?.message}</>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        disabled
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <Controller
                                        name="status"
                                        control={control}
                                        defaultValue={seller.status}
                                        render={({
                                            field: { ref, ...field },
                                        }) => (
                                            <Autocomplete
                                                {...field}
                                                className={classes.textInput}
                                                loading={
                                                    gettingSalesLevelsResponse
                                                }
                                                options={
                                                    salesLevelsResponse?.results
                                                        ? salesLevelsResponse?.results
                                                        : []
                                                }
                                                getOptionLabel={(option) =>
                                                    option.name || option
                                                }
                                                onChange={(e, value) => {
                                                    setValue(
                                                        "status",
                                                        value.name
                                                    );
                                                }}
                                                isOptionEqualToValue={(
                                                    value,
                                                    option
                                                ) => value.name === option.name}
                                                renderInput={(params) => (
                                                    <TextField
                                                        inputRef={ref}
                                                        {...params}
                                                        variant="outlined"
                                                        label="Selecione o Nível*"
                                                        placeholder="Nível"
                                                        {...register("status")}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                disabled={!editSeller}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <Controller
                                        control={control}
                                        name="entry_date"
                                        render={({ field }) => {
                                            return (
                                                <DatePicker
                                                    sx={{
                                                        width: "100%",
                                                        margin: "8px",
                                                    }}
                                                    disabled={!editSeller}
                                                    label="Data de Entrada"
                                                    value={field.value}
                                                    disableFuture
                                                    format="DD/MM/YYYY"
                                                    inputRef={field.ref}
                                                    onChange={(entry_date) => {
                                                        field.onChange(
                                                            entry_date
                                                        );
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <Controller
                                        control={control}
                                        name="exit_date"
                                        render={({ field }) => {
                                            return (
                                                <DatePicker
                                                    sx={{
                                                        width: "100%",
                                                        margin: "8px",
                                                    }}
                                                    disabled={!editSeller}
                                                    label="Data de Saída"
                                                    value={field.value}
                                                    disableFuture
                                                    format="DD/MM/YYYY"
                                                    inputRef={field.ref}
                                                    onChange={(exit_date) => {
                                                        field.onChange(
                                                            exit_date
                                                        );
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1}>
                                <Grid item sm={12}>
                                    <Typography variant="h3">
                                        Dados no Utalk:
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Nome"
                                        defaultValue={seller.utalk.name}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("utalkName")}
                                        error={!!errors?.utalkName}
                                        helperText={
                                            errors?.utalkName?.message ? (
                                                <>
                                                    {errors?.utalkName?.message}
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        disabled
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Email"
                                        defaultValue={seller.utalk.email}
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <Controller
                                        name="utalkSquads"
                                        control={control}
                                        defaultValue=""
                                        render={({
                                            field: { ref, ...field },
                                        }) => (
                                            <Autocomplete
                                                {...field}
                                                className={classes.textInput}
                                                options={sectors}
                                                getOptionLabel={(option) =>
                                                    option
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="utalkSquads"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        label="Selecione o polo*"
                                                        placeholder="Polo"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                onChange={handleChangeSquads}
                                                disabled={!editSeller}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <Controller
                                        name="utalkPermissions"
                                        control={control}
                                        defaultValue={[]}
                                        render={({
                                            field: { ref, ...field },
                                        }) => (
                                            <Autocomplete
                                                {...field}
                                                className={classes.textInput}
                                                options={utalkPermissions}
                                                getOptionLabel={(option) =>
                                                    option
                                                }
                                                multiple
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="utalkPermissions"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        label="Selecione as permissões*"
                                                        placeholder="Permissões"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                onChange={(e, value) => {
                                                    setValue(
                                                        "utalkPermissions",
                                                        value
                                                    );
                                                }}
                                                disabled={!editSeller}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1}>
                                <Grid item sm={12}>
                                    <Typography variant="h3">
                                        Dados no RDStation:
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Nome"
                                        InputLabelProps={{ shrink: true }}
                                        defaultValue={seller.rdstation.name}
                                        {...register("rdstationName")}
                                        error={!!errors?.rdstationName}
                                        helperText={
                                            errors?.rdstationName?.message ? (
                                                <>
                                                    {
                                                        errors?.rdstationName
                                                            ?.message
                                                    }
                                                </>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        disabled
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Email"
                                        defaultValue={seller.utalk.email}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("rdstationEmail")}
                                        disabled
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <Controller
                                        name="rdstationSquads"
                                        control={control}
                                        defaultValue=""
                                        render={({
                                            field: { ref, ...field },
                                        }) => (
                                            <Autocomplete
                                                {...field}
                                                className={classes.textInput}
                                                options={sectors}
                                                getOptionLabel={(option) =>
                                                    option
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="rdstationSquads"
                                                        inputRef={ref}
                                                        variant="outlined"
                                                        label="Selecione o polo*"
                                                        placeholder="Polo"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                onChange={handleChangeSquads}
                                                disabled={!editSeller}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </PageContainer>
                </>
            ) : (
                <ServiceTitle title="Hunter não encontrado" />
            )}
            {openDeleteSellerConfirmDialog && (
                <ConfirmationDialog
                    title="Deletar Hunter"
                    message="Tem certeza que deseja deletar o hunter?"
                    openDialog={openDeleteSellerConfirmDialog}
                    setOpenDialog={setOpenDeleteSellerConfirmDialog}
                    callback={deleteSeller}
                />
            )}
            {openCreateChannelConfirmDialog && (
                <ConfirmationDialog
                    title="Criar canal no utalk"
                    message="Tem certeza que deseja criar um novo canal no utalk pro hunter?"
                    openDialog={openCreateChannelConfirmDialog}
                    setOpenDialog={setOpenCreateChannelConfirmDialog}
                    callback={createUtalkChannel}
                />
            )}
            {openDeleteChannelConfirmDialog && (
                <ConfirmationDialog
                    title="Deletar canal no utalk"
                    message="Tem certeza que deseja deletar o canal do hunter no utalk?"
                    openDialog={openDeleteChannelConfirmDialog}
                    setOpenDialog={setOpenDeleteChannelConfirmDialog}
                    callback={deleteUtalkChannel}
                />
            )}
            {openSquadChangedAlertDialog && (
                <AlertDialog
                    openDialog={openSquadChangedAlertDialog}
                    setOpenDialog={setOpenSquadChangedAlertDialog}
                    title="Equipe alterada"
                    message="Para que seja concretizada a mudança de time do usuário é preciso que você mude o time dele também no RDStation. Caso não seja feito em cerca de 1 hora o time do usuário voltará a ser o anterior em nosso sistema."
                />
            )}
        </>
    );
};

export default SingleSeller;
