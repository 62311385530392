import { useEffect, useState } from "react";

import Button from "../../../../../components/Button";
import TextField from "../../../../../components/TextField";
import DataGrid from "../../../../../components/DataGrid";

import { useForm } from "react-hook-form";
import useCreateGroup from "../../../../../hooks/AcessApi/useCreateGroup";
import useGroupUsers from "../../../../../hooks/AcessApi/useGroupUsers";
import { useStyles } from "./styles";
import { usePopup } from "../../../../../hooks/usePopup";
import useGroupPerms from "../../../../../hooks/AcessApi/useGroupPerms";
import useAddUsersToGroup from "../../../../../hooks/AcessApi/useAddUsersToGroup";
import useAddPermsToGroup from "../../../../../hooks/AcessApi/useAddPermsToGroup";

import { IUser } from "../../../../../interfaces/IUser";
import { IPermissions } from "../../../../../interfaces/IPermissions";

import { Box, Typography } from "@mui/material";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";

interface ICreateGroupForm {
    name: string;
}

const CreateGroupForm = () => {
    const classes = useStyles();
    const { addPopup } = usePopup();

    const { mutateAsync: createGroup, isLoading: creatingGroup } =
        useCreateGroup();
    const { mutate: addUsersToGroup, isLoading: addingUsersToGroup } =
        useAddUsersToGroup();
    const { mutate: addPermsToGroup, isLoading: addingPermsToGroup } =
        useAddPermsToGroup();

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<ICreateGroupForm>();

    const {
        data: groselhaUsers,
        isFetching: gettingGroselhaUsers,
        error: gettingGroselhaUsersError,
    } = useGroupUsers("Groselha");

    useEffect(() => {
        if (gettingGroselhaUsersError) {
            addPopup({
                title: "Falha ao buscar usuários",
                type: "error",
                duration: "temporary",
            });
        }
    }, [gettingGroselhaUsersError, addPopup]);

    const { data: groselhaPerms } = useGroupPerms("Groselha Permissions");

    const UsersGridRef = useGridApiRef();
    const PermissionsGridRef = useGridApiRef();

    const [usersGridColumnDefs] = useState<GridColDef<IUser>[]>([
        {
            field: "username",
            headerName: "Nome",
            flex: 1,
        },
    ]);

    const [permissionsGridColumnDefs] = useState<GridColDef<IPermissions>[]>([
        {
            field: "name",
            headerName: "Nome",
            flex: 1,
        },
    ]);

    const handleCreateGroup = (formData: ICreateGroupForm) => {
        const groupName = formData.name;
        createGroup(groupName)
            .then(() => {
                const selectedPerms =
                    PermissionsGridRef.current.getSelectedRows();
                const selectedPermsArray = Array.from(
                    selectedPerms.values()
                ) as IPermissions[];
                const permissions = selectedPermsArray
                    ?.map((perm) => perm.name)
                    .filter(
                        (permission) => typeof permission === "string"
                    ) as string[];
                if (!!permissions.length) {
                    addPermsToGroup({
                        group: groupName,
                        permissions: permissions,
                    });
                }

                const selectedUsers = UsersGridRef.current.getSelectedRows();
                const selectedUsersArray = Array.from(
                    selectedUsers.values()
                ) as IUser[];
                const usernames = selectedUsersArray
                    ?.map((user) => user.username)
                    .filter((user) => typeof user === "string") as string[];
                if (!!usernames.length) {
                    addUsersToGroup({ group: groupName, usernames: usernames });
                }
            })
            .catch();
    };

    const isLoading =
        creatingGroup ||
        addingUsersToGroup ||
        addingPermsToGroup ||
        gettingGroselhaUsers;

    return (
        <form
            className={classes.form}
            onSubmit={handleSubmit(handleCreateGroup)}
        >
            <Typography variant="subtitle2">Criar Grupo</Typography>
            <Box className={classes.contentBox}>
                <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    id="name"
                    label="Nome do Grupo"
                    type="text"
                    variant="outlined"
                    error={!!errors?.name}
                    helperText={
                        errors?.name?.message ? (
                            <>{errors?.name?.message}</>
                        ) : (
                            <></>
                        )
                    }
                    {...register("name", { required: true })}
                />
                <Box width={"100%"} height={"300px"} marginBottom={"30px"}>
                    <Typography variant="h4">Usuários participantes</Typography>
                    <DataGrid<IUser>
                        apiRef={UsersGridRef}
                        columns={usersGridColumnDefs}
                        rows={groselhaUsers ? groselhaUsers : []}
                        isRowSelectable={() => true}
                        checkboxSelection
                        loading={isLoading}
                    />
                </Box>
                <Box width={"100%"} height={"300px"} marginBottom={"30px"}>
                    <Typography variant="h4">Permissões do grupo</Typography>
                    <DataGrid<IPermissions>
                        apiRef={UsersGridRef}
                        columns={permissionsGridColumnDefs}
                        rows={groselhaPerms ? groselhaPerms : []}
                        isRowSelectable={() => true}
                        checkboxSelection
                        loading={isLoading}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "10px",
                }}
            >
                <Button
                    loading={
                        creatingGroup ||
                        addingPermsToGroup ||
                        addingUsersToGroup
                    }
                    type="submit"
                >
                    <Typography variant="body2">Criar</Typography>
                </Button>
            </Box>
        </form>
    );
};

export default CreateGroupForm;
