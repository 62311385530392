import { IFarmerWallet } from "../../interfaces/SalesBonus/IWallets";
import { IFarmerWalletsQueryOptions } from "../../interfaces/SalesBonus/QueriesOptions/IWalletsQueryOptions";
import { api } from "../../utils/Api/apiClient";
import GenericApi from "../genericApi";

class FarmerWalletApi extends GenericApi<
    IFarmerWallet,
    IFarmerWalletsQueryOptions,
    IFarmerWallet
> {
    constructor() {
        super("/api/sales-bonus-api/farmer-wallet/");
    }

    put = ({
        id,
        object,
    }: {
        id?: number;
        object: Partial<IFarmerWallet> | IFarmerWallet[];
    }) =>
        api
            .put(`/api/sales-bonus-api/farmer-wallet/${id ? id + "/" : ""}`, {
                data: object,
            })
            .then((res) => res.data);
}

export default FarmerWalletApi;
