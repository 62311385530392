import { IconButton, Tooltip } from "@mui/material";
import { ReactNode } from "react";

interface ICardAction {
    tooltip: string;
    icon: ReactNode;
    action: () => void;
}

const CardAction = ({ tooltip, icon, action }: ICardAction) => {
    return (
        <Tooltip title={tooltip}>
            <IconButton onClick={action}>{icon}</IconButton>
        </Tooltip>
    );
};

export default CardAction;
