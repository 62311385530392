import { useState } from "react";

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";

import NoAccountsIcon from "@mui/icons-material/NoAccounts";

import theme from "../../../../../styles/theme";

import { IActions } from "../../../../../interfaces/IActions";
import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";

import useAlterComercialTeamMember from "../../../../../hooks/SellersApi/useAlterComercialMember";

import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";

interface IIrregularityDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    memberState: IFrexcoSeller;
    setMemberState: React.Dispatch<React.SetStateAction<IFrexcoSeller>>;
    utalkUserShouldNotExist?: boolean;
    shouldHaveChannel?: boolean;
}

const IrregularityDialog = ({
    openDialog,
    setOpenDialog,
    memberState,
    setMemberState,
    utalkUserShouldNotExist,
    shouldHaveChannel,
}: IIrregularityDialog) => {
    const [openDeleteSellerConfirmDialog, setOpenDeleteSellerConfirmDialog] =
        useState(false);

    let text = "";
    text = utalkUserShouldNotExist
        ? text +
          "O usuário tem data de saída mas ainda possui atendente no utalk.\n"
        : text;

    text = shouldHaveChannel
        ? text + "Não temos registro de canal no utalk para este usuário.\n"
        : text;

    const actions: IActions[] = [];

    if (utalkUserShouldNotExist) {
        actions.push({
            id: 1,
            icon: <NoAccountsIcon color="error" fontSize="large" />,
            onClick: () => setOpenDeleteSellerConfirmDialog(true),
            tooltip: "Deletar Vendedor.",
        });
    }

    const { mutateAsync: alterMember } = useAlterComercialTeamMember();

    const deleteSeller = () => {
        let updatedMember = { ...memberState };
        updatedMember.active = false;
        if (updatedMember.utalk) {
            updatedMember.utalk.active = false;
        }
        alterMember(updatedMember).then(() => setMemberState(updatedMember));
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle sx={{ backgroundColor: "primary.main" }}>
                    <Typography
                        textAlign={"center"}
                        variant={"h3"}
                        color={"#FAFAFA"}
                    >
                        Irregularidades
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box marginTop={"10px"}>
                        <Typography variant="body1">{text}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        borderTop: `.5px solid ${theme.palette.secondary.light}`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {actions.map((action) => (
                        <Tooltip title={action.tooltip} key={action.id}>
                            <IconButton onClick={action.onClick}>
                                {action.icon}
                            </IconButton>
                        </Tooltip>
                    ))}
                </DialogActions>
            </Dialog>
            {openDeleteSellerConfirmDialog && (
                <ConfirmationDialog
                    title="Deletar Hunter"
                    message="Tem certeza que deseja deletar o hunter?"
                    openDialog={openDeleteSellerConfirmDialog}
                    setOpenDialog={setOpenDeleteSellerConfirmDialog}
                    callback={deleteSeller}
                />
            )}
        </>
    );
};

export default IrregularityDialog;
