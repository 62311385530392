import { useQuery } from "@tanstack/react-query";
import {
    SdrBasedErrors,
} from "../../utils/Api/responses/responses";
import { AxiosError } from "axios";
import DistributionApi from "../../services/SdrApi/distributionService";
import { IDistributionSummary } from "../../interfaces/SdrApi/IDistributionSummary";

const distributionService = new DistributionApi();

const useDistributionSummary = (query?: Object, definitions?: Object) =>
    useQuery<IDistributionSummary[], AxiosError<SdrBasedErrors>>({
        ...definitions,
        queryKey: ["distribution-summary", query],
        queryFn: () => distributionService.getSummary(query),
        staleTime: 60 * 60 * 1000 ,
        keepPreviousData: true,
    });
export default useDistributionSummary;
