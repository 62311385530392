import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Box, Tooltip, Typography } from "@mui/material";

import { usePopup } from "../../../../../hooks/usePopup";
import { useAuth } from "../../../../../hooks/useAuth";
import useGroupPerms from "../../../../../hooks/AcessApi/useGroupPerms";
import useAddPermsToGroup from "../../../../../hooks/AcessApi/useAddPermsToGroup";
import useGroupRemovePerms from "../../../../../hooks/AcessApi/useGroupRemovePerms";

import { PermissionGate } from "../../../../../components/PermissionGate";
import DataGrid from "../../../../../components/DataGrid";

import { IPermissions } from "../../../../../interfaces/IPermissions";

import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";

import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";

interface IManageGroupDialog {
    group: string;
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ManageGroupDialog = ({
    group,
    openDialog,
    setOpenDialog,
}: IManageGroupDialog) => {
    const { addPopup } = usePopup();
    const { permissions } = useAuth();

    const adminPermission = ["groselha | admin"];

    const { mutate: addPermsToGroup, isLoading: addingPermsToGroup } =
        useAddPermsToGroup();

    const { mutate: removePermsFromGroup, isLoading: removingPermsFromGroup } =
        useGroupRemovePerms();

    const { data: groselhaPermissions, isFetching: gettingGroselhaPerms } =
        useGroupPerms("Groselha Permissions");

    const {
        data: groupPermissions,
        isFetching: gettingGroupPerms,
        error: gettingGroupPermissionsError,
    } = useGroupPerms(group);

    useEffect(() => {
        if (gettingGroupPermissionsError) {
            addPopup({
                title: `Erro ao buscar permissões do ${group}.`,
                description: gettingGroupPermissionsError.error,
                type: "error",
                duration: "temporary",
            });
        }
    }, [gettingGroupPermissionsError, addPopup, group]);

    const [groselhaPermissionsColumnDefs] = useState<
        GridColDef<IPermissions>[]
    >([
        {
            field: "name",
            headerName: "Nome",
            flex: 1,
        },
        {
            field: "actions",
            type: "actions",
            getActions: (params) => {
                return [
                    <Tooltip title={"Adicionar"}>
                        <GridActionsCellItem
                            icon={<EastOutlinedIcon color="primary" />}
                            label="Adicionar"
                            onClick={() => {
                                addPermsToGroup({
                                    group: group,
                                    permissions: [params.row.name],
                                });
                                console.log(params.id, params.row.name);
                            }}
                        />
                    </Tooltip>,
                ];
            },
            width: 70,
        },
    ]);
    const [groupPermissionsColumnDefs] = useState<GridColDef<IPermissions>[]>([
        {
            field: "name",
            headerName: "Nome",
            flex: 1,
        },
        {
            field: "actions",
            type: "actions",
            getActions: (params) => {
                return [
                    <Tooltip title={"Remover"}>
                        <GridActionsCellItem
                            icon={
                                <KeyboardBackspaceOutlinedIcon color="warning" />
                            }
                            label="Adicionar"
                            onClick={() => {
                                removePermsFromGroup({
                                    group: group,
                                    permissions: [params.row.name],
                                });
                                console.log(params.id, params.row.name);
                            }}
                        />
                    </Tooltip>,
                ];
            },
            width: 70,
        },
    ]);

    const handleClose = () => {
        setOpenDialog(false);
    };

    const isLoading =
        addingPermsToGroup ||
        removingPermsFromGroup ||
        gettingGroselhaPerms ||
        gettingGroupPerms;

    return (
        <Dialog
            sx={{
                "& .MuiPaper-root": {
                    maxWidth: "none",
                    padding: "20px 20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                },
            }}
            open={openDialog}
            onClose={handleClose}
        >
            <DialogActions
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box sx={{ display: "flex", gap: "20px", width: "100%" }}>
                    <PermissionGate
                        neededpermissions={adminPermission}
                        userPermissions={permissions}
                    >
                        <Box width={"300px"} height={"300px"}>
                            <Typography variant="body2">
                                Permissões do Groselha
                            </Typography>

                            <DataGrid<IPermissions>
                                columns={groselhaPermissionsColumnDefs}
                                rows={
                                    groselhaPermissions
                                        ? groselhaPermissions?.filter(
                                              (groselhaPermission) =>
                                                  !groupPermissions?.some(
                                                      (groupPermission) =>
                                                          groupPermission.name ===
                                                          groselhaPermission.name
                                                  )
                                          )
                                        : []
                                }
                                loading={isLoading}
                                hideFooter
                            />
                        </Box>
                    </PermissionGate>
                    <Box width={"300px"} height={"300px"}>
                        <Typography variant="body2">
                            Permissões do {group}
                        </Typography>
                        <DataGrid<IPermissions>
                            columns={groupPermissionsColumnDefs}
                            rows={groupPermissions ? groupPermissions : []}
                            loading={isLoading}
                            hideFooter
                        />
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
