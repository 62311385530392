import { useState } from "react";
import {
    IFarmerWalletsQueryOptions,
    IOnboardingWalletsQueryOptions,
} from "../../../../../interfaces/SalesBonus/QueriesOptions/IWalletsQueryOptions";
import {
    Autocomplete,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
} from "@mui/material";
import useFrexcoSellers from "../../../../../hooks/SellersApi/useFrexcoSellers";
import TextField from "../../../../../components/TextField";
import useTeams from "../../../../../hooks/SellersApi/useTeams";

function instanceOfOnboardingWalletsQueryOptions(
    object: any
): object is IOnboardingWalletsQueryOptions {
    return "onboarding__in" in object;
}

interface IFilterInputs {
    queryParams: IOnboardingWalletsQueryOptions | IFarmerWalletsQueryOptions;
    setQueryParams: React.Dispatch<
        React.SetStateAction<
            IOnboardingWalletsQueryOptions | IFarmerWalletsQueryOptions
        >
    >;
}

const FilterInputs = ({ queryParams, setQueryParams }: IFilterInputs) => {
    const [responsible, setResponsible] = useState("");
    const [CNPJ, setCNPJ] = useState("");

    const { data: teamsResponse } = useTeams({
        active: true,
        role__in: JSON.stringify(["Farm"]),
    });

    const {
        data: frexcoSellersResponse,
        isFetching: gettingFrexcoSellersResponse,
    } = useFrexcoSellers({
        squads__overlap: teamsResponse?.results
            ? JSON.stringify(teamsResponse?.results.map((team) => team.name))
            : undefined,
        active: true,
        order_by: "name",
        page: 1,
    });

    const handleCnpjChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCNPJ(event.target.value);
        const isAvalidInput = event.target.value.length === 14 || "";
        setQueryParams({
            ...queryParams,
            page: 1,
            cnpj__in: isAvalidInput
                ? JSON.stringify([event.target.value])
                : undefined,
        });
    };

    return (
        <Grid container spacing={2} alignItems={"start"}>
            <Grid item>
                <Autocomplete
                    sx={{ width: 300 }}
                    id="responsible"
                    loading={gettingFrexcoSellersResponse}
                    options={
                        frexcoSellersResponse?.results.map(
                            (seller) => seller.name
                        ) || []
                    }
                    value={responsible}
                    onChange={(e, newValue) => {
                        setResponsible(newValue || "");
                        instanceOfOnboardingWalletsQueryOptions(queryParams)
                            ? setQueryParams({
                                  ...queryParams,
                                  page: 1,
                                  onboarding__in: newValue
                                      ? JSON.stringify([newValue])
                                      : undefined,
                              })
                            : setQueryParams({
                                  ...queryParams,
                                  page: 1,
                                  farmer__in: newValue
                                      ? JSON.stringify([newValue])
                                      : undefined,
                              });
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Responsável" />
                    )}
                />
            </Grid>
            <Grid item>
                <TextField
                    value={CNPJ}
                    onChange={handleCnpjChange}
                    label="CNPJ"
                    helperText="Somente 14 números."
                />
            </Grid>
            <Grid item alignSelf={"center"}>
                <FormGroup sx={{ width: "fit-content" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked
                                onChange={(event) => {
                                    setQueryParams({
                                        ...queryParams,
                                        paying_off: event.target.checked,
                                        page: 1,
                                    });
                                }}
                            />
                        }
                        label="Contando para o mês corrente."
                    />
                </FormGroup>
            </Grid>
            <Grid item alignSelf={"center"}>
                <FormGroup sx={{ width: "fit-content" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked
                                onChange={(event) => {
                                    setQueryParams({
                                        ...queryParams,
                                        active: event.target.checked,
                                        page: 1,
                                    });
                                }}
                            />
                        }
                        label="Cliente ativo."
                    />
                </FormGroup>
            </Grid>
        </Grid>
    );
};

export default FilterInputs;
