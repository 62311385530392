import { api } from "../../utils/Api/apiClient";

import { ISeller } from "../../interfaces/SellersApi/ISeller";
import { IFrexcoSeller } from "../../interfaces/SellersApi/IFrexcoSeller";

const sellersApiService = {
    createChannel: async (seller: { name: string; seller_email: string }) => {
        const url = `api/sellers/utalk/channels/`;

        const data = {
            name: seller.name,
            seller_email: seller.seller_email,
        };

        return await api.post(url, data, {});
    },
    deleteChannel: async (external_id: string) => {
        const url = `api/sellers/utalk/channels/${external_id}/`;

        return await api.delete(url, {});
    },
    createSellers: async (seller: ISeller) => {
        const url = `api/sellers/sellers/`;

        return await api.post(url, seller, {});
    },
    updateSeller: async (seller: IFrexcoSeller) => {
        const url = `api/sellers/sellers/${seller.id}/`;

        return await api.put(url, seller, {});
    },
    deleteSeller: async (seller: IFrexcoSeller) => {
        const url = `api/sellers/sellers/${seller.id}/`;

        return await api.delete(url, {
            data: seller,
        });
    },
    getFrexcoSellers: async (params?: Object) => {
        let url = `api/sellers/frexco/`;

        return await api.get(url, {
            params: params,
        });
    },
    getChannelQrCode: async (params?: Object) => {
        let url = `api/sellers/utalk/channels/qrcode/`;

        return await api.get(url, {
            params: params,
        });
    },
};

export default sellersApiService;
