import { Typography } from "@mui/material";

interface IBreadCrumbsCurrent {
    text: string;
}

const BreadCrumbCurrent = ({ text }: IBreadCrumbsCurrent) => {
    return (
        <Typography variant="h4" color={"text.primary"}>
            {text}
        </Typography>
    );
};

export default BreadCrumbCurrent;
