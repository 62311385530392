import { useState } from "react";

import {
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";

import Button from "../Button";
import TextField from "../TextField";

import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useStyles } from "./styles";

import sellersApiService from "../../services/SellersApi/sellersApiService";

import { usePopup } from "../../hooks/usePopup";
import { AxiosError, AxiosResponse } from "axios";
import {
    CreateHunterResponse,
    SdrBasedErrors,
} from "../../utils/Api/responses/responses";
import useTeams from "../../hooks/SellersApi/useTeams";

const registerSellerFormSchema = yup.object().shape({
    name: yup.string().required(),
    surname: yup.string().required(),
    email: yup
        .string()
        .email()
        .required()
        .matches(
            /^[a-z0-9._-]+@frexco\.com\.br$/,
            "Deve ser um email Frexco. Sem letras maiúsculas."
        ),
    squads: yup.array().of(yup.string().required()),
});

interface ISellerRegisterDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const SellerRegisterDialog = ({
    openDialog,
    setOpenDialog,
}: ISellerRegisterDialog) => {
    const classes = useStyles();
    const { addPopup } = usePopup();

    const [registeringSeller, setRegisteringSeller] = useState(false);

    const { data: teamsResponse } = useTeams({ active: true });
    const sectors = teamsResponse?.results
        ? teamsResponse?.results.map(({ name }) => name)
        : [];

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(registerSellerFormSchema),
    });

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleFormSubmit = async (seller: FieldValues) => {
        setRegisteringSeller(true);

        const data = {
            name: ((seller.name as string) + " " + seller.surname) as string,
            email: seller.email,
            squads: seller.squads,
            entry_date: null,
            exit_date: null,
            area: null,
            status: null,
            registration_id: null,
            active: true,
        };

        await sellersApiService
            .createSellers(data)
            .then((resp: AxiosResponse<CreateHunterResponse[]>) => {
                resp.data.forEach((stepInfo) => {
                    if (!!stepInfo.info) {
                        addPopup({
                            title: "Informação importante!.",
                            description: stepInfo.info,
                            type: "info",
                            duration: "permanent",
                        });
                    }
                });
                addPopup({
                    title: "Usuário e canal criados com sucesso",
                    description:
                        "Agora você pode ativar o canal do usuário utilizando a tabela abaixo.",
                    type: "success",
                    duration: "temporary",
                });
            })
            .catch((err: AxiosError<SdrBasedErrors>) => {
                addPopup({
                    title: "Falha ao criar Hunter.",
                    description: err.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            })
            .finally(() => {
                setRegisteringSeller(false);
            });
    };

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle sx={{ padding: "20px 32px 0 32px" }}>
                Registrar Hunter
            </DialogTitle>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <DialogContent sx={{ padding: "5px 24px" }}>
                    <Typography sx={{ margin: "0 8px" }} variant="body1">
                        Ao realizar o envio deste formulário um convite para o
                        RDStation e pro Utalk serão enviados ao e-mail do
                        hunter, juntamente com um QRcode para configuração de um
                        canal no UTalk para o hunter.
                    </Typography>
                    <TextField
                        variant="outlined"
                        label="Nome *"
                        type="text"
                        className={classes.inputTextField}
                        {...register("name")}
                        error={!!errors?.name}
                        helperText={
                            errors?.name?.message ? (
                                <>{errors?.name?.message}</>
                            ) : (
                                <></>
                            )
                        }
                    />
                    <TextField
                        variant="outlined"
                        label="Sobrenome *"
                        type="text"
                        className={classes.inputTextField}
                        {...register("surname")}
                        error={!!errors?.surname}
                        helperText={
                            errors?.surname?.message ? (
                                <>{errors?.surname?.message}</>
                            ) : (
                                <></>
                            )
                        }
                    />
                    <TextField
                        variant="outlined"
                        label="Email *"
                        placeholder="email@frexco.com.br"
                        defaultValue="@frexco.com.br"
                        type="text"
                        className={classes.inputTextField}
                        {...register("email")}
                        error={!!errors?.email}
                        helperText={
                            errors?.email?.message ? (
                                <>{errors?.email?.message}</>
                            ) : (
                                <></>
                            )
                        }
                    />
                    <Autocomplete
                        className={classes.inputTextField}
                        options={sectors}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Selecione o polo*"
                                placeholder="Polo"
                            />
                        )}
                        onChange={(e, value) => {
                            setValue("squads", [value]);
                        }}
                    />
                </DialogContent>
                <DialogActions sx={{ padding: "0px 8px" }}>
                    <Button
                        sx={{ width: "100%", margin: "0px 14px 20px 24px" }}
                        loading={registeringSeller}
                        type="submit"
                    >
                        <Typography variant="body2">Registrar</Typography>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default SellerRegisterDialog;
