import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        "@media (max-width:620px)": {
            flexDirection: "column",
        },
    },
    form: {
        width: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "@media (max-width:620px)": {
            width: "100%",
        },
    },
    displayBox: {
        width: "50%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        "@media (max-width:620px)": {
            width: "100%",
        },
    },
    card: {
        height: "100%",
        width: "fit-content",
    },
}));
