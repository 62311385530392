import { api } from "../utils/Api/apiClient";

import { FieldValues } from "react-hook-form";

const sdrApiService = {
    postRecommendation: async (recommendation: FieldValues) => {
        const url = `api/sdr/sales-funnel/recommendation/`;

        return await api.post(url, recommendation);
    },
};

export default sdrApiService;
