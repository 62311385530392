import { api } from "../../utils/Api/apiClient";
import { getMembersQueryOptions } from "../../interfaces/QueriesOptions/ComercialTeamService";
import { IFrexcoSeller } from "../../interfaces/SellersApi/IFrexcoSeller";

class ComercialTeamService {
    url: string;
    constructor() {
        this.url = "api/sellers/";
    }

    getMembers = (params?: getMembersQueryOptions) =>
        api
            .get(this.url + `/frexco/`, { params: params })
            .then((res) => res.data);

    alterMember = (member: IFrexcoSeller) =>
        api.put(this.url + `/frexco/${member.id}/`, member);
}
export default ComercialTeamService;
