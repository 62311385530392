import { useEffect, useState } from "react";

import { Box, Divider, Skeleton, Typography } from "@mui/material";

import Button from "../../../../../components/Button";

import useSalesAreas from "../../../../../hooks/SalesBonusApi/useSalesAreas";

interface IButtonInfo {
    label: string;
    stateKey: string;
}

interface IAreasButtons {
    setSelectedSalesAreas: React.Dispatch<
        React.SetStateAction<number | undefined>
    >;
}

const AreasButtons = ({ setSelectedSalesAreas }: IAreasButtons) => {
    const [activeButton, setActiveButtonIndex] = useState<number>(0);

    const {
        data: hunterSalesAreasResponse,
        isFetching: gettingHunterSalesAreasResponse,
    } = useSalesAreas();

    const buttons: IButtonInfo[] | undefined =
        hunterSalesAreasResponse?.results.map((salesAreaWithInfo) => ({
            label: salesAreaWithInfo.name,
            stateKey: `show${salesAreaWithInfo.name}Status`,
        }));

    const handleButtonClick = (index: number) => {
        setActiveButtonIndex(index);
        if (!!buttons) {
            setSelectedSalesAreas(
                hunterSalesAreasResponse?.results.filter(
                    (salesAreaWithInfo) =>
                        salesAreaWithInfo.name === buttons[index].label
                )[0].id!
            );
        }
    };

    useEffect(() => {
        if (!!hunterSalesAreasResponse?.results.length)
            setSelectedSalesAreas(hunterSalesAreasResponse?.results[0].id!);
    }, [hunterSalesAreasResponse, setSelectedSalesAreas]);

    return (
        <Box>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"20px"}
            >
                {gettingHunterSalesAreasResponse ? (
                    <>
                        <Skeleton width={"180px"} height={"30px"} />
                        <Skeleton width={"180px"} height={"30px"} />
                        <Skeleton width={"180px"} height={"30px"} />
                    </>
                ) : (
                    <>
                        {buttons &&
                            buttons.map((buttonInfo, index) => (
                                <Button
                                    key={buttonInfo.stateKey}
                                    {...(activeButton === index
                                        ? { active: true }
                                        : {})}
                                    onClick={() => handleButtonClick(index)}
                                >
                                    <Typography variant="body2">
                                        {buttonInfo.label}
                                    </Typography>
                                </Button>
                            ))}
                    </>
                )}
            </Box>
            <Divider />
        </Box>
    );
};

export default AreasButtons;
