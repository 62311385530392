import useComercialTeamMembers from "../../../../../hooks/SellersApi/useComercialMember";

import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";
import { PromoteMemberButton } from "./CellRenderers";
import { getMembersQueryOptions } from "../../../../../interfaces/QueriesOptions/ComercialTeamService";

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DataGrid from "../../../../../components/DataGrid";
import { Box } from "@mui/material";

interface IAdminsGrid {
    adminsQuery: getMembersQueryOptions;
    updatingFields: Partial<IFrexcoSeller>;
}

const AdminsGrid = ({ adminsQuery, updatingFields }: IAdminsGrid) => {
    const columns: GridColDef<IFrexcoSeller>[] = [
        { field: "name", headerName: "Nome", flex: 1 },
        {
            field: "id",
            headerName: "",
            minWidth: 80,
            renderCell: (
                params: GridRenderCellParams<IFrexcoSeller, number>
            ) => {
                let member = params.row;
                return PromoteMemberButton({ member, updatingFields });
            },
        },
    ];

    const {
        data: comercialTeamMembersResponse,
        isFetching: gettingComercialTeamMembers,
    } = useComercialTeamMembers(adminsQuery);

    return (
        <Box width={"100%"} height={"500px"}>
            <DataGrid<IFrexcoSeller>
                rows={
                    comercialTeamMembersResponse?.results
                        ? comercialTeamMembersResponse?.results
                        : []
                }
                columns={columns}
                loading={gettingComercialTeamMembers}
            />
        </Box>
    );
};

export default AdminsGrid;
