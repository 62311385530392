import { IOnboardingClient } from "../interfaces/AbacateApi/IOnboardingClient";
import { api } from "../utils/Api/apiClient";

const clientsService = {
    getClient: async (params?: Object) => {
        const url = `api/abacate/v1/public/client/`;

        return await api.get(url, {
            params: params,
        });
    },
    getClientsWithoutFarmers: async (params?: Object) => {
        const url = `api/abacate/v1/public/clients-without-farmers/`;

        return await api.get(url, {
            params: params,
        });
    },
    getOnboardingClient: async (params?: Object) => {
        const url = `api/abacate/v1/public/onboarding-clients/`;

        return await api.get(url, {
            params: params,
        });
    },
    postOnboardingClients: async (data: IOnboardingClient[]) => {
        const url = `api/abacate/v1/public/onboarding-clients/`;

        return await api.post(url, data);
    },
    patchOnboardingClient: async (id: number, data: { farmer: string }) => {
        const url = `api/abacate/v1/public/onboarding-clients/${id}/`;

        return await api.patch(url, data);
    },
};

export default clientsService;
