import {
    MutationFunction,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query";
import HunterSalesService from "../../services/AbacateApi/hunterSalesService";
import { usePopup } from "../usePopup";

const hunterSalesService = new HunterSalesService();

const useEditHunterSales = () => {
    const queryClient = useQueryClient();
    const { addPopup } = usePopup();
    return useMutation(
        hunterSalesService.patchHunterSales as MutationFunction,
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    predicate: (query) =>
                        query.queryKey[0] === "hunters-sales-summary" ||
                        query.queryKey[0] === "hunters-sales",
                });
                addPopup({
                    title: "Venda alterada com sucesso.",
                    type: "success",
                    duration: "temporary",
                });
            },
            onError: () => {
                queryClient.invalidateQueries({
                    predicate: (query) =>
                        query.queryKey[0] === "hunters-sales-summary" ||
                        query.queryKey[0] === "hunters-sales",
                });
                addPopup({
                    title: "Erro ao alterar Venda.",
                    type: "error",
                    duration: "temporary",
                });
            },
        }
    );
};
export default useEditHunterSales;
