import { IHunterSales } from "../../interfaces/AbacateApi/IHunterSales";
import { api } from "../../utils/Api/apiClient";

class HunterSalesService {
    url: string;
    constructor() {
        this.url = `api/abacate/v1/public/hunters-sales/`;
    }

    getHunterSales = (params?: Object) =>
        api
            .get(this.url, {
                params: params,
            })
            .then((res) => res.data);

    patchHunterSales = (hunterSale: IHunterSales[]) =>
        api.patch(this.url, hunterSale, {}).then((res) => res.data);
}

export default HunterSalesService;
