import { api } from "../../utils/Api/apiClient";

class ClientJourneyService {
    url: string;
    constructor() {
        this.url = `api/sdr/frexco/client-journey/`;
    }

    getClientJourney = (params?: Object) =>
        api
            .get(this.url, {
                params: params,
            })
            .then((res) => res.data);
}

export default ClientJourneyService;
