import { useState } from "react";

import ComponentHeader from "../../../../../components/ComponentHeader";
import Circle from "../../../../../components/AgGrid/CellRenderers/Circle";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";
import FarmerFilterInputs from "../FiltersInputs";
import AddClientDialog from "../AddClientDialog";
import MoveClientsDialog from "../MoveClientsDialog";

import useFarmerWallet from "../../../../../hooks/SalesBonusApi/useFarmerWallet";
import useMutation from "../../../../../hooks/useMutate";

import { IFarmerWallet } from "../../../../../interfaces/SalesBonus/IWallets";
import { IFarmerWalletsQueryOptions } from "../../../../../interfaces/SalesBonus/QueriesOptions/IWalletsQueryOptions";

import { Box, Pagination, Stack } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import MoneyOffOutlinedIcon from "@mui/icons-material/MoneyOffOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import MoveDownOutlinedIcon from "@mui/icons-material/MoveDownOutlined";

import FarmerWalletApi from "../../../../../services/SalesBonusApi/farmerWallet";
import DataGrid from "../../../../../components/DataGrid";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";

const farmerWalletApi = new FarmerWalletApi();

const FarmerWallet = () => {
    const [farmerWalletParams, setFarmerWalletParams] =
        useState<IFarmerWalletsQueryOptions>({
            page: 1,
            farmer__in: undefined,
            paying_off: true,
            active: true,
            order_by: "updated_at",
        });

    const [openAddClientDialog, setOpenAddClientDialog] = useState(false);
    const [
        openChangePayingOffDialogConfirmartion,
        setOpenChangePayingOffDialogConfirmartion,
    ] = useState(false);
    const [openChangeActiveDialog, setOpenChangeActiveDialog] = useState(false);
    const [clientsToMove, setClientsToMove] = useState<IFarmerWallet[]>([]);
    const [openMoveClientsDialog, setOpenMoveClientsDialog] = useState(false);

    const gridRef = useGridApiRef();

    const { mutate: changeFarmerWallet } = useMutation({
        successText: "Registro alterado.",
        errorText: "Erro ao alterar registro.",
        queryKeyArray: ["farmer-wallet"],
        callback: farmerWalletApi.put,
    });

    const [columnDefs] = useState<GridColDef<IFarmerWallet>[]>([
        {
            field: "farmer",
            minWidth: 180,
            headerName: "Responsável",
            flex: 1,
        },
        {
            field: "cnpj",
            minWidth: 180,
            headerName: "CNPJ",
            flex: 1,
        },
        {
            field: "restaurant_name",
            minWidth: 300,
            headerName: "Nome do Restaurante",
            flex: 1,
        },
        {
            field: "paying_off",
            minWidth: 200,
            headerName: "Contando no mês",
            renderCell: (params) => {
                return Circle({ value: params.row.paying_off });
            },
        },
        {
            field: "active",
            width: 100,
            headerName: "Ativo",
            renderCell: (params) => {
                return Circle({ value: params.row.active });
            },
        },
    ]);

    const {
        data: farmerWalletResponse,
        isFetching: gettingFarmerWalletResponse,
    } = useFarmerWallet(farmerWalletParams);

    const handleChangePayingOff = () => {
        const selectedRows = gridRef.current.getSelectedRows();
        const selectedRowsArray = Array.from(
            selectedRows.values()
        ) as IFarmerWallet[];
        const dataWithPayingOffChanged = selectedRowsArray.map((record) => ({
            ...record,
            paying_off: !farmerWalletParams.paying_off,
        }));
        changeFarmerWallet({
            object: dataWithPayingOffChanged,
        });
    };

    const handleChangeActive = () => {
        const selectedRows = gridRef.current.getSelectedRows();
        const selectedRowsArray = Array.from(
            selectedRows.values()
        ) as IFarmerWallet[];
        const dataWithActiveChanged = selectedRowsArray.map((record) => ({
            ...record,
            active: !farmerWalletParams.active,
        }));
        changeFarmerWallet({
            object: dataWithActiveChanged,
        });
    };

    const handleOpenMoveClientsDialog = () => {
        const selectedRows = gridRef.current.getSelectedRows();
        const selectedRowsArray = Array.from(
            selectedRows.values()
        ) as IFarmerWallet[];
        setClientsToMove(selectedRowsArray);
        setOpenMoveClientsDialog(true);
    };

    return (
        <Box>
            <ComponentHeader.Root>
                <ComponentHeader.Title text="Carteira Farmer" />
                <ComponentHeader.Actions>
                    {farmerWalletParams.paying_off ? (
                        <ComponentHeader.Action
                            tooltip="Desconsiderar registros."
                            icon={
                                <MoneyOffOutlinedIcon
                                    fontSize="large"
                                    color="error"
                                />
                            }
                            action={() => {
                                setOpenChangePayingOffDialogConfirmartion(true);
                            }}
                        />
                    ) : (
                        <ComponentHeader.Action
                            tooltip="Considerar registros."
                            icon={
                                <CurrencyExchangeOutlinedIcon
                                    fontSize="large"
                                    color="primary"
                                />
                            }
                            action={() => {
                                setOpenChangePayingOffDialogConfirmartion(true);
                            }}
                        />
                    )}
                    {farmerWalletParams.active ? (
                        <ComponentHeader.Action
                            tooltip="Desativar clientes."
                            icon={
                                <DeleteForeverOutlinedIcon
                                    fontSize="large"
                                    color="error"
                                />
                            }
                            action={() => {
                                setOpenChangeActiveDialog(true);
                            }}
                        />
                    ) : (
                        <ComponentHeader.Action
                            tooltip="Ativar clientes."
                            icon={
                                <RestoreFromTrashOutlinedIcon
                                    fontSize="large"
                                    color="primary"
                                />
                            }
                            action={() => {
                                setOpenChangeActiveDialog(true);
                            }}
                        />
                    )}
                    <ComponentHeader.Action
                        tooltip="Mover clientes."
                        icon={
                            <MoveDownOutlinedIcon
                                fontSize="large"
                                color="primary"
                            />
                        }
                        action={() => {
                            handleOpenMoveClientsDialog();
                        }}
                    />
                    <ComponentHeader.Action
                        tooltip="Adicionar cliente."
                        icon={
                            <AddCircleOutlineOutlinedIcon
                                fontSize="large"
                                color="info"
                            />
                        }
                        action={() => {
                            setOpenAddClientDialog(true);
                        }}
                    />
                </ComponentHeader.Actions>
            </ComponentHeader.Root>
            <FarmerFilterInputs
                queryParams={farmerWalletParams}
                setQueryParams={setFarmerWalletParams}
            />

            <Box width={"100%"} height={"500px"}>
                <DataGrid<IFarmerWallet>
                    apiRef={gridRef}
                    columns={columnDefs}
                    rows={
                        farmerWalletResponse?.results
                            ? farmerWalletResponse?.results
                            : []
                    }
                    loading={gettingFarmerWalletResponse}
                    isRowSelectable={() => true}
                    checkboxSelection
                />
                {farmerWalletResponse && (
                    <Box
                        display={"flex"}
                        marginTop={"10px"}
                        justifyContent={"end"}
                    >
                        <Stack spacing={2}>
                            <Pagination
                                count={Math.ceil(
                                    farmerWalletResponse?.info.count /
                                        farmerWalletResponse?.info.per_page
                                )}
                                page={farmerWalletParams.page}
                                onChange={(e, value) =>
                                    setFarmerWalletParams((prevState) => ({
                                        ...prevState,
                                        page: value,
                                    }))
                                }
                                shape="rounded"
                            />
                        </Stack>
                    </Box>
                )}
            </Box>
            {openAddClientDialog && (
                <AddClientDialog
                    setOpenDialog={setOpenAddClientDialog}
                    openDialog={openAddClientDialog}
                    queryKey={["farmer-wallet"]}
                    callback={farmerWalletApi.post}
                />
            )}
            {openChangePayingOffDialogConfirmartion && (
                <ConfirmationDialog
                    setOpenDialog={setOpenChangePayingOffDialogConfirmartion}
                    openDialog={openChangePayingOffDialogConfirmartion}
                    title={`Deseja mesmo ${
                        farmerWalletParams.paying_off
                            ? "desconsiderar"
                            : "considerar"
                    } os clientes?`}
                    callback={handleChangePayingOff}
                />
            )}
            {openChangeActiveDialog && (
                <ConfirmationDialog
                    setOpenDialog={setOpenChangeActiveDialog}
                    openDialog={openChangeActiveDialog}
                    title={`Deseja mesmo ${
                        farmerWalletParams.active ? "desativar" : "reativar"
                    } os clientes?`}
                    callback={handleChangeActive}
                />
            )}
            {openMoveClientsDialog && (
                <MoveClientsDialog
                    setOpenDialog={setOpenMoveClientsDialog}
                    openDialog={openMoveClientsDialog}
                    clients={clientsToMove}
                />
            )}
        </Box>
    );
};

export default FarmerWallet;
