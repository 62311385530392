import useComercialTeamMembers from "../../../../../hooks/SellersApi/useComercialMember";

import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";

import { DemoteMemberButton } from "./CellRenderers";
import DataGrid from "../../../../../components/DataGrid";

import { Box } from "@mui/material";

import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { GridRenderCellParams } from "@mui/x-data-grid/models/params/gridCellParams";

const LeadersGrid = () => {
    const columns: GridColDef<IFrexcoSeller>[] = [
        { field: "name", headerName: "Nome", flex: 1 },
        {
            field: "id",
            headerName: "",
            minWidth: 80,
            renderCell: (
                params: GridRenderCellParams<IFrexcoSeller, number>
            ) => {
                let member = params.row;
                return DemoteMemberButton(member);
            },
        },
    ];

    const {
        data: comercialTeamMembersResponse,
        isFetching: gettingComercialTeamMembers,
    } = useComercialTeamMembers({
        active: true,
        is_team_leader: true,
    });

    return (
        <Box width={"100%"} height={"500px"}>
            <DataGrid<IFrexcoSeller>
                rows={
                    comercialTeamMembersResponse?.results
                        ? comercialTeamMembersResponse?.results
                        : []
                }
                columns={columns}
                loading={gettingComercialTeamMembers}
            />
        </Box>
    );
};

export default LeadersGrid;
