import React, { useState } from "react";

import Button from "../../../../../components/Button";
import TextField from "../../../../../components/TextField";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import usersService from "../../../../../services/usersService";

import { usePopup } from "../../../../../hooks/usePopup";

import { AxiosError } from "axios";

let formSchema = yup.object().shape({
    password: yup.string().required("Senha é obrigatório"),
});

interface IChangePwdDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChangePwdDialog = ({
    openDialog,
    setOpenDialog,
}: IChangePwdDialog) => {
    const [showPassword, setShowPassword] = useState(false);
    const [changingPassword, setChangingPassword] = useState(false);

    const { addPopup } = usePopup();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({ resolver: yupResolver(formSchema) });

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleShowPassword = () => {
        setShowPassword((state) => !state);
    };

    const handleChangePassword = async (password: string) => {
        setChangingPassword(true);
        let username = localStorage.getItem("user");
        await usersService
            .changePassword(username!, password)
            .then(() => {
                addPopup({
                    title: "Senha alterada!",
                    type: "success",
                    duration: "temporary",
                });
                handleClose();
            })
            .catch((err: AxiosError<{ error: string }>) => {
                addPopup({
                    title: "Falha ao alterar senha.",
                    description: err.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            })
            .finally(() => {
                setChangingPassword(false);
                reset({ password: "" });
            });
    };

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>Escolha uma nova senha.</DialogTitle>
            <form
                onSubmit={handleSubmit((password) =>
                    handleChangePassword(password.password)
                )}
            >
                <DialogContent>
                    <TextField
                        variant="outlined"
                        placeholder="Senha"
                        type={showPassword ? "text" : "password"}
                        error={!!errors?.password}
                        helperText={
                            errors?.password?.message ? (
                                <>{errors?.password?.message}</>
                            ) : (
                                <></>
                            )
                        }
                        {...register("password", { required: true })}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle-password"
                                        onClick={handleShowPassword}
                                    >
                                        {showPassword ? (
                                            <Visibility
                                                style={{ color: "#89BD23" }}
                                            />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    ></TextField>
                </DialogContent>
                <DialogActions>
                    <Button loading={changingPassword} type="submit">
                        Enviar
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
