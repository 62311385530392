import { useQuery } from "@tanstack/react-query";
import SalesAreasWithInfoApi from "../../services/SalesBonusApi/salesAreasWithInfoApi";

const salesAreasWithInfoApi = new SalesAreasWithInfoApi();

const useSalesAreasWithInfo = (query?: Object, definitions?: Object) =>
    useQuery({
        ...definitions,
        queryKey: ["sales-areas-with-info", query],
        queryFn: () => salesAreasWithInfoApi.get(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar áreas de atuação.",
        },
    });
export default useSalesAreasWithInfo;
