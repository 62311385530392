import { useState } from "react";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

import { IconButton, Tooltip, CircularProgress } from "@mui/material";

import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";

import useAlterComercialTeamMember from "../../../../../hooks/SellersApi/useAlterComercialMember";

import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";

export const DemoteMemberButton = (member: IFrexcoSeller) => {
    const [openConfimartionDialog, setOpenConfimartionDialog] = useState(false);

    const { mutate: alterMember, isLoading: alteringMember } =
        useAlterComercialTeamMember();

    const handleConfirmDemoteMember = () => {
        setOpenConfimartionDialog(true);
    };

    const demoteMember = () => {
        member.is_regional_leader = false;
        alterMember(member);
    };

    return (
        <>
            <Tooltip title="Rebaixar Membro">
                <IconButton onClick={handleConfirmDemoteMember}>
                    {alteringMember ? (
                        <CircularProgress size={20} />
                    ) : (
                        <RemoveOutlinedIcon color="error" />
                    )}
                </IconButton>
            </Tooltip>
            <ConfirmationDialog
                openDialog={openConfimartionDialog}
                setOpenDialog={setOpenConfimartionDialog}
                callback={demoteMember}
                title="Tem certeza que deseja rebaixar este membro?"
            />
        </>
    );
};
