import { useQuery } from "@tanstack/react-query";
import SalesAreasApi from "../../services/SalesBonusApi/salesAreasApi";

const salesAreasApi = new SalesAreasApi();

const useSalesAreas = (query?: Object, definitions?: Object) =>
    useQuery({
        ...definitions,
        queryKey: ["sales-areas", query],
        queryFn: () => salesAreasApi.get(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar éreas de atuação.",
        },
    });
export default useSalesAreas;
