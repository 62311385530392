import { api } from "../../utils/Api/apiClient";
class DealLostReasonService {
    url: string;
    constructor() {
        this.url = `api/sdr/automatic-distribution/lost-reason/`;
    }

    getDealLostReason = (params?: Object) =>
        api
            .get(this.url, {
                params: params,
            })
            .then((res) => res.data);
}

export default DealLostReasonService;
