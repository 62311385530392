import { ChangeEvent, useState } from "react";

import { Box, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { useAuth } from "../../../../../hooks/useAuth";
import useTeams from "../../../../../hooks/SellersApi/useTeams";

import { PermissionGate } from "../../../../../components/PermissionGate";
import ComponentHeader from "../../../../../components/ComponentHeader";
import AdminsGrid from "../AdminsGrid";
import LeadersGrid from "../LeadersGrid";

const LeaderRegister = () => {
    const [showAdminsGrid, setShowAdminsGrid] = useState(true);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setShowAdminsGrid(JSON.parse((event.target as HTMLInputElement).value));
    };
    
    const { permissions: userPermissions } = useAuth();

    const { data: teamsResponse } = useTeams({ active: true });

    

    return (
        <Box>
            <PermissionGate
                neededpermissions={["leader-register"]}
                userPermissions={userPermissions}
            >
                <ComponentHeader.Root>
                    <ComponentHeader.Title text="Definição de Líderes" />
                    <ComponentHeader.Desc text="Utilize a tabela abaixo para gerenciar a designação dos líderes de time." />
                </ComponentHeader.Root>
                <Box>
                <FormControl>
                    <RadioGroup
                        defaultValue="true"
                        onChange={handleChange}
                        row
                    >
                        <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Hunter"
                        />
                        <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Líderes de polo"
                        />
                    </RadioGroup>
                </FormControl>
                    { showAdminsGrid ? (
                        <AdminsGrid
                        adminsQuery={{
                            active: true,
                            is_team_leader: false,
                            squads__overlap: JSON.stringify(
                                teamsResponse?.results.map((team) => team.name)
                            ),
                        }}
                        updatingFields={{ is_team_leader: true }}
                    />
                    ) : (
                        <LeadersGrid />
                    ) }
                    
                </Box>
            </PermissionGate>
        </Box>
    );
};

export default LeaderRegister;
