import { ISalesGoalClass } from "../../interfaces/SalesBonus/ISalesGoalClass";
import { ISalesGoalsClassQueryOptions } from "../../interfaces/SalesBonus/QueriesOptions/ISalesGoalsClassQueryOptions";
import GenericApi from "../genericApi";

class SalesGoalsClassApi extends GenericApi<
    ISalesGoalClass,
    ISalesGoalsClassQueryOptions,
    ISalesGoalClass
> {
    constructor() {
        super("/api/sales-bonus-api/goal-class/");
    }
}

export default SalesGoalsClassApi;
