import { useState } from "react";

import { Box } from "@mui/material";

import ComponentHeader from "../../../../../components/ComponentHeader";
import DataGrid from "../../../../../components/DataGrid";

import { TBonusPayment } from "../../../../../interfaces/SalesBonus/IBonusPayment";

import useBonusResults from "../../../../../hooks/SalesBonusApi/useBonusResults";
import { GridColDef } from "@mui/x-data-grid";

function toPercentage(number: number | undefined): string {
    const percentage = number ? number.toFixed(0) + "%" : "0%";
    return percentage;
}

const BonusPaymentTable = () => {
    const dateObj = new Date();

    const { data: bonusResults, isFetching: gettingBonusResults } =
        useBonusResults();

    const [columnDefs] = useState<GridColDef<TBonusPayment>[]>([
        {
            field: "registration_id",
            minWidth: 115,
            headerName: "Matrícula",
            flex: 1,
        },
        {
            field: "name",
            minWidth: 200,
            headerName: "Nome",
            flex: 1,
        },
        {
            field: "squad",
            minWidth: 120,
            headerName: "Time",
            flex: 1,
        },
        {
            field: "sales_area",
            minWidth: 100,
            headerName: "Área",
            flex: 1,
        },
        {
            field: "sales_level",
            minWidth: 125,
            headerName: "Nível",
            flex: 1,
        },
        {
            field: "bonus",
            minWidth: 150,
            headerName: "Bônus",
            flex: 1,
            renderCell: (params) => {
                const value = params.value
                    ? "R$: " + params.value.toFixed(2)
                    : "R$: 00";
                return value;
            },
        },
        {
            field: "activations",
            minWidth: 120,
            headerName: "Ativações",
            flex: 1,
        },
        {
            field: "gains",
            minWidth: 100,
            headerName: "Ganhos",
            flex: 1,
        },
        {
            field: "revenue",
            minWidth: 150,
            headerName: "Faturamento",
            flex: 1,
            renderCell: (params) => {
                const value = params.value
                    ? "R$: " + params.value.toFixed(2)
                    : "R$: 00";
                return value;
            },
        },
        {
            field: "goal",
            minWidth: 100,
            headerName: "Meta",
            flex: 1,
            renderCell: (params) => {
                const value = params.value ? params.value.toFixed(0) : 0;
                return value;
            },
        },
        {
            field: "goal_achievement_percentage",
            minWidth: 150,
            headerName: "% da Meta",
            flex: 1,
            renderCell: (params) => {
                return toPercentage(params.value);
            },
            cellClassName: (params) => {
                if (
                    params.row.role === "hunter" &&
                    params.row.sales_area !== "KA"
                ) {
                    if (params.value < 100) {
                        return "fail";
                    }
                    if (params.value >= 100) {
                        if (
                            params.row.super_goal_achievement_percentage < 100
                        ) {
                            return "warning";
                        }
                        if (
                            params.row.super_goal_achievement_percentage >= 100
                        ) {
                            return "success";
                        }
                    }
                }

                if (params.row?.role === "leader") {
                    if (params.value < 80) {
                        return "fail";
                    }
                    if (params.value >= 80 && params.value < 100) {
                        return "warning";
                    }
                    if (params.value >= 100) {
                        return "success";
                    }
                }
                return "";
            },
        },
        {
            field: "super_goal",
            minWidth: 140,
            headerName: "Super Meta",
            flex: 1,
            renderCell: (params) => {
                const value = params.value ? params.value.toFixed(0) : 0;
                return value;
            },
        },
        {
            field: "super_goal_achievement_percentage",
            minWidth: 185,
            headerName: "% da Super Meta",
            flex: 1,
            renderCell: (params) => {
                return toPercentage(params.value);
            },
            cellClassName: (params) => {
                if (
                    params.row.role === "hunter" &&
                    params.row.sales_area !== "KA"
                ) {
                    if (params.row.goal_achievement_percentage < 100) {
                        return "fail";
                    }
                    if (params.row.goal_achievement_percentage >= 100) {
                        if (params.value < 100) {
                            return "warning";
                        }
                        if (params.value >= 100) {
                            return "success";
                        }
                    }
                }

                if (params.row.role === "leader") {
                    if (params.value < 100) {
                        return "facil";
                    }
                    if (params.value >= 100) {
                        return "success";
                    }
                }
                return "";
            },
        },
    ]);

    return (
        <Box>
            <ComponentHeader.Root>
                <ComponentHeader.Title
                    text={`Tabela do Período de 
                        01/
                        ${(dateObj.getMonth() + 1).toString().padStart(2, "0")}
                        até 
                        ${dateObj.getDate().toString().padStart(2, "0")}
                        /
                        ${(dateObj.getMonth() + 1)
                            .toString()
                            .padStart(2, "0")}`}
                />
            </ComponentHeader.Root>
            <Box width={"100%"} height={"500px"}>
                <DataGrid
                    columns={columnDefs}
                    rows={bonusResults ? bonusResults : []}
                    loading={gettingBonusResults}
                    getRowId={(row) => row.name}
                    hideFooterPagination={false}
                />
            </Box>
        </Box>
    );
};

export default BonusPaymentTable;
