import { useMutation, useQueryClient } from "@tanstack/react-query";
import ClientsWithoutFarmerParamsService from "../../services/SdrApi/clientsWithoutFarmerParamsService";
import { usePopup } from "../usePopup";
import { AxiosError } from "axios";
import { SdrBasedErrors } from "../../utils/Api/responses/responses";

const clientsWithoutFarmerParamsService = new ClientsWithoutFarmerParamsService();

const useAlterClientsWithoutFarmerParamsService = () => {
    const queryClient = useQueryClient();
    const { addPopup } = usePopup();
    return useMutation(
        clientsWithoutFarmerParamsService.putParams,
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ["clients-without-params"],
                });
                addPopup({
                    title: "Parâmetros dos vendedores alterados.",
                    type: "success",
                    duration: "temporary",
                });
            },
            onError: (error: AxiosError<SdrBasedErrors>) => {
                addPopup({
                    title: "Erro ao alterar parâmetros dos vendedores.",
                    description: error.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            },
        }
    );
};
export default useAlterClientsWithoutFarmerParamsService;
