import { useQuery } from "@tanstack/react-query";
import FrexcoSellersHistoryApi from "../../services/SellersApi/frexcoSellersHistoryApi";

const frexcoSellersApi = new FrexcoSellersHistoryApi();

const useFrexcoSellerHistory = (query?: Object, definitions?: Object) =>
    useQuery({
        ...definitions,
        queryKey: ["frexco-sellers-history", query],
        queryFn: () => frexcoSellersApi.get(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar histórico dos vendedores.",
        },
    });

export default useFrexcoSellerHistory;
