import { useState } from "react";
import { AuthError, useAuth } from "../../../hooks/useAuth";
import { usePopup } from "../../../hooks/usePopup";
import { useStyles } from "./styles";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Typography } from "@mui/material";

import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import BackgroundImage from "../../../assets/images/loginBackground.png";
import GroselhaLogo from "../../../assets/images/logoGroselha.png";

import Button from "../../../components/Button";
import TextField from "../../../components/TextField";

let loginSchema = yup.object().shape({
  username: yup.string().required("Nome de usuário é obrigatório"),
  password: yup.string().required("Senha é obrigatório"),
});

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const { signIn } = useAuth();
  const { addPopup } = usePopup();
  const classes = useStyles();

  const handleShowPassword = () => setShowPassword((state) => !state);

  const handleLogin = async (username: string, password: string) => {
    setLoading(true);
    try {
      await signIn({
        username: username,
        password: password,
      });
    } catch (error) {
      if (error instanceof AuthError) {
        addPopup({
          type: "error",
          title: "Credenciais inválidas!",
          duration: "temporary",
        });
      } else if (error instanceof TypeError) {
        addPopup({
          type: "error",
          title: "Algo deu errado. Verfique sua conexão e tente novamente!",
          duration: "temporary",
        });
      } else {
        addPopup({
          type: "error",
          title: "Algo deu errado. Contate o administrador!",
          duration: "temporary",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <img
        src={BackgroundImage}
        className={classes.background}
        alt="imagem de um galpão da Frexco"
      />
      <div className={classes.loginBox}>
        <img
          src={GroselhaLogo}
          alt="Logo do sistema"
          className={classes.logoImg}
        />
        <div className={classes.title}>
          <Typography variant="h1" color="primary.main">
            Olá, Frexconauta!
          </Typography>
          <Typography variant="subtitle1" color="primary.main">
            Acesse sua conta!
          </Typography>
        </div>
        <form
          onSubmit={handleSubmit((user) => {
            handleLogin(user.username, user.password);
          })}
          className={classes.form}
        >
          <TextField
            className={classes.formObjects}
            variant="outlined"
            placeholder="Usuário"
            type="text"
            error={!!errors?.username}
            helperText={
              errors?.username?.message ? (
                <>{errors?.username?.message}</>
              ) : (
                <></>
              )
            }
            {...register("username", { required: true })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          ></TextField>
          <TextField
            className={classes.formObjects}
            variant="outlined"
            placeholder="Senha"
            type={showPassword ? "text" : "password"}
            error={!!errors?.password}
            helperText={
              errors?.password?.message ? (
                <>{errors?.password?.message}</>
              ) : (
                <></>
              )
            }
            {...register("password", { required: true })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle-password"
                    onClick={handleShowPassword}
                  >
                    {showPassword ? (
                      <Visibility style={{ color: "#89BD23" }} />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
          <Button
            type="submit"
            className={classes.formObjects}
            loading={loading}
          >
            <Typography variant="h3">Entrar</Typography>
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
