import { RouteProps } from "react-router-dom";

import Login from "../pages/public/Login";
import Welcome from "../pages/private/Welcome";
import Recommend from "../pages/private/Recommend";
import AutoAssignment from "../pages/private/AutoAssignment";
import ComercialTeamManagement from "../pages/private/ComercialTeamManagement";
import AdminPanel from "../pages/private/AdminPanel";
import Profile from "../pages/private/Profile";
import SingleSeller from "../pages/private/SingleSeller";
import Onboarding from "../pages/private/OnboardingClients";
import HunterSales from "../pages/private/HunterSales";
import Orders from "../pages/private/Orders";
import BonusConfigPage from "../pages/private/BonusConfig";
import BonusLevelEdit from "../pages/private/BonusLevelEdit";
import TeamsManagement from "../pages/private/TeamsManagement";
import Wallets from "../pages/private/Wallets";
import BonusPaymentPage from "../pages/private/BonusPaymentPage";

import RecommendIcon from "@mui/icons-material/Recommend";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';

export type IRoute = RouteProps & {
    isPrivate: boolean;
    requiredPermissions: string[];
    menuName?: string;
    menuIcon?: React.ReactNode;
    path: string;
};

const RouteList: IRoute[] = [
    {
        isPrivate: false,
        requiredPermissions: [],
        path: "/login",
        element: <Login />,
    },
    {
        isPrivate: true,
        requiredPermissions: [],
        path: "/",
        element: <Welcome />,
    },
    {
        isPrivate: true,
        requiredPermissions: ["sales-funnel | recommendation"],
        path: "/recommend",
        element: <Recommend />,
        menuName: "Recomendação",
        menuIcon: <RecommendIcon />,
    },
    {
        isPrivate: true,
        requiredPermissions: [
            "groselha | admin",
            "groselha | admin | view only",
        ],
        path: "/admin",
        element: <AdminPanel />,
    },
    {
        isPrivate: true,
        requiredPermissions: ["groselha | user"],
        path: "/profile",
        element: <Profile />,
    },
    {
        isPrivate: true,
        requiredPermissions: ["top-secret"],
        path: "/auto-assignment",
        element: <AutoAssignment />,
        menuName: "Atribuição Automática",
        menuIcon: <AssignmentIndIcon />,
    },
    {
        isPrivate: true,
        requiredPermissions: ["comercial-team | management"],
        path: "/management/comercial-team",
        element: <ComercialTeamManagement />,
        menuName: "Time Comercial",
        menuIcon: <PeopleAltOutlinedIcon />,
    },
    {
        isPrivate: true,
        requiredPermissions: ["seller | single"],
        path: "/seller/:id",
        element: <SingleSeller />,
    },
    {
        isPrivate: true,
        requiredPermissions: ["onboarding-clients"],
        path: "/onboarding-clients",
        element: <Onboarding />,
        menuName: "Onboarding Clientes",
        menuIcon: <PeopleAltOutlinedIcon />,
    },
    {
        isPrivate: true,
        requiredPermissions: ["hunter-sales"],
        path: "/hunter-sales",
        element: <HunterSales />,
        menuName: "Registros de Vendas",
        menuIcon: <AttachMoneyOutlinedIcon />,
    },
    {
        isPrivate: true,
        requiredPermissions: ["orders"],
        path: "/orders",
        element: <Orders />,
        menuName: "Pedidos",
        menuIcon: <ListAltOutlinedIcon />,
    },
    {
        isPrivate: true,
        requiredPermissions: ["bonus"],
        path: "/bonus",
        element: <BonusConfigPage />,
        menuName: "Bonificação",
        menuIcon: <MilitaryTechOutlinedIcon />,
    },
    {
        isPrivate: true,
        requiredPermissions: ["bonus-level | edit"],
        path: "/bonus/level/:areaId/:levelId",
        element: <BonusLevelEdit />,
    },
    {
        isPrivate: true,
        requiredPermissions: ["sellers | teams"],
        path: "/teams",
        element: <TeamsManagement />,
        menuName: "Equipes",
        menuIcon: <GroupsOutlinedIcon />,
    },
    {
        isPrivate: true,
        requiredPermissions: ["wallets"],
        path: "/wallets",
        element: <Wallets />,
        menuName: "Carteiras",
        menuIcon: <WalletOutlinedIcon />,
    },
    {
        isPrivate: true,
        requiredPermissions: ["bonus-payment-page"],
        path: "/bonus-payment-page",
        element: <BonusPaymentPage />,
        menuName: "Pagamento de Bônus",
        menuIcon: <PriceChangeOutlinedIcon />,
    },
];

export default RouteList;
