import { api } from "../../utils/Api/apiClient";

class ClientsWithoutFarmerParamsService {
    url: string;
    constructor() {
        this.url = `api/sdr/farmers/params/`;
    }

    getParams = (params?: Object) =>
        api
            .get(this.url, {
                params: params,
            })
            .then((res) => res.data);

    putParams = (huntersParams: { hunter_id: number; farmer_id: number }[]) =>
        api.put(this.url, huntersParams).then((res) => res.data);
}

export default ClientsWithoutFarmerParamsService;
