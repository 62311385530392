import { useState } from "react";

import useFrexcoSellerHistory from "../../../../../hooks/SellersApi/useFrexcoSellerHistory";
import useMutation from "../../../../../hooks/useMutate";

import { IFrexcoSellerHistory } from "../../../../../interfaces/SellersApi/IFrexcoSellerHistory";
import { MonthObject } from "../../../../../interfaces/IMonthObjects";

import { Autocomplete, Box } from "@mui/material";

import {
    YYYYMMDDtoDDMMYYYY,
    getCurrentMonth,
    getPastMonths,
} from "../../../../../utils/dateFunctions";
import FrexcoSellersHistoryApi from "../../../../../services/SellersApi/frexcoSellersHistoryApi";

import TextField from "../../../../../components/TextField";
import DeleteItemCellRenderer from "../../../../../components/AgGrid/CellRenderers/DeleteItem";
import DataGrid from "../../../../../components/DataGrid";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

const SellerHistoryGrid = () => {
    const columnsDefs: GridColDef<IFrexcoSellerHistory>[] = [
        {
            field: "name",
            minWidth: 250,
            headerName: "Membro",
            flex: 1,
            valueGetter: (params) => params.row.seller.name,
        },
        {
            field: "changed_at",
            minWidth: 90,
            headerName: "Em",
            flex: 1,
            renderCell: (
                params: GridRenderCellParams<IFrexcoSellerHistory, string>
            ) => {
                return YYYYMMDDtoDDMMYYYY(params.value ? params.value : "");
            },
            editable: true,
        },
        {
            field: "field",
            minWidth: 130,
            headerName: "Campo",
            flex: 1,
        },
        {
            field: "old_value",
            minWidth: 130,
            headerName: "De",
            flex: 1,
        },
        {
            field: "new_value",
            minWidth: 130,
            headerName: "Para",
            flex: 1,
        },
        {
            field: "",
            width: 80,
            headerName: "",
            renderCell: (params) => {
                return (
                    <DeleteItemCellRenderer
                        callback={() => {
                            params.row.deleted = true;

                            updateRegister({
                                id: params.row.id,
                                object: params.row,
                            });
                        }}
                    />
                );
            },
        },
    ];

    const [selectedPeriod, setSelectedPeriod] = useState<MonthObject>(
        getCurrentMonth()
    );

    const frexcoSellersApi = new FrexcoSellersHistoryApi();

    const { data: sellerHistoryResponse, isFetching: gettingSellerHistory } =
        useFrexcoSellerHistory({
            deleted: false,
            changed_at__gte: selectedPeriod.start_date,
            changed_at__lte: selectedPeriod.end_date,
            per_page: 200,
        });

    const { mutate: updateRegister } = useMutation({
        successText: "Registro de histórico alterado com sucesso.",
        errorText: "Erro ao alterar registro de histórico.",
        queryKeyArray: ["frexco-sellers-history"],
        callback: frexcoSellersApi.put,
    });

    return (
        <div style={{ display: "block", width: "100%" }}>
            <Box gap={"40px"} style={{ width: 300 }}>
                <Autocomplete
                    id="tags-standard"
                    options={getPastMonths()}
                    getOptionLabel={(option) => option.label}
                    defaultValue={selectedPeriod}
                    loading={gettingSellerHistory}
                    isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Selecione o período."
                            placeholder="Períodos"
                        />
                    )}
                    onChange={(e, value) => {
                        if (value) {
                            setSelectedPeriod(value);
                        }
                    }}
                />
            </Box>
            <Box width={"100%"} height={"500px"} marginTop={"15px"}>
                <DataGrid<IFrexcoSellerHistory>
                    rows={
                        sellerHistoryResponse?.results
                            ? sellerHistoryResponse?.results
                            : []
                    }
                    columns={columnsDefs}
                    loading={gettingSellerHistory}
                    processRowUpdate={(newRow) => {
                        updateRegister({
                            id: newRow.id,
                            object: newRow,
                        });

                        return newRow;
                    }}
                />
            </Box>
        </div>
    );
};

export default SellerHistoryGrid;
