import { useState } from "react";

import ComponentHeader from "../../../../../components/ComponentHeader";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CreateGoalDialog from "../CreateGoalDialog";
import { ISalesLevel } from "../../../../../interfaces/SalesBonus/ISalesLevel";

interface ILevelGoalsHeader {
    level: ISalesLevel;
}

const Header = ({ level }: ILevelGoalsHeader) => {
    const [openCreateGoalDialog, setOpenCreateGoalDialog] = useState(false);

    return (
        <>
            <ComponentHeader.Root>
                <ComponentHeader.Title text={`Metas do ${level.name}`} />
                <ComponentHeader.Actions>
                    <ComponentHeader.Action
                        tooltip="Criar Meta"
                        icon={
                            <AddCircleOutlineOutlinedIcon
                                fontSize="large"
                                color="info"
                            />
                        }
                        action={() => {
                            setOpenCreateGoalDialog(true);
                        }}
                    />
                </ComponentHeader.Actions>
            </ComponentHeader.Root>
            <CreateGoalDialog
                openDialog={openCreateGoalDialog}
                setOpenDialog={setOpenCreateGoalDialog}
                level={level}
            />
        </>
    );
};

export default Header;
