import { ISalesAreasWithInfo } from "../../interfaces/SalesBonus/ISalesAreas";
import { ISalesAreasWithInfoQueryOptions } from "../../interfaces/SalesBonus/QueriesOptions/ISalesAreasWithInfoQueryOptions";
import GenericApi from "../genericApi";

class SalesAreasWithInfoApi extends GenericApi<
    ISalesAreasWithInfo,
    ISalesAreasWithInfoQueryOptions,
    ISalesAreasWithInfo
> {
    constructor() {
        super("/api/sales-bonus-api/params/");
    }
}

export default SalesAreasWithInfoApi;
