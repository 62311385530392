import axios, { AxiosResponse } from "axios";
import { ICepInfo } from "../../interfaces/BrasilApi/ICepInfos";

const api = axios.create({
    baseURL: "https://brasilapi.com.br/api",
    headers: {
        "Content-Type": "application/json",
    },
});

class BrasilApi {
    getCepInfos = (cep: string) =>
        api
            .get(`/cep/v1/${cep}`)
            .then((res: AxiosResponse<ICepInfo>) => res.data);

    getCepInfosV2 = (cep: string) =>
        api
            .get(`/cep/v2/${cep}`)
            .then((res: AxiosResponse<ICepInfo>) => res.data);
}

export default BrasilApi;
