import { AxiosResponse } from "axios";
import { SdrBasedGenericResponses } from "../utils/Api/responses/responses";
import { api } from "../utils/Api/apiClient";

class GenericApi<T, P, Q> {
    endpoint: string;
    constructor(endpoint: string) {
        this.endpoint = endpoint;
    }

    get = (params?: P) =>
        api
            .get(this.endpoint, { params: params })
            .then(
                (res: AxiosResponse<SdrBasedGenericResponses<T>>) => res.data
            );

    post = (object: Q | Q[]) =>
        api
            .post(this.endpoint, object)
            .then((res: AxiosResponse<T>) => res.data);

    put = ({ id, object }: { id: number; object: Partial<T> }) =>
        api.put(this.endpoint + `${id}/`, object).then((res) => res.data);

    delete = (objectId: number) =>
        api.delete(this.endpoint + `${objectId}/`).then((res) => res.data);
}

export default GenericApi;
