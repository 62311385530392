import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    form: {
        width: "360px",
        "& .MuiTextField-root": {
            margin: "10px 0 0 0",
        },
    },
    contentBox: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },
}));
