import { useQuery } from "@tanstack/react-query";
import SalesLevelsApi from "../../services/SalesBonusApi/salesLevelsApi";

const salesLevelsApi = new SalesLevelsApi();

const useSalesLevels = (query?: Object, definitions?: Object) =>
    useQuery({
        ...definitions,
        queryKey: ["sales-levels", query],
        queryFn: () => salesLevelsApi.get(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar níveis.",
        },
    });
export default useSalesLevels;
