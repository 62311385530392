import { ISalesAreas } from "../../interfaces/SalesBonus/ISalesAreas";
import { ISalesAreasQueryOptions } from "../../interfaces/SalesBonus/QueriesOptions/ISalesAreasQueryOptions";
import GenericApi from "../genericApi";

class SalesAreasApi extends GenericApi<
    ISalesAreas,
    ISalesAreasQueryOptions,
    ISalesAreas
> {
    constructor() {
        super("/api/sales-bonus-api/sales-area/");
    }
}

export default SalesAreasApi;
