import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

import Button from "../../../../../components/Button";
import SellerRegisterDialog from "../../../../../components/SellerRegisterDialog";
import { PermissionGate } from "../../../../../components/PermissionGate";

import { Circle, IrregularityAlert } from "./CellRenderers";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { useAuth } from "../../../../../hooks/useAuth";
import useComercialTeamMembers from "../../../../../hooks/SellersApi/useComercialMember";
import useUtalkChannels from "../../../../../hooks/SellersApi/useUtalkChannels";

import { useStyles } from "./styles";

import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";

import ComponentHeader from "../../../../../components/ComponentHeader";
import DataGrid from "../../../../../components/DataGrid";

import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { GridRenderCellParams } from "@mui/x-data-grid/models/params/gridCellParams";

const ComercialTeamManagementComponent = () => {
    const classes = useStyles();
    const { permissions } = useAuth();
    const navigate = useNavigate();

    const [openSellerRegisterDialog, setOpenSellerRegisterDialog] =
        useState(false);

    const handleOpenSellerRegisterDialog = () => {
        setOpenSellerRegisterDialog(true);
    };

    const {
        data: comercialTeamMembersResponse,
        isFetching: gettingComercialTeamMembers,
    } = useComercialTeamMembers({
        active: true,
        per_page: 500,
    });

    const {
        data: utalkChannelsResponse,
        isFetching: gettingUtalkChannelsResponse,
    } = useUtalkChannels(
        {
            external_id__in: JSON.stringify(
                comercialTeamMembersResponse?.results
                    .map((member) =>
                        member.utalk?.channel ? member.utalk?.channel : ""
                    )
                    .filter((external_id) => !!external_id)
            ),
            per_page: 500,
        },
        {
            enabled: !!comercialTeamMembersResponse?.results,
        }
    );

    const [columnsDefs, setColumnsDefs] = useState<GridColDef<IFrexcoSeller>[]>(
        [
            { field: "name", headerName: "Nome", minWidth: 200, flex: 1 },
            {
                field: "squads",
                minWidth: 150,
                headerName: "Polo",
                flex: 1,
                renderCell: (
                    params: GridRenderCellParams<IFrexcoSeller, string[]>
                ) => {
                    return params.row?.is_regional_leader
                        ? ""
                        : params.value
                              ?.map((squad: string) => `${squad}`)
                              .join(", ");
                },
            },
            {
                field: "area",
                minWidth: 120,
                maxWidth: 120,
                headerName: "Atuação",
            },
            {
                field: "status",
                minWidth: 100,
                flex: 1,
                headerName: "Nível",
            },
            {
                field: "is_regional_leader",
                minWidth: 150,
                maxWidth: 150,
                headerName: "Função",
                renderCell: (
                    params: GridRenderCellParams<IFrexcoSeller, boolean>
                ): string => {
                    return params.value
                        ? "Regional"
                        : params.row?.is_team_leader
                        ? "Líder de Polo"
                        : "Hunter";
                },
            },
            {
                field: "is_team_leader",
                minWidth: 100,
                maxWidth: 100,
                headerName: "Líder",
                renderCell: (
                    params: GridRenderCellParams<IFrexcoSeller, boolean>
                ) => {
                    const isLeadership =
                        params.value || params.row?.is_regional_leader;

                    return Circle({ value: !!isLeadership });
                },
            },
        ]
    );

    useEffect(() => {
        if (!gettingUtalkChannelsResponse && utalkChannelsResponse) {
            setColumnsDefs([
                ...columnsDefs,
                {
                    field: "exit_date",
                    minWidth: 100,
                    maxWidth: 100,
                    headerName: "Irregular",
                    renderCell: (
                        params: GridRenderCellParams<IFrexcoSeller, string>
                    ) => {
                        return IrregularityAlert({
                            member: params.row!,
                            channel: utalkChannelsResponse?.results.find(
                                (channel) =>
                                    channel.external_id ===
                                    params.row?.utalk.channel
                            ),
                        });
                    },
                    align: "center",
                },
                {
                    field: "id",
                    width: 80,
                    headerName: "",
                    renderCell: (
                        params: GridRenderCellParams<IFrexcoSeller, string>
                    ) => {
                        return (
                            <Tooltip title="Editar Hunter">
                                <IconButton
                                    onClick={() => {
                                        navigate(`/seller/${params.value}`);
                                    }}
                                >
                                    <EditOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        );
                    },
                },
            ]);
        }
    }, [utalkChannelsResponse?.results, gettingUtalkChannelsResponse]);

    return (
        <Box>
            <ComponentHeader.Root>
                <ComponentHeader.Title text="Gerenciamento do Time Comercial" />
                <ComponentHeader.Desc
                    text="Com a funcionalidade de gerenciamento de hunters, você terá
                 acesso a uma lista completa dos hunters cadastrados em suas
                 plataformas de vendas, RD Station e UTalk. Além de visualizar
                 informações relevantes sobre cada hunter, você poderá cadastrar
                 novos hunters diretamente através do Groselha."
                />
            </ComponentHeader.Root>
            <Box className={classes.buttonsContainer}>
                <PermissionGate
                    neededpermissions={["sellers | register"]}
                    userPermissions={permissions}
                >
                    <Button
                        startIcon={<PersonAddIcon fontSize="large" />}
                        onClick={handleOpenSellerRegisterDialog}
                    >
                        <Typography variant="body2">
                            Cadastrar hunter
                        </Typography>
                    </Button>
                </PermissionGate>
            </Box>
            <Box></Box>
            <Box width={"100%"} height={"500px"} marginTop={"15px"}>
                <DataGrid<IFrexcoSeller>
                    rows={
                        comercialTeamMembersResponse?.results
                            ? comercialTeamMembersResponse?.results
                            : []
                    }
                    columns={columnsDefs}
                    loading={gettingComercialTeamMembers}
                    hideFooterPagination={false}
                />
            </Box>
            {openSellerRegisterDialog && (
                <SellerRegisterDialog
                    openDialog={openSellerRegisterDialog}
                    setOpenDialog={setOpenSellerRegisterDialog}
                />
            )}
        </Box>
    );
};

export default ComercialTeamManagementComponent;
