import { useState } from "react";

import useSalesGoalsTypes from "../../../../../hooks/SalesBonusApi/useSalesGoalsTypes";
import useSalesGoalsClass from "../../../../../hooks/SalesBonusApi/useSalesGoalsClass";
import useSalesLevels from "../../../../../hooks/SalesBonusApi/useSalesLevels";
import useMutation from "../../../../../hooks/useMutate";
import { useParams } from "react-router-dom";

import {
    Autocomplete,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Controller, FieldValues, useForm } from "react-hook-form";

import TextField from "../../../../../components/TextField";
import Button from "../../../../../components/Button";

import { ISalesGoal } from "../../../../../interfaces/SalesBonus/ISalesGoal";
import { ISalesGoalType } from "../../../../../interfaces/SalesBonus/ISalesGoalType";
import { ISalesGoalClass } from "../../../../../interfaces/SalesBonus/ISalesGoalClass";

import SalesGoalsApi from "../../../../../services/SalesBonusApi/salesGoals";

const salesGoalsApi = new SalesGoalsApi();

let formSchema = yup.object().shape({
    name: yup.string().required("Obrigatório"),
    type: yup.string().required("Obrigatório"),
    class: yup.string().required("Obrigatório"),
    quantity: yup
        .number()
        .min(0, "Maior ou igual que 0")
        .required("Obrigatório"),
    bonus: yup.number().required("Obrigatório").min(0, "Maior ou igual que 0"),
});

interface IEditGoalDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    goal: ISalesGoal;
}

const EditGoalDialog = ({
    openDialog,
    setOpenDialog,
    goal,
}: IEditGoalDialog) => {
    const { levelId } = useParams();

    const [goalType, setGoalType] = useState<ISalesGoalType | null>();
    const [goalClass, setGoalClass] = useState<ISalesGoalClass | null>();

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
        setValue,
    } = useForm({
        resolver: yupResolver(formSchema),
    });

    const { data: goalsTypesResponse, isFetching: gettingGoalsTypesResponse } =
        useSalesGoalsTypes();

    const { data: goalsClassResponse, isFetching: gettingGoalsClassResponse } =
        useSalesGoalsClass();

    const { data: salesLevelResponse } = useSalesLevels(
        {
            id__in: `["${levelId}"]`,
        },
        { enabled: !!levelId }
    );

    const { mutate: alterGoal, isLoading: alteringGoal } = useMutation({
        successText: "Meta alterada.",
        errorText: "Falha ao alterar meta.",
        queryKeyArray: ["sales-areas-with-info"],
        callback: salesGoalsApi.put,
    });

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSubmitForm = (data: FieldValues) => {
        if (salesLevelResponse) {
            let goalToEdit = {
                id: goal.id!,
                name: data.name,
                quantity: data.quantity,
                value: data.bonus,
                sales_level_id: salesLevelResponse.results[0].id!,
                goal_type_id: goalType ? goalType.id! : goal.goal_type.id!,
                goal_class_id: goalClass ? goalClass.id! : goal.goal_class.id!,
            };
            alterGoal({
                id: goalToEdit.id!,
                object: goalToEdit as unknown as ISalesGoal,
            });
        }
    };

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle sx={{ backgroundColor: "primary.main" }}>
                <Typography textAlign={"center"} variant="h3" color={"#FAFAFA"}>
                    Editar meta {goal.name}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box marginTop={"10px"}>
                    <TextField
                        fullWidth
                        defaultValue={goal.name}
                        {...register("name")}
                        label="Nome*"
                        error={!!errors?.name}
                        helperText={<>{errors?.name?.message}</>}
                    />

                    <Controller
                        name="class"
                        control={control}
                        defaultValue={goal.goal_class.name}
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                {...field}
                                id="tags-standard"
                                loading={gettingGoalsClassResponse}
                                options={
                                    goalsClassResponse?.results
                                        ? goalsClassResponse.results
                                        : []
                                }
                                getOptionLabel={(option) =>
                                    option.name || option
                                }
                                onChange={(e, value) => {
                                    setGoalClass(value);
                                    setValue("class", value.name);
                                }}
                                isOptionEqualToValue={(value, option) =>
                                    value.name === option.name
                                }
                                renderInput={(params) => (
                                    <TextField
                                        inputRef={ref}
                                        {...params}
                                        fullWidth
                                        {...register("class")}
                                        label="Classe*"
                                        error={!!errors?.class}
                                        helperText={
                                            <>{errors?.class?.message}</>
                                        }
                                    />
                                )}
                            />
                        )}
                    />
                    <Controller
                        name="type"
                        control={control}
                        defaultValue={goal.goal_type.name}
                        render={({ field: { ref, ...field } }) => (
                            <Autocomplete
                                {...field}
                                id="tags-standard"
                                loading={gettingGoalsTypesResponse}
                                options={
                                    goalsTypesResponse?.results
                                        ? goalsTypesResponse.results
                                        : []
                                }
                                getOptionLabel={(option) =>
                                    option.name || option
                                }
                                onChange={(e, value) => {
                                    setGoalType(value);
                                    setValue("type", value.name);
                                }}
                                isOptionEqualToValue={(value, option) =>
                                    value === option.name ||
                                    value.name === option.name ||
                                    value === option
                                }
                                renderInput={(params) => (
                                    <TextField
                                        inputRef={ref}
                                        {...params}
                                        fullWidth
                                        {...register("type")}
                                        label="Tipo*"
                                        error={!!errors?.type}
                                        helperText={
                                            <>{errors?.type?.message}</>
                                        }
                                    />
                                )}
                            />
                        )}
                    />

                    <TextField
                        fullWidth
                        {...register("quantity")}
                        defaultValue={goal.quantity}
                        label="Quantidade*"
                        type="number"
                        error={!!errors?.quantity}
                        helperText={<>{errors?.quantity?.message}</>}
                    />
                    <TextField
                        fullWidth
                        {...register("bonus")}
                        defaultValue={goal.value}
                        label="Recompensa*"
                        type="number"
                        error={!!errors?.bonus}
                        helperText={<>{errors?.bonus?.message}</>}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                    onClick={handleSubmit(handleSubmitForm)}
                    loading={alteringGoal}
                >
                    <Typography variant="body2">CONFIRMAR</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditGoalDialog;
