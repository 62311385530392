import { Box } from "@mui/material";

import { useAuth } from "../../../../../hooks/useAuth";

import { PermissionGate } from "../../../../../components/PermissionGate";
import ComponentHeader from "../../../../../components/ComponentHeader";
import SellerHistoryGrid from "../MemberHistoryGrid";

const SellerHistory = () => {
    const { permissions: userPermissions } = useAuth();

    return (
        <Box>
            <PermissionGate
                neededpermissions={["history"]}
                userPermissions={userPermissions}
            >
                <ComponentHeader.Root>
                    <ComponentHeader.Title text="Histórico de Mudanças" />
                    <ComponentHeader.Desc text="Utilize a tabela abaixo para gerenciar a mudança de membros do time comercial." />
                </ComponentHeader.Root>
                <Box display={"flex"} gap={"40px"}>
                    <SellerHistoryGrid />
                </Box>
            </PermissionGate>
        </Box>
    );
};

export default SellerHistory;
