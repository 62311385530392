import { IPutSellerDistributionParams } from "../../interfaces/IPutSellerDistributionParams";
import { api } from "../../utils/Api/apiClient";

class SellerDistributionParamsService {
    url: string;
    constructor() {
        this.url = `api/sdr/automatic-distribution/seller/`;
    }

    putSellerDistributionParams = (data: IPutSellerDistributionParams[]) =>
        api.put(this.url, data, {}).then((res) => res.data);

    getSellerDistributionParams = () =>
        api.get(this.url, {}).then((res) => res.data);
}

export default SellerDistributionParamsService;
