import { useMemo } from "react";
import { Routes as Switch, Route as ReactRoute } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import RouteList from "./RouteList";
import { ProtectedRoute } from "./ProtectedRoute";
import { Navigate } from "react-router-dom";
import Layout from "../layouts";
import { usePopup } from "../hooks/usePopup";
import {
    QueryCache,
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query";

const Routes = () => {
    const { token, permissions } = useAuth();
    const { addPopup } = usePopup();

    const queryClient = useMemo(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        retry: 2,
                        cacheTime: 300_000,
                        staleTime: 10_000,
                        refetchOnWindowFocus: false,
                    },
                },
                queryCache: new QueryCache({
                    onError: (error, query) => {
                        if (query.meta?.errorMessage) {
                            addPopup({
                                title: query.meta?.errorMessage as string,
                                type: "error",
                                duration: "temporary",
                            });
                        }
                    },
                }),
            }),
        []
    );

    return (
        <QueryClientProvider client={queryClient}>
            <Switch>
                {RouteList.map(
                    ({ path, element, requiredPermissions }, index) =>
                        path !== "/login" ? (
                            <ReactRoute
                                key={index}
                                path={path}
                                element={
                                    <ProtectedRoute
                                        children={<Layout>{element}</Layout>}
                                        redirectPath={!!token ? "/" : "/login"}
                                        isAllowed={
                                            !!token &&
                                            (requiredPermissions.length === 0 ||
                                                permissions.includes(
                                                    "groselha | admin"
                                                ) ||
                                                requiredPermissions.some(
                                                    (permission) =>
                                                        permissions.includes(
                                                            permission
                                                        )
                                                ))
                                        }
                                    />
                                }
                            />
                        ) : !!token ? (
                            <ReactRoute
                                key={index}
                                path={path}
                                element={<Navigate to={"/"} replace />}
                            />
                        ) : (
                            <ReactRoute
                                key={index}
                                path={path}
                                element={element}
                            />
                        )
                )}
            </Switch>
        </QueryClientProvider>
    );
};

export default Routes;
