import {
    ComponentsOverrides,
    Theme,
    createTheme,
    responsiveFontSizes,
} from "@mui/material";
import type {} from "@mui/x-data-grid/themeAugmentation";

const MuiDataGridOverride: ComponentsOverrides<Theme>["MuiDataGrid"] = {
    root: ({ theme }) => ({
        borderColor: theme.palette.secondary.light,
    }),
    columnHeaders: ({ theme }) => ({
        backgroundColor: theme.palette.primary.main,
        fontSize: "larger",
        fontWeight: "bold ",
    }),
    scrollArea: ({ theme }) => ({
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    }),
    checkboxInput: ({theme})=>({
        color: theme.palette.secondary.light
    })
};

let theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 380,
            md: 600,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            main: "#8BC34A",
            dark: "#689F38",
        },
        secondary: {
            main: "#1C1C1C",
            light: "#6A6A6A",
        },
        error: {
            main: "#CF142B",
            dark: "#A21021",
        },
        warning: {
            main: "#ed6c02",
            light: "#faab6b",
        },
        info: {
            main: "#5D5FEF",
            light: "#7879F1",
        },
    },
    typography: {
        fontFamily: ["Open Sans, sans-serif", "Inter, sans-serif"].join(","),
        h1: {
            fontSize: "2.5rem",
            fontWeight: 700,
            letterSpacing: "0.015625rem",
        },
        subtitle1: {
            fontSize: "1.88rem",
            fontWeight: 300,
            letterSpacing: "0.015625rem",
        },
        h2: {
            fontFamily: "Inter, sans-serif",
            fontSize: "2.06rem",
            fontWeight: 400,
            letterSpacing: "0.015625rem",
        },
        subtitle2: {
            fontSize: "1.5rem",
            fontWeight: 400,
            letterSpacing: "0.009375rem",
        },
        h3: {
            fontSize: "1.5rem",
            fontWeight: 700,
            letterSpacing: "0.009375rem",
        },
        h4: {
            fontFamily: "Inter, sans-serif",
            fontSize: "1.19rem",
            fontWeight: 500,
            letterSpacing: "0.009375rem",
        },
        body1: {
            fontFamily: "Inter, sans-serif",
            fontSize: "1rem",
            fontWeight: 400,
            letterSpacing: "0.009375rem",
        },
        body2: {
            fontSize: "0.895rem",
            fontWeight: 600,
            letterSpacing: "0,078125rem",
        },
    },
    components: {
        MuiDataGrid: {
            styleOverrides: MuiDataGridOverride,
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;
