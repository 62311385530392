import { Card as MuiCard, Avatar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { stringAvatar } from "../../utils/stringAvatar";
import { stringToColor } from "../../utils/stringToColor";

import { TUser } from "../../@types/user";

const useStyles = makeStyles({
    root: {
        width: "8rem",
        minWidth: "180px",
        height: "8.5rem",
        background: "#FAFAFA",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        position: "relative",
        "&:hover": {
            cursor: "pointer",
            transition: "all 0.2s ease-out",
            boxShadow: "0px 4px 8px rgba(38, 38, 38, 0.2)",
            top: "-4px",
            border: "1px solid #cccccc",
            backgroundColor: "white",
        },
        "&:before": {
            content: "",
            position: "absolute",
            zIndex: "-1",
            top: "-16px",
            right: "-16px",
            background: "#00838d",
            height: "32px",
            width: "32px",
            borderRadius: "32px",
            transform: "scale(2)",
            transformOrigin: "50% 50%",
            transition: "transform 0.15s ease-out",
        },
        "&:hover:before": {
            transform: "scale(2.15)",
        },
    },
    avatar: {
        "&.MuiAvatar-root": {
            width: "3.75rem",
            height: "3.75rem",
        },
    },
    name: {
        textTransform: "capitalize",
        fontWeight: 600,
        fontSize: "0.875rem",
        padding: "0 0.3rem",
        textAlign: "center",
        width: "100%",
        overflowWrap: "break-word",
    },
});

interface IUserCard {
    user: TUser;
    onClick?: any;
}

export const UserCard = ({ user, onClick }: IUserCard) => {
    const classes = useStyles();

    return (
        <MuiCard onClick={onClick} className={classes.root}>
            <Avatar
                sx={{ background: stringToColor(user.username) }}
                className={classes.avatar}
            >
                {stringAvatar(user.username)}
            </Avatar>
            <Typography className={classes.name} variant="body2">
                {user.username}
            </Typography>
        </MuiCard>
    );
};
