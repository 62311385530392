import BreadCrumbCurrent from "./BreadCrumbCurrent";
import BreadCrumbsLink from "./BreadCrumbLink";
import BreadCrumbsRoot from "./BreadCrumbsRoot";

const BreadCrumbs = {
    Root: BreadCrumbsRoot,
    Link: BreadCrumbsLink,
    Current: BreadCrumbCurrent,
};

export default BreadCrumbs;
