import React from "react";
import Button from "../Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

interface IConfirmationDialog {
    title: string;
    message?: string;
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    callback: Function;
}

export const ConfirmationDialog = ({
    title,
    message,
    openDialog,
    setOpenDialog,
    callback,
}: IConfirmationDialog) => {
    const handleConfirm = () => {
        callback();
        setOpenDialog(false);
    };

    const handleCancel = () => {
        setOpenDialog(false);
    };

    return (
        <Dialog
            open={openDialog}
            onClose={() => {
                setOpenDialog(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} autoFocus>
                    <Typography variant="body2">Cancelar</Typography>
                </Button>
                <Button onClick={handleConfirm} autoFocus color="error">
                    <Typography variant="body2">Confirmar</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};
