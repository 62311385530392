import { useQuery } from "@tanstack/react-query";
import {
    SdrBasedErrors,
    SdrBasedGenericResponses,
} from "../../utils/Api/responses/responses";
import { AxiosError } from "axios";
import UtalkChannelApi from "../../services/SellersApi/utalkChannelsApi";
import { IUtalkChannelQueryOptions } from "../../interfaces/SellersApi/QuerisOptions/IUtalkChannelQueryOptions";
import { IUtalkChannel } from "../../interfaces/SellersApi/IUtalkChannel";

const utalkChannelApi = new UtalkChannelApi();

const useUtalkChannels = (
    query?: IUtalkChannelQueryOptions,
    definitions?: Object
) =>
    useQuery<
        SdrBasedGenericResponses<IUtalkChannel>,
        AxiosError<SdrBasedErrors>
    >({
        ...definitions,
        queryKey: ["utalk-channels", query],
        queryFn: () => utalkChannelApi.get(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: `Erro ao buscar dados do canal.`,
        },
    });
export default useUtalkChannels;
