import { useState } from "react";
import { Box, Typography } from "@mui/material";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import Card from "../../../../../components/Card";

import { ISalesGoal } from "../../../../../interfaces/SalesBonus/ISalesGoal";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";
import EditGoalDialog from "../EditGoalDialog";
import useMutation from "../../../../../hooks/useMutate";
import SalesGoalsApi from "../../../../../services/SalesBonusApi/salesGoals";

const salesGoalsApi = new SalesGoalsApi();

interface ISalesLevelGoalCard {
    goal: ISalesGoal;
}

const SalesLevelGoalCard = ({ goal }: ISalesLevelGoalCard) => {
    const [openConfirmDeleteGoalDialog, setOpenConfirmDeleteGoalDialog] =
        useState(false);
    const [openEditGoalDialog, setOpenEditGoalDialog] = useState(false);

    const { mutate: deleteGoal } = useMutation({
        successText: "Meta deletada.",
        errorText: "Erro ao deletar meta.",
        queryKeyArray: ["sales-areas-with-info"],
        callback: salesGoalsApi.delete,
    });

    return (
        <>
            <Card.Root>
                <Card.Title text={goal.name} />
                <Card.Content>
                    <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
                        <Typography variant="body1">
                            Classe: {goal.goal_class.name}
                        </Typography>
                        <Typography variant="body1">
                            Tipo: {goal.goal_type.name}
                        </Typography>
                        <Typography variant="body1">
                            Quantidade: {goal.quantity}
                        </Typography>
                        <Typography variant="body1">
                            Recompensa: {goal.value}
                        </Typography>
                    </Box>
                </Card.Content>
                <Card.Actions>
                    <Card.Action
                        tooltip={`Deletar ${goal.name}`}
                        icon={
                            <DeleteForeverOutlinedIcon
                                color="error"
                                fontSize="large"
                            />
                        }
                        action={() => {
                            setOpenConfirmDeleteGoalDialog(true);
                        }}
                    />
                    <Card.Action
                        tooltip={`Editar ${goal.name}`}
                        icon={
                            <EditOutlinedIcon
                                color="primary"
                                fontSize="large"
                            />
                        }
                        action={() => {
                            setOpenEditGoalDialog(true);
                        }}
                    />
                </Card.Actions>
            </Card.Root>
            <ConfirmationDialog
                title={`Deseja mesmo deletar a meta ${goal.name}?`}
                setOpenDialog={setOpenConfirmDeleteGoalDialog}
                openDialog={openConfirmDeleteGoalDialog}
                callback={() => {
                    deleteGoal(goal.id!);
                }}
            />
            <EditGoalDialog
                openDialog={openEditGoalDialog}
                setOpenDialog={setOpenEditGoalDialog}
                goal={goal}
            />
        </>
    );
};

export default SalesLevelGoalCard;
