import { useNavigate } from "react-router-dom";

import { Link, Typography } from "@mui/material";

interface IBreadCrumbsLink {
    to: string;
    text: string;
}

const BreadCrumbsLink = ({ to, text }: IBreadCrumbsLink) => {
    const navigate = useNavigate();

    return (
        <Link
            style={{ cursor: "pointer" }}
            underline="hover"
            color="inherit"
            onClick={() => navigate(to)}
        >
            <Typography variant="h4">{text}</Typography>
        </Link>
    );
};

export default BreadCrumbsLink;
