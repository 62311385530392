import { useQuery } from "@tanstack/react-query";
import SalesGoalsTypesApi from "../../services/SalesBonusApi/salesGoalsTypesApi";

const salesGoalsTypesApi = new SalesGoalsTypesApi();

const useSalesGoalsTypes = (query?: Object, definitions?: Object) =>
    useQuery({
        ...definitions,
        queryKey: ["sales-goals-types", query],
        queryFn: () => salesGoalsTypesApi.get(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar tipos de metas.",
        },
    });
export default useSalesGoalsTypes;
