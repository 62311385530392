import { useMutation } from "@tanstack/react-query";
import SpecialAttentionClientsService from "../../services/AbacateApi/specialAttentionClients";
import { usePopup } from "../usePopup";
import { AxiosError } from "axios";
import { AbacateApiErros } from "../../utils/Api/responses/responses";

const specialAttetionClientsService = new SpecialAttentionClientsService();

const useCreateSpecialAttentionClient = () => {
    const { addPopup } = usePopup();
    return useMutation(specialAttetionClientsService.post, {
        onSuccess: () => {
            addPopup({
                title: "Pedido de atenção registrado.",
                type: "success",
                duration: "temporary",
            });
        },
        onError: (error: AxiosError<AbacateApiErros>) => {
            addPopup({
                title: "Erro ao registrar pedido de atenção.",
                description: error.response?.data.error,
                type: "error",
                duration: "temporary",
            });
        },
    });
};
export default useCreateSpecialAttentionClient;
