import { AxiosResponse } from "axios";
import { IWalletMovement } from "../../interfaces/SalesBonus/IWalletMovement";
import { api } from "../../utils/Api/apiClient";

class WalletMovementApi {
    endpoint = "/api/sales-bonus-api/wallet-movement/";

    post = (object: IWalletMovement) =>
        api.post(this.endpoint, object).then((res: AxiosResponse) => res.data);
}

export default WalletMovementApi;
