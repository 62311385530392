import { OnboardingClientEditForm } from "./components/OnboardingClientEditForm";
import FarmerAttributionList from "./components/FarmerAttributionList";
import PageContainer from "../../../components/PageContainer";

import GainToFarmer from "./components/GainToFarmer";

const Onboarding = () => {
    return (
        <>
            <PageContainer>
                <GainToFarmer />
                <OnboardingClientEditForm />
                <FarmerAttributionList />
            </PageContainer>
        </>
    );
};

export default Onboarding;
