import { useEffect, useState } from "react";

import useHunterSalesSummary from "../../../../../hooks/AbacateApi/useHunterSalesSummary";

import { IHunterSalesSummary } from "../../../../../interfaces/AbacateApi/IHunterSalesSummary";
import { MonthObject } from "../../../../../interfaces/IMonthObjects";

import TextField from "../../../../../components/TextField";
import ComponentHeader from "../../../../../components/ComponentHeader";
import DataGrid from "../../../../../components/DataGrid";

import { Autocomplete, Box, Checkbox, FormControlLabel } from "@mui/material";

import { useStyles } from "./styles";

import {
    getPastMonths,
    todayMinusXAsString,
} from "../../../../../utils/dateFunctions";
import { GridColDef } from "@mui/x-data-grid";

import DistributionSummaryGrid from "../DistributionSummaryGrid";

const TopSellersTable = () => {
    const classes = useStyles();

    const [selectedPeriod, setSelectedPeriod] = useState<MonthObject>({
        label: "7 Dias",
        start_date: todayMinusXAsString(7),
        end_date: todayMinusXAsString(1),
        amount: 10,
    });

    const [byTeamView, setByTeamView] = useState(false);
    const [columnDefs, setColumnDefs] = useState<
        GridColDef<IHunterSalesSummary>[]
    >([]);

    const { data: hunterSalesSummary, isFetching: fettingTopSellers } =
        useHunterSalesSummary({
            sales_summary: true,
            start_date: selectedPeriod.start_date,
            end_date: selectedPeriod.end_date,
            amount: selectedPeriod.amount,
            by_teams: byTeamView,
        });

    useEffect(() => {
        if (byTeamView) {
            setColumnDefs([
                { field: "hunter_team", headerName: "Time", flex: 1 },
                {
                    field: "activations",
                    headerName: "Ativações",
                    minWidth: 100,
                    flex: 1,
                },
                {
                    field: "gains",
                    headerName: "Ganhos",
                    minWidth: 100,
                    flex: 1,
                },
                { field: "total", headerName: "Total", minWidth: 100, flex: 1 },
            ]);
        } else {
            setColumnDefs([
                { field: "hunter", headerName: "Nome", flex: 1 },
                { field: "hunter_team", headerName: "Time", flex: 1 },
                {
                    field: "activations",
                    headerName: "Ativações",
                    minWidth: 100,
                    flex: 1,
                },
                {
                    field: "gains",
                    headerName: "Ganhos",
                    minWidth: 100,
                    flex: 1,
                },
                { field: "total", headerName: "Total", minWidth: 100, flex: 1 },
            ]);
        }
    }, [byTeamView]);

    return (
            <Box>
                <ComponentHeader.Root>
                    <ComponentHeader.Title text="Rank de Vendedores" />
                </ComponentHeader.Root>
                <Box className={classes.filtersBox}>
                    <Autocomplete
                        id="tags-standard"
                        className={classes.select}
                        options={getPastMonths()}
                        getOptionLabel={(option) => option.label}
                        defaultValue={selectedPeriod}
                        isOptionEqualToValue={(option, value) =>
                            option.label === value.label
                        }
                        loading={fettingTopSellers}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Selecione o período."
                                placeholder="Períodos"
                            />
                        )}
                        onChange={(e, value) => {
                            if (value) {
                                setSelectedPeriod(value);
                            }
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(event) => {
                                    setByTeamView(event.target.checked);
                                }}
                            />
                        }
                        label="Por time"
                    />
                </Box>

                <Box width={"100%"} height={"500px"}>
                    <DataGrid<IHunterSalesSummary>
                        rows={hunterSalesSummary ? hunterSalesSummary : []}
                        columns={columnDefs}
                        loading={fettingTopSellers}
                        getRowId={(hunterSalesSummary) =>
                            hunterSalesSummary.hunter_id
                                ? hunterSalesSummary.hunter_id
                                : hunterSalesSummary.hunter_team
                        }
                    />
                </Box>
            </Box>
    );
};

export default TopSellersTable;
