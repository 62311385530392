import { ISalesGoal } from "../../interfaces/SalesBonus/ISalesGoal";
import { ISalesGoalPost } from "../../interfaces/SalesBonus/PostObjectsInterfaces/ISalesGoalPost";
import { ISalesGoalsQueryOptions } from "../../interfaces/SalesBonus/QueriesOptions/ISalesGoalsQueryOptions";
import GenericApi from "../genericApi";

class SalesGoalsApi extends GenericApi<
    ISalesGoal,
    ISalesGoalsQueryOptions,
    ISalesGoalPost
> {
    constructor() {
        super("/api/sales-bonus-api/goal/");
    }
}

export default SalesGoalsApi;
