import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { Theme, styled } from "@mui/material";

interface ButtonProps {
    active?: boolean;
    color?: "primary" | "error";
}

interface IPickBackgroundColor extends ButtonProps {
    theme: Theme;
}

const pickBackgroundColor = ({
    active,
    color,
    theme,
}: IPickBackgroundColor) => {
    switch (color) {
        case "primary":
            if (active) return theme.palette.primary.dark;
            else return theme.palette.primary.main;
        case "error":
            return theme.palette.error.main;
    }
};

const pickBackgroundColorOnHover = ({ color, theme }: IPickBackgroundColor) => {
    switch (color) {
        case "primary":
            return theme.palette.primary.dark;
        case "error":
            return theme.palette.error.dark;
    }
};

const Button = styled(MuiLoadingButton)<ButtonProps>(
    ({ theme, active, color = "primary" }) => ({
        margin: theme.spacing(1),
        padding: "0.625rem 1.25rem",
        textTransform: "capitalize",
        borderRadius: "0.15rem",
        backgroundColor: pickBackgroundColor({ active, color, theme }),
        color: "#FAFAFA",
        "&:hover": {
            backgroundColor: pickBackgroundColorOnHover({
                active,
                color,
                theme,
            }),
        },
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FAFAFA",
        },
    })
);

export default Button;
