import { MonthObject } from "../interfaces/IMonthObjects";
import { format, subDays } from 'date-fns';

export const todayMinusXAsString = (x: number) => {
    const currentDate = new Date();
    const xDaysAgo = new Date(currentDate);
    xDaysAgo.setDate(currentDate.getDate() - x);

    const formattedDate = xDaysAgo.toISOString().split("T")[0];

    return formattedDate;
};

function getLastDayOfMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
}

export function getPastMonths(): MonthObject[] {
    const months: MonthObject[] = [];
    const currentDate = new Date();
    const targetDate = new Date("2023-07-01");

    while (currentDate >= targetDate) {
        const label = `${currentDate.toLocaleString("default", {
            month: "long",
        })}/${currentDate.getFullYear()}`;
        const start_date = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
        )
            .toISOString()
            .slice(0, 10);

        const lastDayOfMonth = getLastDayOfMonth(
            currentDate.getFullYear(),
            currentDate.getMonth()
        );
        const end_date =
            currentDate.getDate() === lastDayOfMonth
                ? currentDate.toISOString().slice(0, 10)
                : new Date(
                      currentDate.getFullYear(),
                      currentDate.getMonth(),
                      lastDayOfMonth
                  )
                      .toISOString()
                      .slice(0, 10);

        months.push({ label, start_date, end_date, amount: 200 });

        currentDate.setMonth(currentDate.getMonth() - 1);
    }
    months.unshift({
        label: "7 Dias",
        start_date: todayMinusXAsString(8),
        end_date: todayMinusXAsString(1),
        amount: 10,
    });
    return months;
}

export function YYYYMMDDtoDDMMYYYY(inputDate: string): string {
    const dateParts = inputDate.split("-");

    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const day = parseInt(dateParts[2]);

    const formattedDate = `${day.toString().padStart(2, "0")}-${month
        .toString()
        .padStart(2, "0")}-${year.toString()}`;
    return formattedDate;
}

export function DDMMYYYYtoYYYYMMDD(dateString: string): string {
    const parts = dateString.split("-");

    if (parts.length !== 3) {
        throw new Error("Formato de data inválido. Use DD-MM-YYYY.");
    }

    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    const transformedDate = `${year}-${month}-${day}`;
    return transformedDate;
}

export function isFuture(dataString: string) {
    const today = new Date();

    const pieces = dataString.split("-");
    const year = parseInt(pieces[0]);
    const month = parseInt(pieces[1]) - 1;
    const day = parseInt(pieces[2]);

    const receivedDate = new Date(year, month, day);

    if (receivedDate > today) {
        return true;
    }
    return false;
}

export function getCurrentMonth() {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return {
        label: "Mês Atual",
        start_date: startOfMonth.toISOString().split("T")[0],
        end_date: endOfMonth.toISOString().split("T")[0],
    };
}

export function getXDaysAgo(days: number) {
    const today = new Date();

    const xDaysAgo = new Date(today);
    xDaysAgo.setDate(today.getDate() - days);

    return xDaysAgo;
}

export function getXDaysForward(days: number) {
    const today = new Date();

    const xDaysForward = new Date(today);
    xDaysForward.setDate(today.getDate() + days);

    return xDaysForward;
}

export function getYearMonthDay(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month}-${day}`;
}

export function compareDates(a: string, b: string): number {
    const [diaA, mesA] = a.split('_').map(Number);
    const [diaB, mesB] = b.split('_').map(Number);
    
    if (mesA !== mesB) {
        return mesA - mesB;
    }
    
    return diaA - diaB;
}

function formatDateToDD_MM(date: Date): string {
    return format(date, 'dd_MM');
  }

export function getLast7Days(): string[] {
    const datesArray: string[] = [];
    for (let i = 6; i >= 0; i--) {
      const currentDate = subDays(new Date(), i);
      const formattedDate = formatDateToDD_MM(currentDate);
      datesArray.push(formattedDate);
    }
    return datesArray;
  }