import {
    Autocomplete,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";

import TextField from "../../../../../components/TextField";
import Button from "../../../../../components/Button";

import useMutation from "../../../../../hooks/useMutate";

import TeamsApi from "../../../../../services/SellersApi/teamsApi";
import { useForm } from "react-hook-form";
import useSalesAreas from "../../../../../hooks/SalesBonusApi/useSalesAreas";
import { ITeams } from "../../../../../interfaces/SellersApi/ITeams";

const teamsApi = new TeamsApi();

interface ICreateTeamDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateTeamDialog = ({ openDialog, setOpenDialog }: ICreateTeamDialog) => {
    const roles = ["Hunter", "Farm"];

    const {
        data: getSalesAreasResponse,
        isFetching: gettingSalesAreasResponse,
    } = useSalesAreas();

    const { mutate: createTeam, isLoading: creatingTeam } = useMutation({
        successText: "Equipe criada.",
        errorText: "Falha ao criar equipe.",
        queryKeyArray: ["teams"],
        callback: teamsApi.post,
    });

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<ITeams>({
        defaultValues: {
            active: true,
        },
    });

    const handleSubmitForm = (data: ITeams) => {
        createTeam(data);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle sx={{ backgroundColor: "primary.main" }}>
                <Typography textAlign={"center"} variant="h3" color={"#FAFAFA"}>
                    Criar equipe
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box marginTop={"10px"}>
                    <TextField
                        fullWidth
                        {...register("name", { required: true })}
                        label="Nome*"
                        error={!!errors?.name}
                        helperText={<>{errors?.name?.message}</>}
                    />
                </Box>
                <Autocomplete
                    id="tags-standard"
                    options={roles}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            label="Função*"
                            {...register("role", { required: true })}
                            error={!!errors?.role}
                            helperText={<>{errors?.role?.message}</>}
                        />
                    )}
                />
                <Autocomplete
                    id="tags-standard"
                    loading={gettingSalesAreasResponse}
                    options={
                        getSalesAreasResponse?.results
                            ? getSalesAreasResponse?.results
                            : []
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            label="Area de atuação*"
                            {...register("area", { required: true })}
                            error={!!errors?.area}
                            helperText={<>{errors?.area?.message}</>}
                        />
                    )}
                />
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                    onClick={handleSubmit(handleSubmitForm)}
                    loading={creatingTeam}
                >
                    <Typography variant="body2">CRIAR</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateTeamDialog;
