import { Box, Typography } from "@mui/material";

interface ICardTitle {
    text: string;
}

const CardTitle = ({ text }: ICardTitle) => {
    return (
        <Box sx={{ backgroundColor: "primary.main" }} padding={"10px 0 10px 0"}>
            <Typography variant="h3" color={"#FAFAFA"} textAlign={"center"}>
                {text}
            </Typography>
        </Box>
    );
};

export default CardTitle;
