import { useStyles } from "./styles";

import { Box, Typography } from "@mui/material";

import AutomaticAssignmenteParams from "./components/AutomaticAssignmentParams";
import ComponentHeader from "../../../components/ComponentHeader";

const AutoAssignment = () => {
    const classes = useStyles();

    return (
        <>
            <ComponentHeader.Root>
                <ComponentHeader.Title text="Atribuição Automática de Leads" />
            </ComponentHeader.Root>
            <Box className={classes.contentContainer}>
                <Typography variant="body1" color={"secondary.light"}>
                    Detalhes do serviço
                </Typography>
                <Typography variant="body1">
                    Altera os parâmetros do serviço de atribuição automática de
                    leads aos hunters.
                </Typography>
                <AutomaticAssignmenteParams />
            </Box>
        </>
    );
};

export default AutoAssignment;
