import PageContainer from "../../../components/PageContainer";
import BonusConfig from "./components/BonusConfig";

const BonusConfigPage = () => {
    return (
        <PageContainer>
            <BonusConfig />
        </PageContainer>
    );
};

export default BonusConfigPage;
