import { useQuery } from "@tanstack/react-query";
import OnboardingWalletApi from "../../services/SalesBonusApi/onboardingWallet";

const onboardingWalletApi = new OnboardingWalletApi();

const useOnboardingWallet = (query?: Object, definitions?: Object) =>
    useQuery({
        ...definitions,
        queryKey: ["onboarding-wallet", query],
        queryFn: () => onboardingWalletApi.get(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar carteira onboarding.",
        },
    });
export default useOnboardingWallet;
