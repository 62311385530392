import { useQuery } from "@tanstack/react-query";
import HunterSalesService from "../../services/AbacateApi/hunterSalesService";
import {
    AbacateApiErros,
    AbacateGetResponse,
} from "../../utils/Api/responses/responses";
import { IHunterSales } from "../../interfaces/AbacateApi/IHunterSales";

const hunterSalesService = new HunterSalesService();

const useHunterSales = (params: Object, definitions: Object) =>
    useQuery<AbacateGetResponse<IHunterSales>, AbacateApiErros>({
        ...definitions,
        queryKey: ["hunters-sales", params],
        queryFn: () => hunterSalesService.getHunterSales(params),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar registros de vendas.",
        },
    });
export default useHunterSales;
