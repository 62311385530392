import ComponentHeader from "../../../components/ComponentHeader";
import ComponentHeaderTitle from "../../../components/ComponentHeader/ComponentHeaderTitle";
import PageContainer from "../../../components/PageContainer";
import TeamsManagementGrid from "./components/TeamsManagementGrid";

const TeamsManagement = () => {
    return (
        <PageContainer>
            <ComponentHeader.Root>
                <ComponentHeaderTitle text="Equipes" />
                <ComponentHeader.Desc text="Através dessa funcionalidade você pode criar, excluir, editar e reativar equipes." />
            </ComponentHeader.Root>
            <TeamsManagementGrid/>
        </PageContainer>
    );
};

export default TeamsManagement;
