import { Typography, Box } from "@mui/material";
import WelcomeBackground from '../../../assets/images/welcome.png'
import { useStyles } from "./styles";

const Welcome = () => {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Box>
                <Typography variant="h2" color={"primary.main"}>
                    Bem-vindo(a),
                </Typography>
                <Typography variant="body1" color={"primary.main"}>
                    Escolha o serviço que deseja utilizar no menu ao lado.
                </Typography>
            </Box>
            <img className={classes.img} src={WelcomeBackground} alt="ilustração de uma pessoa escolhendo um serviço" />
        </div>
    )
}

export default Welcome;