import useSalesGoalsTypes from "../../../../../hooks/SalesBonusApi/useSalesGoalsTypes";
import useMutation from "../../../../../hooks/useMutate";
import useSalesGoalsClass from "../../../../../hooks/SalesBonusApi/useSalesGoalsClass";
import { useParams } from "react-router-dom";
import { usePopup } from "../../../../../hooks/usePopup";

import {
    Autocomplete,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FieldValues, useForm } from "react-hook-form";

import TextField from "../../../../../components/TextField";
import Button from "../../../../../components/Button";

import { ISalesLevel } from "../../../../../interfaces/SalesBonus/ISalesLevel";
import { ISalesGoalPost } from "../../../../../interfaces/SalesBonus/PostObjectsInterfaces/ISalesGoalPost";

import SalesGoalsApi from "../../../../../services/SalesBonusApi/salesGoals";

const salesGoalsApi = new SalesGoalsApi();

let formSchema = yup.object().shape({
    name: yup.string().required("Obrigatório"),
    type: yup.number().required("Obrigatório"),
    class: yup.number().required("Obrigatório"),
    quantity: yup
        .number()
        .min(0, "Maior ou igual que 0")
        .required("Obrigatório"),
    bonus: yup.number().required("Obrigatório").min(0, "Maior ou igual que 0"),
});

interface ICreateGoalDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    level: ISalesLevel;
}

const CreateGoalDialog = ({
    openDialog,
    setOpenDialog,
    level,
}: ICreateGoalDialog) => {
    const { levelId } = useParams();
    const { addPopup } = usePopup();

    const {
        handleSubmit,
        reset,
        register,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(formSchema),
    });

    const { data: goalsTypesResponse, isFetching: gettingGoalsTypesResponse } =
        useSalesGoalsTypes();

    const { data: goalsClassResponse, isFetching: gettingGoalsClassResponse } =
        useSalesGoalsClass();

    const { mutate: createGoal, isLoading: creatingGoal } = useMutation({
        successText: "Meta criada.",
        errorText: "Falha ao criar meta.",
        queryKeyArray: ["sales-areas-with-info"],
        callback: salesGoalsApi.post,
    });

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSubmitForm = (data: FieldValues) => {
        if (levelId) {
            let goal: ISalesGoalPost = {
                name: data.name,
                sales_level_id: parseInt(levelId),
                goal_class_id: data.class,
                goal_type_id: data.type,
                quantity: data.quantity,
                value: data.bonus,
            };
            createGoal(goal);
            reset();
        } else {
            addPopup({
                title: "Não foi possível criar a meta.",
                description: "Contate um administrador.",
                type: "error",
                duration: "temporary",
            });
        }
    };

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle sx={{ backgroundColor: "primary.main" }}>
                <Typography textAlign={"center"} variant="h3" color={"#FAFAFA"}>
                    Criar nova meta em {level.name}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box marginTop={"10px"}>
                    <TextField
                        fullWidth
                        {...register("name")}
                        label="Nome*"
                        error={!!errors?.name}
                        helperText={<>{errors?.name?.message}</>}
                    />
                    <Autocomplete
                        id="tags-standard"
                        loading={gettingGoalsClassResponse}
                        options={
                            goalsClassResponse?.results
                                ? goalsClassResponse?.results
                                : []
                        }
                        getOptionLabel={(option) => option.name}
                        onChange={(event, selectedOption) => {
                            setValue("class", selectedOption?.id!);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                label="Classe*"
                                error={!!errors?.type}
                                helperText={<>{errors?.type?.message}</>}
                            />
                        )}
                    />
                    <Autocomplete
                        id="tags-standard"
                        loading={gettingGoalsTypesResponse}
                        options={
                            goalsTypesResponse?.results
                                ? goalsTypesResponse?.results
                                : []
                        }
                        getOptionLabel={(option) => option.name}
                        onChange={(event, selectedOption) => {
                            setValue("type", selectedOption?.id!);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                label="Tipo*"
                                error={!!errors?.type}
                                helperText={<>{errors?.type?.message}</>}
                            />
                        )}
                    />

                    <TextField
                        fullWidth
                        {...register("quantity")}
                        defaultValue={0}
                        label="Quantidade*"
                        type="number"
                        error={!!errors?.quantity}
                        helperText={<>{errors?.quantity?.message}</>}
                    />
                    <TextField
                        fullWidth
                        {...register("bonus")}
                        defaultValue={0}
                        label="Recompensa*"
                        type="number"
                        error={!!errors?.bonus}
                        helperText={<>{errors?.bonus?.message}</>}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                    onClick={handleSubmit(handleSubmitForm)}
                    loading={creatingGoal}
                >
                    <Typography variant="body2">CRIAR</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateGoalDialog;
