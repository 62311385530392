import {
    Autocomplete,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";

import Button from "../../../../../components/Button";
import TextField from "../../../../../components/TextField";

import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { ColDef } from "ag-grid-community";

import { makeStyles } from "@mui/styles";

import { IRegion } from "../../../../../interfaces/IRegion";
import { ISeller } from "../../../../../interfaces/SellersApi/ISeller";
import { IPutAreaDistributionParams } from "../../../../../interfaces/IPutAreaDistributionParams";
import { ITeams } from "../../../../../interfaces/SellersApi/ITeams";

import { FieldValues, useForm } from "react-hook-form";
import { usePopup } from "../../../../../hooks/usePopup";
import useDealLostReason from "../../../../../hooks/SdrApi/useDealLostReason";
import useAreas from "../../../../../hooks/SdrApi/useAreas";
import { IPutSellerDistributionParams } from "../../../../../interfaces/IPutSellerDistributionParams";
import useAlterSellerDistributionParams from "../../../../../hooks/SdrApi/useAlterSellerDistributionParams";
import useAlterAreaDistributionParams from "../../../../../hooks/SdrApi/useAlterAreaDistributionParams";

export const useStyles = makeStyles(() => ({
    inputsContainer: {
        width: "100%",
        display: "flex",
        gap: "2px",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 0px",
        "& .MuiTextField-root": {
            margin: "10px 0 0 0",
        },
    },
    actionsBox: {
        display: "flex",
        justifyContent: "center",
    },
}));

interface ITeamDefinitionsDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    team: ITeams;
    hunters: ISeller[];
}

const TeamDefinitionsDialog = ({
    openDialog,
    setOpenDialog,
    team,
    hunters,
}: ITeamDefinitionsDialog) => {
    const classes = useStyles();
    const { addPopup } = usePopup();

    const [query, setQuery] = useState<Object>({ squad_id: team.id });

    const handleClose = () => {
        setOpenDialog(false);
    };

    const gridRef = useRef<AgGridReact<IRegion>>(null);

    const {
        isFetching: gettingAreasRanking,
        data: areasRankingResponse,
        error: gettingAreasRankingError,
    } = useAreas(query);

    useEffect(() => {
        if (gettingAreasRankingError) {
            if (
                gettingAreasRankingError.response?.data.error ===
                "DistributionAreaParams matching query does not exist."
            ) {
                addPopup({
                    title: `Falha ao buscar ranking de areas para ${team.name}.`,
                    description:
                        "Não existe um ranking para este time ainda, buscaremos todas as areas e você poderá criar um ranking para ele.",
                    type: "error",
                    duration: "permanent",
                });
                setQuery({});
            } else {
                addPopup({
                    title: `Falha ao buscar ranking de areas para ${team.name}.`,
                    description: gettingAreasRankingError.response?.data.error,
                    type: "error",
                    duration: "temporary",
                });
            }
        }
    }, [gettingAreasRankingError, addPopup, team.name]);

    const showLoadingOverlayRegionsInfoGrif = useCallback(() => {
        gridRef.current?.api?.showLoadingOverlay();
    }, []);

    const hideLoadingOverlayRegionsInfoGrid = useCallback(() => {
        gridRef.current?.api?.hideOverlay();
    }, []);

    useEffect(() => {
        if (gettingAreasRanking) {
            showLoadingOverlayRegionsInfoGrif();
        } else {
            hideLoadingOverlayRegionsInfoGrid();
        }
    }, [
        gettingAreasRanking,
        showLoadingOverlayRegionsInfoGrif,
        hideLoadingOverlayRegionsInfoGrid,
    ]);

    const {
        isFetching: gettingDealLostReasons,
        data: dealLostReasonsResponse,
    } = useDealLostReason();

    const {
        mutate: alterSellerDistributionParams,
        isLoading: sendingSellerDistributionParams,
    } = useAlterSellerDistributionParams();

    const {
        mutate: alterAreaDistributionParams,
        isLoading: sendingAreaDistributionParams,
    } = useAlterAreaDistributionParams();

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    const submitForm = (data: FieldValues) => {
        const areaRanking: { area_id: number; position: number }[] = [];
        gridRef.current?.api.forEachNode((node) =>
            areaRanking.push({
                area_id: node.data?.id!,
                position: node.rowIndex! + 1,
            })
        );
        const teamAreaRanking: IPutAreaDistributionParams = {
            squad_id: team.id!,
            area_ranking: areaRanking,
        };
        if (!!areaRanking.length) {
            alterAreaDistributionParams([teamAreaRanking]);
        }

        if (data.inactive || data.new || data.lost || data.lost_reason) {
            data.inactive = data.inactive ? data.inactive : 0;
            data.new = data.new ? data.new : 0;
            data.lost = data.lost ? data.lost : 0;
            if (!data.lost_reason && data.lost > 0) {
                setError("lost_reason", {
                    type: "custom",
                    message: "Obrigatório",
                });
            } else {
                const sellersToUpdate: IPutSellerDistributionParams[] =
                    hunters.map((hunter) => ({
                        seller_id: parseInt(hunter.id!),
                        new: data.new,
                        lost: data.lost,
                        inactive: data.inactive,
                        lost_reason: data.lost_reason,
                    }));
                alterSellerDistributionParams(sellersToUpdate);
            }
        }
    };

    const containerStyle = { width: "100%", height: "300px" };
    const gridStyle = { height: "300px", width: "100%" };
    const defaultColDef = {
        flex: 1,
        minWidth: 200,
    };
    const [columnDefs] = useState<ColDef[]>([
        { field: "id", minWidth: 80, rowDrag: true, headerName: "ID" },
        { field: "name", headerName: "Região" },
    ]);

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>
                <Typography variant="h3" sx={{ textAlign: "center" }}>
                    Parâmetros do time {team.name}
                </Typography>
            </DialogTitle>
            <form onSubmit={handleSubmit(submitForm)}>
                <DialogContent>
                    <div style={containerStyle}>
                        <div style={gridStyle} className={"ag-theme-alpine"}>
                            <AgGridReact<IRegion>
                                ref={gridRef}
                                rowData={areasRankingResponse?.results}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowDragManaged={true}
                                animateRows={true}
                            ></AgGridReact>
                        </div>
                    </div>
                    <Autocomplete
                        sx={{
                            "& .MuiTextField-root": {
                                margin: "10px 0 0 0",
                            },
                        }}
                        loading={gettingDealLostReasons}
                        id="tags-standard"
                        options={
                            dealLostReasonsResponse
                                ? dealLostReasonsResponse?.results
                                : []
                        }
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Motivo de perda"
                                placeholder="Motivo"
                                {...register("lost_reason")}
                                error={!!errors?.lost_reason}
                                helperText={
                                    errors?.lost_reason?.message ? (
                                        <>{errors?.lost_reason?.message}</>
                                    ) : (
                                        <></>
                                    )
                                }
                            />
                        )}
                    />
                    <Box className={classes.inputsContainer}>
                        <TextField
                            variant="outlined"
                            label="Perdidos"
                            placeholder="Perdidos"
                            type="number"
                            {...register("lost")}
                            error={!!errors?.lost}
                            helperText={
                                errors?.lost?.message ? (
                                    <>{errors?.lost?.message}</>
                                ) : (
                                    <></>
                                )
                            }
                        />
                        <TextField
                            variant="outlined"
                            label="Inativos"
                            placeholder="Inativos"
                            type="number"
                            {...register("inactive")}
                            error={!!errors?.inactive}
                            helperText={
                                errors?.inactive?.message ? (
                                    <>{errors?.inactive?.message}</>
                                ) : (
                                    <></>
                                )
                            }
                        />
                        <TextField
                            variant="outlined"
                            label="Novos"
                            placeholder="Novos"
                            type="number"
                            {...register("new")}
                            error={!!errors?.new}
                            helperText={
                                errors?.new?.message ? (
                                    <>{errors?.new?.message}</>
                                ) : (
                                    <></>
                                )
                            }
                        />
                    </Box>
                </DialogContent>
                <Box className={classes.actionsBox}>
                    <Button
                        type="submit"
                        loading={
                            sendingSellerDistributionParams ||
                            sendingAreaDistributionParams
                        }
                    >
                        <Typography variant="body2">Enviar</Typography>
                    </Button>
                </Box>
            </form>
        </Dialog>
    );
};

export default TeamDefinitionsDialog;
