import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSalesAreasWithInfo from "../../../hooks/SalesBonusApi/useSalesAreasWithInfo";

import BreadCrumbs from "../../../components/BreadCrumbs";
import PageContainer from "../../../components/PageContainer";
import BonusLevelEditForm from "./components/BonusLevelEditForm";
import LevelGoals from "./components/LevelGoals";
import ComponentHeader from "../../../components/ComponentHeader";

import { ISalesAreasWithInfo } from "../../../interfaces/SalesBonus/ISalesAreas";
import { ISalesLevel } from "../../../interfaces/SalesBonus/ISalesLevel";

const BonusLevelEdit = () => {
    const areaId = useParams().areaId;
    const levelId = useParams().levelId;

    const [level, setLevel] = useState<ISalesLevel>();

    const {
        data: salesAreasWithInfo,
        isFetching: gettingSalesAreasWithInfo,
        error: gettingSalesAreasWithInfoError,
    } = useSalesAreasWithInfo({ area_id: areaId });

    useEffect(() => {
        if (!!salesAreasWithInfo) {
            let infos = salesAreasWithInfo as unknown as ISalesAreasWithInfo;
            setLevel(
                infos.levels.find((level) => level.id!.toString() === levelId)
            );
        }
    }, [salesAreasWithInfo, levelId]);

    return (
        <>
            {gettingSalesAreasWithInfo ? (
                <ComponentHeader.Root>
                    <ComponentHeader.Title text="Carregando..." />
                </ComponentHeader.Root>
            ) : gettingSalesAreasWithInfoError ? (
                <ComponentHeader.Root>
                    <ComponentHeader.Title text="Error :(" />
                </ComponentHeader.Root>
            ) : (
                salesAreasWithInfo &&
                level && (
                    <>
                        <BreadCrumbs.Root>
                            <BreadCrumbs.Link to="/bonus" text="Bonificação" />
                            <BreadCrumbs.Current text={level.name} />
                        </BreadCrumbs.Root>
                        <PageContainer>
                            <BonusLevelEditForm level={level} />
                            <LevelGoals level={level} />
                        </PageContainer>
                    </>
                )
            )}
        </>
    );
};

export default BonusLevelEdit;
