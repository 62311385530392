import { api } from "../../utils/Api/apiClient";

class AreaManagementService {
    url: string;
    constructor() {
        this.url = `api/sdr/v1/management/areas/`;
    }

    getAreas = (params?: Object) =>
        api
            .get(this.url, {
                params: params,
            })
            .then((res) => res.data);

    getAreasLeadsInfo = (params?: Object) =>
        api
            .get(this.url + "leads/", {
                params: params,
            })
            .then((res) => res.data);

    attAreasPriority = (data?: Object) =>
        api.patch(this.url, data).then((res) => res.data);

    searchAreas = () => api.post(this.url).then((res) => res.data);
}

export default AreaManagementService;
