import CircleIcon from "@mui/icons-material/Circle";

const Circle = ({ value }: { value: boolean }) => {
    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
            }}
        >
            <CircleIcon sx={{ color: value ? "primary.dark" : "error.main" }} />
        </div>
    );
};

export default Circle;
