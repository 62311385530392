import { IFrexcoSellerHistory } from "../../interfaces/SellersApi/IFrexcoSellerHistory";
import { IFrexcoSellerHistoryQueryOptions } from "../../interfaces/SellersApi/QuerisOptions/IFrexcoSellerHistoryQueryOptions";
import GenericApi from "../genericApi";

class FrexcoSellersHistoryApi extends GenericApi<
    IFrexcoSellerHistory,
    IFrexcoSellerHistoryQueryOptions,
    IFrexcoSellerHistory
> {
    constructor() {
        super("api/sellers/sellers/history/");
    }
}

export default FrexcoSellersHistoryApi;
