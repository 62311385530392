import { IconButton, Tooltip } from "@mui/material";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useState } from "react";
import { ITeams } from "../../../../../../../interfaces/SellersApi/ITeams";
import EditTeamDialog from "../../../EditTeamDialog";

interface IEditTeamCellRenderer {
    team: ITeams;
}

const EditTeamCellRenderer = ({ team }: IEditTeamCellRenderer) => {
    const [openEditTeamDialog, setOpenEditTeamDialog] = useState(false);

    return (
        <>
            <Tooltip title="Editar equipe">
                <IconButton onClick={() => setOpenEditTeamDialog(true)}>
                    <EditOutlinedIcon color="primary" />
                </IconButton>
            </Tooltip>
            {openEditTeamDialog && (
                <EditTeamDialog
                    setOpenDialog={setOpenEditTeamDialog}
                    openDialog={openEditTeamDialog}
                    team={team}
                />
            )}
        </>
    );
};

export default EditTeamCellRenderer;
