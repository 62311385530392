import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: "40px",
    },
    assignClientsGridBox: {
        width: "100%",
        marginTop: "20px",
        "@media (max-width: 620px)": {
            width: "100%",
        },
    },
}));
