import { useQuery } from "@tanstack/react-query";
import TeamsApi from "../../services/SellersApi/teamsApi";

const teamsApi = new TeamsApi();

const useTeams = (query?: Object, definitions?: Object) =>
    useQuery({
        ...definitions,
        queryKey: ["teams", query],
        queryFn: () => teamsApi.get(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar equipes.",
        },
    });
export default useTeams;
