import { useEffect, useState } from "react";

import useMutation from "../../../../../hooks/useMutate";
import useSalesGoalsTypes from "../../../../../hooks/SalesBonusApi/useSalesGoalsTypes";
import { usePopup } from "../../../../../hooks/usePopup";

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FieldValues, useForm } from "react-hook-form";

import TextField from "../../../../../components/TextField";
import Button from "../../../../../components/Button";

import SalesLevelsApi from "../../../../../services/SalesBonusApi/salesLevelsApi";
import SalesFixedValuesApi from "../../../../../services/SalesBonusApi/salesFixedValuesApi";

import { ISalesLevel } from "../../../../../interfaces/SalesBonus/ISalesLevel";
import { ISalesAreasWithInfo } from "../../../../../interfaces/SalesBonus/ISalesAreas";
import { ISalesLevelPost } from "../../../../../interfaces/SalesBonus/PostObjectsInterfaces/ISalesLevelPost";
import { ISalesFixedValuesPost } from "../../../../../interfaces/SalesBonus/PostObjectsInterfaces/ISalesFixedValuesPost";
import { ISalesFixedValues } from "../../../../../interfaces/SalesBonus/ISalesFixedValues";

const salesLevelApi = new SalesLevelsApi();
const salesFixedValuesApi = new SalesFixedValuesApi();

let formSchema = yup.object().shape({
    name: yup.string().required("Obrigatório"),
    fixed_value_per_gain: yup
        .number()
        .min(0, "Maior ou igual que 0")
        .required("Obrigatório"),
    fixed_value_per_activation: yup
        .number()
        .min(0, "Maior ou igual que 0")
        .required("Obrigatório"),
    floor_for_activation: yup
        .number()
        .required("Obrigatório")
        .min(0, "Maior ou igual que 0"),
    floor_for_gain: yup
        .number()
        .required("Obrigatório")
        .min(0, "Maior ou igual que 0"),
});

interface ICreateLevelDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    area: ISalesAreasWithInfo;
}

const CreateLevelDialog = ({
    openDialog,
    setOpenDialog,
    area,
}: ICreateLevelDialog) => {
    const { addPopup } = usePopup();

    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        reset,
        register,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(formSchema),
    });

    const { data: goalsTypesResponse, error: gettingGoalsTypesResponseError } =
        useSalesGoalsTypes();

    const { mutateAsync: createLevel, isLoading: creatingLevel } = useMutation<
        ISalesLevel,
        ISalesLevelPost
    >({
        successText: "Level criado com sucesso!",
        errorText: "Falha ao criar level.",
        queryKeyArray: ["sales-areas-with-info"],
        callback: salesLevelApi.post,
    });

    const { mutateAsync: createFixedValue, isLoading: creatingFixedValue } =
        useMutation<ISalesFixedValues, ISalesFixedValuesPost>({
            successText: "Valor fixo criado com sucesso!",
            errorText: "Falha ao criar valor fixo.",
            queryKeyArray: ["sales-areas-with-info"],
            callback: salesFixedValuesApi.post,
        });

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSubmitForm = (data: FieldValues) => {
        const level: ISalesLevelPost = {
            name: data.name,
            area_id: area.id!,
        };
        createLevel(level).then((resp) => {
            if (gettingGoalsTypesResponseError) {
                addPopup({
                    title: "Não é possível criar level no momento.",
                    description: "Entre em contat com o administrador.",
                    type: "error",
                    duration: "temporary",
                });
            } else {
                if (goalsTypesResponse) {
                    let activationObj = goalsTypesResponse.results.filter(
                        (goalType) => goalType.name === "Ativação"
                    );
                    if (!!activationObj.length) {
                        let fixedValueForActivation: ISalesFixedValuesPost = {
                            sales_level_id: resp.id!,
                            quantity: data.floor_for_activation,
                            value: data.fixed_value_per_activation,
                            goal_type_id: activationObj[0].id!,
                        };
                        createFixedValue(fixedValueForActivation);
                    }
                }
                if (goalsTypesResponse) {
                    let gainObj = goalsTypesResponse.results.filter(
                        (goalType) => goalType.name === "Ganho"
                    );
                    if (!!gainObj.length) {
                        let fixedValueForGain: ISalesFixedValuesPost = {
                            sales_level_id: resp.id!,
                            quantity: data.floor_for_gain,
                            value: data.fixed_value_per_gain,
                            goal_type_id: gainObj[0].id!,
                        };
                        createFixedValue(fixedValueForGain);
                    }
                }
                reset();
            }
        });
    };

    useEffect(() => {
        setIsLoading(creatingFixedValue || creatingLevel);
    }, [creatingFixedValue, creatingLevel]);

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle sx={{ backgroundColor: "primary.main" }}>
                <Typography textAlign={"center"} variant="h3" color={"#FAFAFA"}>
                    Criar novo nível em {area.name}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box marginTop={"10px"}>
                    <TextField
                        fullWidth
                        {...register("name")}
                        label="Nome*"
                        error={!!errors?.name}
                        helperText={<>{errors?.name?.message}</>}
                    />
                    <TextField
                        fullWidth
                        {...register("fixed_value_per_gain")}
                        defaultValue={0}
                        label="Valor fixo por ganho*"
                        type="number"
                        error={!!errors?.fixed_value_per_gain}
                        helperText={
                            <>{errors?.fixed_value_per_gain?.message}</>
                        }
                    />
                    <TextField
                        fullWidth
                        {...register("fixed_value_per_activation")}
                        defaultValue={0}
                        label="Valor fixo por ativação*"
                        type="number"
                        error={!!errors?.fixed_value_per_activation}
                        helperText={
                            <>{errors?.fixed_value_per_activation?.message}</>
                        }
                    />
                    <TextField
                        fullWidth
                        {...register("floor_for_gain")}
                        defaultValue={0}
                        label="Piso para ganho*"
                        type="number"
                        error={!!errors?.floor_for_gain}
                        helperText={<>{errors?.floor_for_gain?.message}</>}
                    />
                    <TextField
                        fullWidth
                        {...register("floor_for_activation")}
                        defaultValue={0}
                        label="Piso para ativação*"
                        type="number"
                        error={!!errors?.floor_for_activation}
                        helperText={
                            <>{errors?.floor_for_activation?.message}</>
                        }
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                    onClick={handleSubmit(handleSubmitForm)}
                    loading={isLoading}
                >
                    <Typography variant="body2">CRIAR</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateLevelDialog;
