import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { MenuItem, Typography } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";

import { useAuth } from "../hooks/useAuth";
import RouteList from "../routes/RouteList";
import { PermissionGate } from "../components/PermissionGate";

interface IDrawerListItems {
    toggleDrawer?: Function;
}

export const DrawerListItems = ({ toggleDrawer }: IDrawerListItems) => {
    const location = useLocation();
    const { permissions } = useAuth();

    const drawerList = RouteList.filter(
        (route) => route.menuIcon && route.menuName && route.requiredPermissions
    );

    return (
        <React.Fragment>
            {drawerList.map((component, index) => (
                <PermissionGate
                    key={index}
                    neededpermissions={component.requiredPermissions}
                    userPermissions={permissions}
                    children={
                        <Link
                            to={component.path || "/"}
                            style={{ textDecoration: "none" }}
                        >
                            <ListItemButton
                                alignItems="center"
                                onClick={() => {
                                    toggleDrawer?.();
                                }}
                                sx={{
                                    color:
                                        component.path === location.pathname
                                            ? "#33691E"
                                            : "#6A6A6A",
                                    backgroundColor:
                                        component.path === location.pathname
                                            ? "#D4E7BD"
                                            : "none",
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        fontSize: "20px",
                                        padding: "8px",
                                        borderRadius: "5px",
                                        minwidth: 0,
                                        color:
                                            component.path === location.pathname
                                                ? "#33691E"
                                                : "#6A6A6A",
                                    }}
                                >
                                    {component.menuIcon}
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        marginLeft: "0px",
                                        color:
                                            component.path === location.pathname
                                                ? "#33691E"
                                                : "#6A6A6A",
                                    }}
                                >
                                    <strong>{component.menuName}</strong>
                                </ListItemText>
                            </ListItemButton>
                        </Link>
                    }
                />
            ))}
        </React.Fragment>
    );
};

type IMenuItem = {
    label: string;
    icon: React.ReactNode;
    onclick: () => any;
    path?: string;
    requiredPermissions: string[];
};

export const MenuListItems = () => {
    const { signOut } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { permissions } = useAuth();

    const menuList: IMenuItem[] = [
        {
            label: "Perfil",
            icon: (
                <AssignmentIndOutlinedIcon
                    sx={{ fontSize: "1.7rem" }}
                />
            ),
            onclick: () => {
                navigate("/profile");
            },
            path: "/profile",
            requiredPermissions: ["groselha | user"],
        },
        {
            label: "Painel de Administração",
            icon: (
                <AdminPanelSettingsOutlinedIcon sx={{ fontSize: "1.7rem" }} />
            ),
            onclick: () => {
                navigate("/admin");
            },
            path: "/admin",
            requiredPermissions: ["groselha | admin", "groselha | admin | view only"],
        },
        {
            label: "Sair da conta",
            icon: (
                <ExitToAppIcon
                    sx={{ fontSize: "1.7rem", color: "secondary.light" }}
                />
            ),
            onclick: () => {
                signOut();
            },
            requiredPermissions: [],
        },
    ];

    return (
        <React.Fragment>
            {menuList.map((component, index) => (
                <PermissionGate
                    key={index}
                    neededpermissions={component.requiredPermissions}
                    userPermissions={permissions}
                >
                    <MenuItem
                        sx={{
                            color:
                                component.path === location.pathname
                                    ? "#33691E"
                                    : "secondary.light",
                            backgroundColor:
                                component.path === location.pathname
                                    ? "#D4E7BD"
                                    : "none",
                        }}
                        onClick={component.onclick}
                    >
                        <ListItemIcon
                            sx={{
                                color:
                                    component.path === location.pathname
                                        ? "#33691E"
                                        : "secondary.light",
                            }}
                        >
                            {component.icon}
                        </ListItemIcon>
                        <Typography
                            sx={{
                                color:
                                    component.path === location.pathname
                                        ? "#33691E"
                                        : "secondary.light",
                            }}
                            variant="h4"
                        >
                            {component.label}
                        </Typography>
                    </MenuItem>
                </PermissionGate>
            ))}
        </React.Fragment>
    );
};
