import ComponentHeader from "../../../../../components/ComponentHeader";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CreateLevelDialog from "../CreateLevelFormDialog";
import { useState } from "react";
import { ISalesAreasWithInfo } from "../../../../../interfaces/SalesBonus/ISalesAreas";

interface IHeader {
    salesArea: ISalesAreasWithInfo;
}

const Header = ({ salesArea }: IHeader) => {
    const [openCreateLevelDialog, setOpenCreateLevelDialog] = useState(false);

    return (
        <>
            <ComponentHeader.Root>
                <ComponentHeader.Title text={`Níveis do ${salesArea.name}:`} />
                <ComponentHeader.Actions>
                    <ComponentHeader.Action
                        tooltip="Criar Nível"
                        icon={
                            <AddCircleOutlineOutlinedIcon
                                fontSize="large"
                                color="info"
                            />
                        }
                        action={() => {
                            setOpenCreateLevelDialog(true);
                        }}
                    />
                </ComponentHeader.Actions>
            </ComponentHeader.Root>
            <CreateLevelDialog
                openDialog={openCreateLevelDialog}
                setOpenDialog={setOpenCreateLevelDialog}
                area={salesArea}
            />
        </>
    );
};

export default Header;
