import { useQuery } from "@tanstack/react-query";
import BrasilApi from "../../services/BrasilApi";

const brasilAPi = new BrasilApi();

const useCepInfosV2 = (cep: string, definitions?: Object) =>
    useQuery({
        ...definitions,
        queryKey: ["cep-infos-v2", cep],
        queryFn: () => brasilAPi.getCepInfosV2(cep),
        staleTime: 60 * 60 * 1000 * 24,
        keepPreviousData: true,
        retry: 0,
        meta: {
            errorMessage: `Erro ao buscar informações do cep: ${cep}.`,
        },
    });
export default useCepInfosV2;
