import { useState } from "react";
import { Typography } from "@mui/material";

import Box from "@mui/material/Box";

import Button from "../../../components/Button";
import ClientRecommendedForm from "./ClientRecommendedForm";
import OthersRecommendationsForm from "./OthersRecomentationsForm";

import { useStyles } from "./styles";

import { PermissionGate } from "../../../components/PermissionGate";

import { useAuth } from "../../../hooks/useAuth";
import ComponentHeader from "../../../components/ComponentHeader";

const Recommend = () => {
    const classes = useStyles();
    const { permissions } = useAuth();

    const [clientRecommended, setClientRecommended] = useState(false);
    const [othersRecommended, setOthersRecommended] = useState(false);

    const handleOnClickClientRecommended = () => {
        if (othersRecommended) setOthersRecommended(false);
        if (!clientRecommended) setClientRecommended(true);
    };

    const handleOnClickothersRecommended = () => {
        if (clientRecommended) setClientRecommended(false);
        if (!othersRecommended) setOthersRecommended(true);
    };

    return (
        <div>
            <ComponentHeader.Root>
                <ComponentHeader.Title text="Recomendação de Leads" />
            </ComponentHeader.Root>
            <Box className={classes.contentContainer}>
                <Typography variant="body1" color={"secondary.light"}>
                    Detalhes do serviço
                </Typography>
                <Typography variant="body1">
                    <PermissionGate
                        userPermissions={permissions}
                        neededpermissions={["client recommended"]}
                    >
                        Cadastre leads no RDStation por meio da Recomendação com
                        a opção de "Selecionar Hunter", permitirá que você
                        escolha manualmente da lista dos hunters ativos na
                        Frexco. Será necessário um CNPJ de indicação, caso não
                        tenha, repetir o CNPJ do Lead que irá ser cadastrado.
                    </PermissionGate>
                    <PermissionGate
                        userPermissions={permissions}
                        neededpermissions={["want to recommend"]}
                    >
                        Cadastre leads no RDStation por meio da Recomendação com
                        a opção "Enviar Automaticamente" irá enviar a
                        recomendação para o Hunter com mais vendas nos últimos 7
                        dias.
                    </PermissionGate>
                    <p>
                        Caso este lead já esteja no RDStation, um aviso será
                        enviado ao email do Hunter.
                    </p>
                </Typography>
                <Box>
                    <PermissionGate
                        userPermissions={permissions}
                        neededpermissions={["client recommended"]}
                    >
                        <Button
                            sx={{
                                backgroundColor: clientRecommended
                                    ? "#689F38"
                                    : "",
                            }}
                            onClick={handleOnClickClientRecommended}
                        >
                            <Typography variant="h4">
                                Selecionar Hunter
                            </Typography>
                        </Button>
                    </PermissionGate>
                    <PermissionGate
                        userPermissions={permissions}
                        neededpermissions={["want to recommend"]}
                    >
                        <Button
                            sx={{
                                backgroundColor: othersRecommended
                                    ? "#689F38"
                                    : "",
                            }}
                            onClick={handleOnClickothersRecommended}
                        >
                            <Typography variant="h4">
                                Enviar Automaticamente
                            </Typography>
                        </Button>
                    </PermissionGate>
                </Box>
                <Box className={classes.contentContainer}>
                    {clientRecommended && <ClientRecommendedForm />}
                    {othersRecommended && <OthersRecommendationsForm />}
                </Box>
            </Box>
        </div>
    );
};

export default Recommend;
