import { useState } from "react";
import { Box, IconButton, Theme, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { IUser } from "../../../../../interfaces/IUser";

import SettingsIcon from "@mui/icons-material/Settings";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { ManageUserDialog } from "../ManageUserDialog";
import useGroupRemoveUsers from "../../../../../hooks/AcessApi/useGroupRemoveUsers";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "100%",
        display: "flex",
        padding: ".5rem",
        alignItems: "center",
        justifyContent: "space-between",
        "&:hover": {
            backgroundColor: "#daecc6",
        },
        "@media (max-width: 620px)": {
            width: "100%",
        },
    },
}));

const UserListComponent = ({ user, group }: { user: IUser; group: string }) => {
    const classes = useStyles();

    const [openManageUserDialog, setOpenManageUserDialog] = useState(false);
    const [openConfirmRemoveUserDialog, setOpenConfirmRemoveUserDialog] =
        useState(false);

    const { mutate: removeUserFromGroup } = useGroupRemoveUsers();

    const handleRemoveUserFromGroup = () => {
        removeUserFromGroup({ group: group, usernames: [user.username] });
    };

    const handleOpenManageUserDialog = () => {
        setOpenManageUserDialog(true);
    };

    const handleOpenConfirmRemoveUserFromGroup = () => {
        setOpenConfirmRemoveUserDialog(true);
    };

    return (
        <>
            <Box className={classes.container}>
                <Typography variant="h4">{user.username}</Typography>
                <Box>
                    <Tooltip title="Remover Usuário">
                        <IconButton
                            onClick={handleOpenConfirmRemoveUserFromGroup}
                        >
                            <ClearOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Gerenciar Usuário">
                        <IconButton onClick={handleOpenManageUserDialog}>
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            {openManageUserDialog && (
                <ManageUserDialog
                    user={user}
                    openDialog={openManageUserDialog}
                    setOpenDialog={setOpenManageUserDialog}
                />
            )}
            {openConfirmRemoveUserDialog && (
                <ConfirmationDialog
                    title={`Tem certeza que deseja remover ${user.username} do ${group}?`}
                    message="O usuário perderá todos os privilégios do grupo."
                    openDialog={openConfirmRemoveUserDialog}
                    setOpenDialog={setOpenConfirmRemoveUserDialog}
                    callback={handleRemoveUserFromGroup}
                />
            )}
        </>
    );
};

export default UserListComponent;
