import { AxiosResponse } from "axios";
import { IDistribution } from "../../interfaces/SdrApi/IDistribution";
import { IDistributionQueryOptions } from "../../interfaces/SdrApi/QueriesOptions/IDistributionQueryOptions";
import { IDistributionSummary } from "../../interfaces/SdrApi/IDistributionSummary";
import { api } from "../../utils/Api/apiClient";
import GenericApi from "../genericApi";


class DistributionApi extends GenericApi<
    IDistribution,
    IDistributionQueryOptions,
    IDistribution
> {
    constructor() {
        super("api/sdr/sales-funnel/distribution/");
    }

    getSummary = (params?: IDistributionQueryOptions) =>
        api
            .get(this.endpoint, { params: params })
            .then(
                (res: AxiosResponse<IDistributionSummary[]>) => res.data
            );
}

export default DistributionApi;
