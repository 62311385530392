import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    formsContainer: {
        display: "flex",
        gap: "80px",
        "@media (max-width: 620px)": {
            flexDirection: "column",
            gap: "20px",
        },
        marginTop: "15px",
    },
    searchClientForm: {
        width: "fit-content",
    },
    postSpecialAttetionClientForm: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "15px",
        backgroundColor: "#FAFAFA",
        borderRadius: "8px",
        "& .MuiTextField-root": {
            margin: "0px",
        },
        maxWidth: "350px",
    },
    cnpjInputBox: {
        display: "flex",
        alignItems: "center",
        gap: "5px",
    },
    inputTextField: {
        width: "100%",
    },
    postFormInputBox: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
    },
}));
