import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  img: {
    width: "100%",
    maxWidth: "648px",
    alignSelf: "center",
    marginTop: "5vh",
  },
}));
