import { ISalesFixedValues } from "../../interfaces/SalesBonus/ISalesFixedValues";
import { ISalesFixedValuesPost } from "../../interfaces/SalesBonus/PostObjectsInterfaces/ISalesFixedValuesPost";
import { ISalesFixedValuesQueryOptions } from "../../interfaces/SalesBonus/QueriesOptions/ISalesFixedValuesQueryOptions";
import GenericApi from "../genericApi";

class SalesFixedValuesApi extends GenericApi<
    ISalesFixedValues,
    ISalesFixedValuesQueryOptions,
    ISalesFixedValuesPost
> {
    constructor() {
        super("/api/sales-bonus-api/fixed-values/");
    }
}

export default SalesFixedValuesApi;
