import ComercialTeamManagementComponent from "./components/ComercialTeamManagementComponent";
import RegionalLeaderRegister from "./components/RegionalLeaderRegister";
import PageContainer from "../../../components/PageContainer";
import LeaderRegister from "./components/LeaderRegister";
import SellerHistory from "./components/MemberHistory";

const ComercialTeamManagement = () => {
    return (
        <PageContainer>
            <ComercialTeamManagementComponent />
            <LeaderRegister />
            <RegionalLeaderRegister />
            <SellerHistory />
        </PageContainer>
    );
};

export default ComercialTeamManagement;
