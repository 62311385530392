import { FieldValues, useForm } from "react-hook-form";

import useMutation from "../../../../../hooks/useMutate";

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";

import TextField from "../../../../../components/TextField";
import Button from "../../../../../components/Button";

import SalesAreasApi from "../../../../../services/SalesBonusApi/salesAreasApi";

import { ISalesAreas } from "../../../../../interfaces/SalesBonus/ISalesAreas";

const salesAreasApi = new SalesAreasApi();

interface ICreateLevelDialog {
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateAreaDialog = ({
    openDialog,
    setOpenDialog,
}: ICreateLevelDialog) => {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();

    const { mutate: createArea, isLoading: creatingArea } = useMutation({
        successText: "Área criada com sucesso",
        errorText: "Falha ao criar área.",
        queryKeyArray: ["sales-areas"],
        callback: salesAreasApi.post,
    });

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSubmitForm = (data: FieldValues) => {
        createArea(data as unknown as ISalesAreas);
    };

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle sx={{ backgroundColor: "primary.main" }}>
                <Typography textAlign={"center"} variant="h3" color={"#FAFAFA"}>
                    Criar nova area de atuação
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box marginTop={"10px"}>
                    <TextField
                        fullWidth
                        {...register("name", { required: true })}
                        label="Nome*"
                        error={!!errors?.name}
                        helperText={<>{errors?.name?.message}</>}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                    onClick={handleSubmit(handleSubmitForm)}
                    loading={creatingArea}
                >
                    <Typography variant="body2">CRIAR</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateAreaDialog;
