import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        margin: "20px auto",
        "@media (max-width: 620px)": {
            width: "100%",
        },
    },
    accordionSummary: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
}));
