import { useState } from "react";

import {
    Accordion,
    AccordionDetails,
    Box,
    Divider,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";

import HuntersListComponent from "./HuntersListComponent";
import AccordionSummary from "../../../../../components/AccordionSummary";

import { ISellerWithDistributionParams } from "../../../../../interfaces/SellersApi/ISeller";
import { ITeams } from "../../../../../interfaces/SellersApi/ITeams";

import { useStyles } from "./styles";
import TeamDefinitionsDialog from "./TeamDifinitionsDialog";

interface IAccordionItem {
    team: ITeams;
    hunters: ISellerWithDistributionParams[];
}

const AccordionItem = ({ team, hunters }: IAccordionItem) => {
    const classes = useStyles();

    const [openSquadDefinitionsDialog, setOpenSquadDefinitionsDialog] =
        useState(false);

    return (
        <>
            <Accordion>
                <AccordionSummary
                    key={team.id}
                    expandIcon={
                        <Tooltip title="Vendedores">
                            <ExpandMoreIcon />
                        </Tooltip>
                    }
                    aria-controls="panel1a-content"
                >
                    <Box className={classes.accordionSummary}>
                        <Typography variant="h3">{team.name}</Typography>
                        <Tooltip title="Gerenciar time">
                            <IconButton
                                onClick={() =>
                                    setOpenSquadDefinitionsDialog(true)
                                }
                            >
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {hunters.map((hunter) => (
                        <>
                            <HuntersListComponent
                                key={hunter.id}
                                hunter={hunter}
                            />
                            <Divider />
                        </>
                    ))}
                </AccordionDetails>
            </Accordion>
            {openSquadDefinitionsDialog && (
                <TeamDefinitionsDialog
                    openDialog={openSquadDefinitionsDialog}
                    setOpenDialog={setOpenSquadDefinitionsDialog}
                    team={team}
                    hunters={hunters}
                />
            )}
        </>
    );
};

export default AccordionItem;
