import { api } from "../utils/Api/apiClient";
import { TUser } from "../@types/user";

class UsersService {
    url: string;
    constructor() {
        this.url = `api/access/`;
    }

    getAllUsers = () => api.get(this.url + "user").then((res) => res.data);

    getGroselhaUsers = () =>
        api.get(this.url + "user/Groselha").then((res) => res.data);

    createUser = (username: string, password: string) =>
        api.post(this.url + "user", { username: username, password: password });

    deleteUser = (username: string) =>
        api.delete(this.url + `user/${username}`);

    addUsersToGroup = (usernames: string[], group: string) =>
        api.post(this.url + "/group/users/", {
            group_name: group,
            users: usernames,
        });

    removeUsersFromGroup = (usernames: string[], group: string) =>
        api.delete(this.url + "users/", {
            data: { group_name: group, users: usernames },
        });

    changePassword = (username: string, password: string) => {
        api.patch(this.url + `user/${username}`, { password: password });
    };
}

const getAllUsers = async () => {
    const url = `api/access/user`;
    return await api.get(url);
};

const getGroselhaUsers = async () => {
    const url = `api/access/group/users/Groselha`;
    return await api.get(url);
};

const createUser = async (username: string, password: string) => {
    const url = `api/access/user`;
    const body = {
        username: username,
        password: password,
    };
    return await api.post(url, body);
};

const deleteUser = async (username: string) => {
    const url = `api/access/user/${username}`;
    return await api.delete(url);
};

const addUsersToGroup = async (users: TUser[]) => {
    let usernameList: string[] = [];
    users.forEach((user) => usernameList.push(user.username));
    const url = `/api/access/group/users/`;
    const body = {
        group_name: "Groselha",
        users: usernameList,
    };
    return await api.post(url, body);
};

const removeUsersFromGroup = async (users: TUser[]) => {
    let usernameList: string[] = [];
    users.forEach((user) => usernameList.push(user.username));
    const url = `/api/access/group/users/`;
    const body = {
        group_name: "Groselha",
        users: usernameList,
    };
    return await api.delete(url, { data: body });
};

const changePassword = async (username: string, password: string) => {
    const url = `api/access/user/${username}`;
    const body = {
        password: password,
    };
    return await api.patch(url, body);
};

const usersService = {
    getAllUsers,
    getGroselhaUsers,
    createUser,
    addUsersToGroup,
    removeUsersFromGroup,
    changePassword,
    deleteUser,
};

export default usersService;
