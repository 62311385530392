import SpecialAttentionClients from "./components/SpecialAttetionClients";
import CriticalOrdersManagementTable from "./components/CriticalOrdersManagementTable";
import PageContainer from "../../../components/PageContainer";
import { PermissionGate } from "../../../components/PermissionGate";
import { useAuth } from "../../../hooks/useAuth";

const Orders = () => {
    const { permissions } = useAuth();

    return (
        <PageContainer>
            <SpecialAttentionClients />
            <PermissionGate
                neededpermissions={["critical-orders-management"]}
                userPermissions={permissions}
            >
                <CriticalOrdersManagementTable area="CD"/>
                <CriticalOrdersManagementTable area="Logística"/>
            </PermissionGate>
        </PageContainer>
    );
};

export default Orders;
