import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
    contentContainer: {
        marginTop: "15px",
    },
    formsContainer: {
        display: "flex",
        gap: "20px",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
    inputNumberField: {
        "&.css-wn6zjt-MuiFormControl-root-MuiTextField-root": {
            width: "30%",
            minWidth: "80px",
        },
    },
    infoContainer: {
        marginTop: "20px",
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        "@media (max-width: 620px)": {
            flexDirection: "column",
        },
    },
    form: {
        display: "flex",
        flexDirection: "column",
        width: "50%",
        padding: "10px 20px",
        border: "0.8px solid black",
        borderRadius: "6px",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            minHeight: "300px",
        },
    },
}));
