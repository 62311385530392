import { useEffect } from "react";

import {
    Autocomplete,
    Box,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import SendIcon from "@mui/icons-material/Send";

import { ISellerWithDistributionParams } from "../../../../../interfaces/SellersApi/ISeller";
import { IPutSellerDistributionParams } from "../../../../../interfaces/IPutSellerDistributionParams";

import { makeStyles } from "@mui/styles";

import TextField from "../../../../../components/TextField";

import useDealLostReason from "../../../../../hooks/SdrApi/useDealLostReason";
import useAlterSellerDistributionParams from "../../../../../hooks/SdrApi/useAlterSellerDistributionParams";
import { useForm, Controller } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 0px",
        "@media (max-width: 900px)": {
            flexDirection: "column",
        },
    },
    form: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
        "& .MuiTextField-root": {
            width: "120px",
            margin: "0px",
        },
        "@media (max-width: 670px)": {
            flexDirection: "column",
        },
    },
    select: {
        "& .MuiTextField-root": {
            width: "180px",
            margin: "0px",
        },
    },
}));

let formSchema = yup.object().shape({
    seller_id: yup.string().required("Obrigatório"),
    lost_reason: yup.string().required("Obrigatório"),
    lost: yup.number().required("Obrigatório").min(0, "Maior que 0"),
    inactive: yup.number().required("Obrigatório").min(0, "Maior que 0"),
    new: yup.number().required("Obrigatório").min(0, "Maior que 0"),
});

const HuntersListComponent = ({
    hunter,
}: {
    hunter: ISellerWithDistributionParams;
}) => {
    const classes = useStyles();

    const {
        isFetching: gettingDealLostReasons,
        data: dealLostReasonsResponse,
    } = useDealLostReason();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control: formControl,
        reset: resetForm,
    } = useForm<IPutSellerDistributionParams>({
        resolver: yupResolver(formSchema),
        defaultValues: {
            seller_id: parseInt(hunter.id!),
            lost_reason: hunter.lost_reason,
            new: hunter.new,
            lost: hunter.lost,
            inactive: hunter.inactive,
        },
    });

    const submitForm = (data: IPutSellerDistributionParams) => {
        alterSellerDistributionParams([data]);
    };

    const {
        mutate: alterSellerDistributionParams,
        isLoading: sendingSellerDistributionParams,
    } = useAlterSellerDistributionParams();

    useEffect(() => {
        resetForm({
            seller_id: parseInt(hunter.id!),
            lost_reason: hunter.lost_reason,
            new: hunter.new,
            lost: hunter.lost,
            inactive: hunter.inactive,
        });
    }, [hunter, resetForm]);

    return (
        <Box className={classes.container}>
            <Typography variant="subtitle2">{hunter.name}</Typography>
            <form className={classes.form} onSubmit={handleSubmit(submitForm)}>
                <Controller
                    name="lost_reason"
                    control={formControl}
                    render={({ field: { ref, ...field } }) => (
                        <Autocomplete
                            {...field}
                            onChange={(e, value) =>
                                setValue("lost_reason", value.name)
                            }
                            className={classes.select}
                            loading={gettingDealLostReasons}
                            id="lost_reason"
                            options={
                                dealLostReasonsResponse
                                    ? dealLostReasonsResponse.results
                                    : []
                            }
                            getOptionLabel={(option) =>
                                typeof option === "string"
                                    ? option
                                    : option.name
                            }
                            isOptionEqualToValue={(option, value) =>
                                value === "" ||
                                option.name === value ||
                                option === value
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="lost_reason"
                                    inputRef={ref}
                                    variant="outlined"
                                    placeholder="Motivo de perda"
                                    error={!!errors?.lost_reason}
                                    helperText={
                                        errors?.lost_reason?.message ? (
                                            <>{errors?.lost_reason?.message}</>
                                        ) : (
                                            <></>
                                        )
                                    }
                                />
                            )}
                        />
                    )}
                />

                <TextField
                    variant="outlined"
                    label="Perdidos"
                    placeholder="Perdidos"
                    type="number"
                    {...register("lost", { required: true })}
                    error={!!errors?.lost}
                    helperText={
                        errors?.lost?.message ? (
                            <>{errors?.lost?.message}</>
                        ) : (
                            <></>
                        )
                    }
                />
                <TextField
                    variant="outlined"
                    label="Inativos"
                    placeholder="Inativos"
                    type="number"
                    {...register("inactive", { required: true })}
                    error={!!errors?.inactive}
                    helperText={
                        errors?.inactive?.message ? (
                            <>{errors?.inactive?.message}</>
                        ) : (
                            <></>
                        )
                    }
                />
                <TextField
                    variant="outlined"
                    label="Novos"
                    placeholder="Novos"
                    type="number"
                    {...register("new", { required: true })}
                    error={!!errors?.new}
                    helperText={
                        errors?.new?.message ? (
                            <>{errors?.new?.message}</>
                        ) : (
                            <></>
                        )
                    }
                />
                {sendingSellerDistributionParams ? (
                    <CircularProgress />
                ) : (
                    <Tooltip title="Salvar" placement="right">
                        <IconButton type="submit">
                            <SendIcon color="primary" fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}
            </form>
        </Box>
    );
};

export default HuntersListComponent;
