import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
    },
    filtersBox: {
        display: "flex",
        gap: "15px",
        marginTop: "10px",
        marginBottom: "5px",
        "@media (max-width: 620px)": {
            flexDirection: "column",
        },
    },
    select: {
        width: "200px",
        "& .MuiTextField-root": {
            margin: "0px",
        },
    },
}));
