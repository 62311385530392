import { useEffect } from "react";

import {
    Autocomplete,
    Box,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import SendIcon from "@mui/icons-material/Send";

import { makeStyles } from "@mui/styles";

import TextField from "../../../../../components/TextField";

import { useForm, Controller, FieldValues } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IFrexcoSeller } from "../../../../../interfaces/SellersApi/IFrexcoSeller";
import useAlterClientsWithoutFarmerParamsService from "../../../../../hooks/SdrApi/useAlterClientsWithoutFarmerParams";

export const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 0px",
    },
    form: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
        "& .MuiTextField-root": {
            width: "120px",
            margin: "0px",
        },
    },
    select: {
        "& .MuiTextField-root": {
            width: "180px",
            margin: "0px",
        },
    },
}));

let formSchema = yup.object().shape({
    hunter_id: yup.number().required("Obrigatório"),
    farmer: yup.object().required("Obrigatório"),
});

const HuntersListComponent = ({
    hunter,
    responsibleFarmer,
    farmers,
}: {
    hunter: IFrexcoSeller;
    responsibleFarmer: IFrexcoSeller[];
    farmers: IFrexcoSeller[];
}) => {
    const classes = useStyles();

    const {
        handleSubmit,
        setValue,
        formState: { errors },
        control: formControl,
        reset: resetForm,
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            hunter_id: parseInt(hunter.id!),
            farmer: !!responsibleFarmer.length
                ? responsibleFarmer[0]
                : { id: 0, name: "" },
        },
    });

    const { mutate, isLoading: alteringClientsWithoutFarmerParams } =
        useAlterClientsWithoutFarmerParamsService();

    useEffect(() => {
        resetForm({
            hunter_id: parseInt(hunter.id!),
            farmer: !!responsibleFarmer.length
                ? responsibleFarmer[0]
                : { id: 0, name: "" },
        });
    }, [responsibleFarmer, hunter.id, resetForm]);

    const submitForm = (data: FieldValues) => {
        const hunterWithResponsibleFarmer = {
            hunter_id: parseInt(hunter.id!),
            farmer_id: data.farmer.id,
        };
        mutate([hunterWithResponsibleFarmer]);
    };

    return (
        <Box className={classes.container}>
            <Typography variant="subtitle2">{hunter.name}</Typography>
            <form
                className={classes.form}
                onSubmit={handleSubmit(submitForm)}
            >
                <Controller
                    name="farmer"
                    control={formControl}
                    render={({ field: { ref, ...field } }) => (
                        <Autocomplete<
                            IFrexcoSeller | { name: string; id: number }
                        >
                            {...field}
                            onChange={(e, value) => setValue("farmer", value!)}
                            className={classes.select}
                            id="farmer"
                            options={farmers}
                            getOptionLabel={(option) =>
                                Object.keys(option!!).length ? option.name : ""
                            }
                            isOptionEqualToValue={(option, value) =>
                                value.name === "" || option.name === value.name
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="farmer"
                                    inputRef={ref}
                                    variant="outlined"
                                    placeholder="Farmer"
                                    error={!!errors?.farmer}
                                    helperText={
                                        errors?.farmer?.message ? (
                                            <>{errors?.farmer?.message}</>
                                        ) : (
                                            <></>
                                        )
                                    }
                                />
                            )}
                        />
                    )}
                />

                {alteringClientsWithoutFarmerParams ? (
                    <CircularProgress />
                ) : (
                    <Tooltip title="Salvar" placement="right">
                        <IconButton type="submit">
                            <SendIcon color="primary" fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}
            </form>
        </Box>
    );
};

export default HuntersListComponent;
