import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import useMutation from "../../../../../hooks/useMutate";
import { useNavigate, useParams } from "react-router-dom";
import { usePopup } from "../../../../../hooks/usePopup";
import useSalesAreas from "../../../../../hooks/SalesBonusApi/useSalesAreas";

import { Box, Grid } from "@mui/material";

import TextField from "../../../../../components/TextField";
import ComponentHeader from "../../../../../components/ComponentHeader";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SendIcon from "@mui/icons-material/Send";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { ISalesLevel } from "../../../../../interfaces/SalesBonus/ISalesLevel";
import { ISalesFixedValues } from "../../../../../interfaces/SalesBonus/ISalesFixedValues";
import { ISalesFixedValuesPost } from "../../../../../interfaces/SalesBonus/PostObjectsInterfaces/ISalesFixedValuesPost";

import SalesLevelsApi from "../../../../../services/SalesBonusApi/salesLevelsApi";
import SalesFixedValuesApi from "../../../../../services/SalesBonusApi/salesFixedValuesApi";

const salesLevelsApi = new SalesLevelsApi();
const salesFixedValuesApi = new SalesFixedValuesApi();

let formSchema = yup.object().shape({
    name: yup.string().required("Obrigatório"),
    fixed_value_per_gain: yup
        .number()
        .min(0, "Maior ou igual que 0")
        .required("Obrigatório"),
    floor_for_gain: yup
        .number()
        .min(0, "Maior ou igual que 0")
        .required("Obrigatório"),
    fixed_value_per_activation: yup
        .number()
        .min(0, "Maior ou igual que 0")
        .required("Obrigatório"),
    floor_for_activation: yup
        .number()
        .min(0, "Maior ou igual que 0")
        .required("Obrigatório"),
});

interface IBonusLevelEditForm {
    level: ISalesLevel;
}

const BonusLevelEditForm = ({ level }: IBonusLevelEditForm) => {
    const navigate = useNavigate();
    const { areaId } = useParams();
    const { addPopup } = usePopup();

    const [editingLevel, setEditingLevel] = useState(false);
    const [openConfirmDeleteLevelDialog, setOpenConfirmDeleteLevelDialog] =
        useState(false);

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({ resolver: yupResolver(formSchema) });

    const { mutateAsync: deleteLevel } = useMutation({
        successText: "Nível deletado.",
        errorText: "Falha ao deletar Nível.",
        queryKeyArray: ["sales-areas-with-info"],
        callback: salesLevelsApi.delete,
    });

    const { mutate: alterLevel } = useMutation({
        successText: "Nível alterado.",
        errorText: "Falha ao alterar nível.",
        queryKeyArray: ["sales-areas-with-info"],
        callback: salesLevelsApi.put,
    });

    const { mutate: alterFixedValue } = useMutation({
        successText: "Valor fixo alterado.",
        errorText: "Falha ao alterar valor fixo.",
        queryKeyArray: ["sales-areas-with-info"],
        callback: salesFixedValuesApi.put,
    });

    const { data: salesAreaInfoResponse } = useSalesAreas(
        { id__in: `["${areaId}"]` },
        { enabled: !!areaId }
    );

    const handleAlterFixedValue = (type: string, data: FieldValues) => {
        let fixedValue = level.fixed_values.find(
            (fixedValue) => fixedValue.goal_type.name === type
        );
        if (fixedValue) {
            let fixedValueToEdit: ISalesFixedValuesPost = {
                sales_level_id: level.id!,
                goal_type_id: fixedValue.goal_type.id!,
                quantity:
                    type === "Ativação"
                        ? data.floor_for_activation
                        : data.floor_for_gain,
                value:
                    type === "Ativação"
                        ? data.fixed_value_per_activation
                        : data.fixed_value_per_gain,
            };
            alterFixedValue({
                id: fixedValue.id!,
                object: fixedValueToEdit as unknown as ISalesFixedValues,
            });
        }
    };

    const handleSubmitForm = (data: FieldValues) => {
        if (salesAreaInfoResponse) {
            let levelToEdit = {
                name: data.name,
                area_id: salesAreaInfoResponse?.results[0].id!,
            };
            alterLevel({
                id: level.id!,
                object: levelToEdit as unknown as ISalesLevel,
            });

            handleAlterFixedValue("Ativação", data);
            handleAlterFixedValue("Ganho", data);
        } else {
            addPopup({
                title: "Não foi possível editar o nível.",
                description: "Tente novamente em instantes.",
                type: "error",
                duration: "temporary",
            });
        }
    };

    const handleDeleteLevel = () => {
        deleteLevel(level.id!).then(() => navigate("/bonus"));
    };

    return (
        <Box>
            <ComponentHeader.Root>
                <ComponentHeader.Title text={`Editar ${level.name}`} />
                <ComponentHeader.Actions>
                    <ComponentHeader.Action
                        tooltip="Excluir Nível"
                        icon={
                            <DeleteForeverOutlinedIcon
                                fontSize="large"
                                color="error"
                            />
                        }
                        action={() => {
                            setOpenConfirmDeleteLevelDialog(true);
                        }}
                    />
                    {editingLevel ? (
                        <ComponentHeader.Action
                            tooltip="Confirmar Edição"
                            icon={<SendIcon fontSize="large" color="primary" />}
                            action={handleSubmit(handleSubmitForm)}
                        />
                    ) : (
                        <ComponentHeader.Action
                            tooltip="Editar Nível"
                            icon={
                                <EditOutlinedIcon
                                    fontSize="large"
                                    color="info"
                                />
                            }
                            action={() => {
                                setEditingLevel(true);
                            }}
                        />
                    )}
                    <ComponentHeader.Action
                        tooltip={editingLevel ? "Cancelar Edição" : ""}
                        icon={
                            <CancelOutlinedIcon
                                fontSize="large"
                                color="primary"
                            />
                        }
                        action={() => {
                            setEditingLevel(false);
                        }}
                    />
                </ComponentHeader.Actions>
                <ComponentHeader.Desc text="Nesta página você pode editar valores fixos e metas do nível." />
            </ComponentHeader.Root>
            <Grid spacing={1} container>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={"Nome*"}
                        defaultValue={level.name}
                        {...register("name")}
                        error={!!errors?.name}
                        helperText={<>{errors?.name?.message}</>}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        fullWidth
                        label={"Valor fixo por ganho*"}
                        defaultValue={
                            level.fixed_values.find(
                                (fixedValue) =>
                                    fixedValue.goal_type.name === "Ganho"
                            )?.value
                        }
                        {...register("fixed_value_per_gain")}
                        error={!!errors?.fixed_value_per_gain}
                        helperText={
                            <>{errors?.fixed_value_per_gain?.message}</>
                        }
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        fullWidth
                        label={"Piso para ganho*"}
                        defaultValue={
                            level.fixed_values.find(
                                (fixedValue) =>
                                    fixedValue.goal_type.name === "Ganho"
                            )?.quantity
                        }
                        {...register("floor_for_gain")}
                        error={!!errors?.floor_for_gain}
                        helperText={<>{errors?.floor_for_gain?.message}</>}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        fullWidth
                        label={"Valor fixo por ativação*"}
                        defaultValue={
                            level.fixed_values.find(
                                (fixedValue) =>
                                    fixedValue.goal_type.name === "Ativação"
                            )?.value
                        }
                        {...register("fixed_value_per_activation")}
                        error={!!errors?.fixed_value_per_activation}
                        helperText={
                            <>{errors?.fixed_value_per_activation?.message}</>
                        }
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        fullWidth
                        label={"Piso para ativação*"}
                        defaultValue={
                            level.fixed_values.find(
                                (fixedValue) =>
                                    fixedValue.goal_type.name === "Ativação"
                            )?.quantity
                        }
                        {...register("floor_for_activation")}
                        error={!!errors?.floor_for_activation}
                        helperText={
                            <>{errors?.floor_for_activation?.message}</>
                        }
                    />
                </Grid>
            </Grid>
            <ConfirmationDialog
                title="Deseja mesmo deletar o level?"
                openDialog={openConfirmDeleteLevelDialog}
                setOpenDialog={setOpenConfirmDeleteLevelDialog}
                callback={() => {
                    handleDeleteLevel();
                }}
            />
        </Box>
    );
};

export default BonusLevelEditForm;
