import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    contentContainer: {
        marginTop: "15px",
    },
    inputTextField: {
        maxWidth: 460,
        minWidth: 220,
    },
    buttonBox: {
        width: "250px",
        textAlign: "left",
    },
    infoContainer: {
        marginTop: "20px",
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        "@media (max-width: 620px)": {
            flexDirection: "column",
        },
    },
}));
