import PageContainer from "../../../components/PageContainer";
import Actions from "./components/Actions";
import BonusPaymentTable from "./components/BonusPaymentTable";

const BonusPaymentPage = () => {
    return (
        <PageContainer>
            <Actions />
            <BonusPaymentTable />
        </PageContainer>
    );
};

export default BonusPaymentPage;
