import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

import { v4 as uuidv4 } from "uuid";

import ComponentHeader from "../../../../../components/ComponentHeader";
import SalesAreaLevelCard from "../SalesLevelCard";

import Header from "../Header";

import useSalesAreasWithInfo from "../../../../../hooks/SalesBonusApi/useSalesAreasWithInfo";

import { ISalesAreasWithInfo } from "../../../../../interfaces/SalesBonus/ISalesAreas";

interface ISalesAreaLevelsCards {
    selectedSalesArea: number | undefined;
}

const SalesAreaLevelsCards = ({ selectedSalesArea }: ISalesAreaLevelsCards) => {
    const [salesArea, setSalesArea] = useState<ISalesAreasWithInfo>();

    const {
        data: salesAreasWithInfo,
        isFetching: gettingSalesAreasWithInfo,
        error: gettingSalesAreasWithInfoError,
    } = useSalesAreasWithInfo(
        {
            area_id: selectedSalesArea,
        },
        { enabled: !!selectedSalesArea }
    );

    useEffect(() => {
        if (!!salesAreasWithInfo) {
            setSalesArea(salesAreasWithInfo as unknown as ISalesAreasWithInfo);
        }
    }, [salesAreasWithInfo]);

    return (
        <>
            <Box>
                {gettingSalesAreasWithInfo ? (
                    <ComponentHeader.Root>
                        <ComponentHeader.Title text="Buscando informações dos níveis..." />
                    </ComponentHeader.Root>
                ) : gettingSalesAreasWithInfoError ? (
                    <ComponentHeader.Root>
                        <ComponentHeader.Title text="ERROR :(" />
                    </ComponentHeader.Root>
                ) : (
                    salesArea && (
                        <>
                            <Header salesArea={salesArea} />
                            <Grid
                                container
                                spacing={2}
                                margin={"10px auto"}
                                maxWidth={"1200px"}
                            >
                                {salesArea.levels.map((salesLevel) => (
                                    <Grid key={uuidv4()} item xs={12} sm={4}>
                                        <SalesAreaLevelCard
                                            key={uuidv4()}
                                            level={salesLevel}
                                            area_id={salesArea.id!}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )
                )}
            </Box>
        </>
    );
};

export default SalesAreaLevelsCards;
