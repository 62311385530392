import { useQuery } from "@tanstack/react-query";
import ClientsWithoutFarmerParamsService from "../../services/SdrApi/clientsWithoutFarmerParamsService";
import {
    SdrBasedErrors,
    SdrBasedResponses,
} from "../../utils/Api/responses/responses";
import { AxiosError } from "axios";

const clientsWithoutFarmerParamsService =
    new ClientsWithoutFarmerParamsService();

const useClientsWithoutFarmerParamsService = (
    query?: Object,
    definitions?: Object
) =>
    useQuery<SdrBasedResponses, AxiosError<SdrBasedErrors>>({
        ...definitions,
        queryKey: ["clients-without-params", query],
        queryFn: () => clientsWithoutFarmerParamsService.getParams(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar parâmetros dos vendedores.",
        },
    });
export default useClientsWithoutFarmerParamsService;
