import CardRoot from "./CardRoot";
import CardTitle from "./CardTitle";
import CardAction from "./CardAction";
import CardActions from "./CardActions";
import CardContent from "./CardContent";

const Card = {
    Root: CardRoot,
    Title: CardTitle,
    Content: CardContent,
    Actions: CardActions,
    Action: CardAction,
};

export default Card;
