import { useMutation, useQueryClient } from "@tanstack/react-query";
import ComercialTeamService from "../../services/SellersApi/comercialTeamService";
import { usePopup } from "../usePopup";
import { AxiosError } from "axios";
import { SdrBasedErrors } from "../../utils/Api/responses/responses";

const comercialTeamService = new ComercialTeamService();

const useAlterComercialTeamMember = () => {
    const queryClient = useQueryClient();
    const { addPopup } = usePopup();
    return useMutation(comercialTeamService.alterMember, {
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["comercial-team-members"],
            });
            addPopup({
                title: "Membro alterado.",
                type: "success",
                duration: "temporary",
            });
        },
        onError: (error: AxiosError<SdrBasedErrors>) => {
            addPopup({
                title: "Erro ao alterar membro.",
                description: error.response?.data.error,
                type: "error",
                duration: "temporary",
            });
        },
    });
};
export default useAlterComercialTeamMember;
