import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import { PopupProvider } from "./hooks/usePopup";

import Routes from "./routes/Routes";

import GlobalStyle from "./styles/global";
import theme from "./styles/theme";
import { ApiInterceptor } from "./utils/Api/interceptor";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <GlobalStyle />
                    <PopupProvider>
                        <AuthProvider>
                            <ApiInterceptor>
                                <Routes />
                            </ApiInterceptor>
                        </AuthProvider>
                    </PopupProvider>
                </ThemeProvider>
            </BrowserRouter>
        </LocalizationProvider>
    );
}

export default App;
