import { IPutAreaDistributionParams } from "../../interfaces/IPutAreaDistributionParams";
import { api } from "../../utils/Api/apiClient";

class AreaDistributionParamsService {
    url: string;
    constructor() {
        this.url = `api/sdr/automatic-distribution/area/`;
    }

    putAreaDistributionParams = (data: IPutAreaDistributionParams[]) =>
        api.put(this.url, data).then((res) => res.data);
}

export default AreaDistributionParamsService;
