import { useQuery } from "@tanstack/react-query";
import ComercialTeamService from "../../services/SellersApi/comercialTeamService";
import {
    SdrBasedErrors,
    SdrBasedGenericResponses,
} from "../../utils/Api/responses/responses";
import { AxiosError } from "axios";
import { getMembersQueryOptions } from "../../interfaces/QueriesOptions/ComercialTeamService";
import { IFrexcoSeller } from "../../interfaces/SellersApi/IFrexcoSeller";

const comercialTeamService = new ComercialTeamService();

const useComercialTeamMembers = (
    query?: getMembersQueryOptions,
    definitions?: Object
) =>
    useQuery<
        SdrBasedGenericResponses<IFrexcoSeller>,
        AxiosError<SdrBasedErrors>
    >({
        ...definitions,
        queryKey: ["comercial-team-members", query],
        queryFn: () => comercialTeamService.getMembers(query),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: `Erro ao buscar membros do comercial.`,
        },
    });
export default useComercialTeamMembers;
