import { useQuery } from "@tanstack/react-query";
import BrasilApi from "../../services/BrasilApi";

const brasilAPi = new BrasilApi();

const useCepInfos = (cep: string, definitions?: Object) =>
    useQuery({
        ...definitions,
        queryKey: ["cep-infos", cep],
        queryFn: () => brasilAPi.getCepInfos(cep),
        staleTime: 60 * 60 * 1000 * 24,
        keepPreviousData: true,
        retry: 0,
        meta: {
            errorMessage: `Erro ao buscar informações do cep: ${cep}. Iremos tentar novamente.`,
        },
    });
export default useCepInfos;
