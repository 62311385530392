import { useQuery } from "@tanstack/react-query";
import SellerDistributionParamsService from "../../services/SdrApi/sellerDistributionParamsService";
import {
    SdrBasedErrors,
    SdrBasedResponses,
} from "../../utils/Api/responses/responses";
import { AxiosError } from "axios";

const sellerDistributionParamsService = new SellerDistributionParamsService();

const useSellerDistributionParams = (definitions?: Object) =>
    useQuery<SdrBasedResponses, AxiosError<SdrBasedErrors>>({
        ...definitions,
        queryKey: ["seller-distribution-params"],
        queryFn: () =>
            sellerDistributionParamsService.getSellerDistributionParams(),
        staleTime: 60 * 60 * 1000,
        keepPreviousData: true,
        meta: {
            errorMessage: "Erro ao buscar parâmetros dos Hunters.",
        },
    });
export default useSellerDistributionParams;
